import { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import 'bootstrap-icons/font/bootstrap-icons.css'
import AlertModal from "../AlertModal";
import { Auth } from '@aws-amplify/auth';
import { saveAs } from 'file-saver';
import { exportCSV} from './exportUtils'; // Create these utility functions to handle CSV and PDF exports
import ReportTable from './ReportTable';
import exceljs from 'exceljs'; // Import exceljs library

export default function ReportHome() {
  
    const [searchQueryReportName, setSearchQueryReportName] = useState("");
    const [searchQueryReportData, setSearchQueryReportData] = useState("");
    const [searching, setSearching] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const location = useLocation();


    const [empLov, setEmpLov] = useState({});
    const [clientLov, setClientLov] = useState({});


    //AlertModal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});




    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    function fetchEmpLov() {
        (async () => {
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/emp/emplov', {
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setEmpLov(result);
        })();
    }

    function fetchClientLov() {
        (async () => {
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client/clientlov', {
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setClientLov(result);
        })();
    }

    const [SOWLov, setSOWLov] = useState({});
    const [projLov, setProjLov] = useState({});

    function fetchSOWLov() {
      (async () => {
        setSearching(true);
          let accessToken = await getAccessToken();
          const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/sow/sowlov', {
              headers: new Headers({
                  'Authorization': accessToken
              })
          });
          const result = await response.json();
          setSOWLov(result);
          setSearching(false);
      })();
  }

  function fetchProjLov() {
    (async () => {
      setSearching(true);
        let accessToken = await getAccessToken();
        const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project/projlov', {
            headers: new Headers({
                'Authorization': accessToken
            })
        });
        const result = await response.json();
        setProjLov(result);
        setSearching(false);
    })();
}

const [reportNames, setReportNames] = useState([]);
const [reportData, setReportData] = useState([]);
const [showReportTable, setShowReportTable] = useState(false);
const [selReportName, setSelReportName] = useState("");
const [selReportHeader, setSelReportHeaders] = useState("");

const [reportDataDownload, setReportDataDownload] = useState([]);


//const [filteredReportNames, setFilteredReportNames] = useState([]);

  
  function fetchReportNames(searchStr) {
    (async () => {
        setSearching(true);
        setSearchQueryReportName(searchStr ? searchStr : searchQueryReportName);
        let myHeaders = new Headers();
        let accessToken = await getAccessToken();
        myHeaders.append("Content-Type", "application/json");
        const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/reports/get_report_name?searchName=' + (searchStr ? searchStr.toLowerCase() : searchQueryReportName.toLowerCase()),
            {
                headers: new Headers({
                    'Authorization': accessToken
                })
            }
        );
        const result = await response.json();
        result.sort((a, b) => a.report_name.localeCompare(b.report_name)); // Sort the fetched data by first_name
        console.log(result)
        setReportNames(result); // Store the fetched report names
        setSearching(false);
    })();
}
  useEffect(() => {
        fetchEmpLov()
        fetchClientLov()
        fetchSOWLov()
        fetchProjLov()
        const searchParams = new URLSearchParams(location.search);
        const reportName = searchParams.get('reportName');
        if (reportName) {
              // Fetch SOW based on the sowId
              fetchReportNames(reportName);
          } else {
              // Fetch all SOWs
              fetchReportNames();}
    }, []);



    function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
    }

    
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState("");

    const handleSort = (column) => {
      if (sortColumn === column) {
        // If the same column is clicked, toggle the sorting order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If a different column is clicked, set it as the new sort column
        setSortColumn(column);
        setSortOrder("asc");
      }
    };

    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        if (sortOrder === "asc") {
          return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
        } else {
          return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
        }
      } else {
        return null; // Don't display any icon if the column is not the current sort column
      }
    };


    // Add this code inside the component before rendering the table
let sortedReports = [...reportNames];
if (sortColumn && sortOrder) {
reportNames.sort((a, b) => {
  const columnA = a[sortColumn];
  const columnB = b[sortColumn];
  if (columnA < columnB) {
    return sortOrder === "asc" ? -1 : 1;
  }
  if (columnA > columnB) {
    return sortOrder === "asc" ? 1 : -1;
  }
  return 0;
});
}

  // Function to get the column names from the first report data object
  const getColumnNames = () => {
    if (reportDataDownload.length > 0) {
      return Object.keys(reportDataDownload[0]);
    }
    return [];
  };

// Function to convert table data to CSV format
const convertToCSV = (data) => {
  const columns = getColumnNames();
  const csvRows = [];

  // Add the column headers as the first row
  const header = columns.join(',');
  csvRows.push(header);

  // Convert each row of data to CSV format
  data.forEach((row) => {
    const values = columns.map((column) => {
      let value = row[column];

      // Handle special case for 'client_name' column
      if (column === 'Client_name' && value!='No Projects Assigned') {
          value = clientLov[value]?.client_name || value;
      }

      if (column === 'Project_name' && value!='') {
        value =projLov[value]?.project_name || value;
      }

      // Check if the value contains commas or arrays
      if (typeof value === 'string' && (value.includes(',') || value.includes('[') || value.includes(']'))) {
        value = `"${value}"`; // Wrap the value with double quotes
      }

      return value;
    });

    const csvRow = values.join(',');
    csvRows.push(csvRow);
  });

  // Combine all rows into a single CSV string
  return csvRows.join('\n');
};

  
    // Function to trigger the download
    const downloadAsCSV = () => {
      const currentDate = new Date().toISOString().slice(0, 10);

      if (selReportHeader==="Employees list with projects they are working in" || selReportHeader==="Employees buffer list"
      || selReportHeader==="Employees bench list"){
        const filteredreportdata = reportDataDownload.filter((row) => row.Emp_end_date>=currentDate)
        const csvData = convertToCSV(filteredreportdata);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${selReportHeader}.csv`;
        a.click();
        URL.revokeObjectURL(url);
      }else{
      const csvData = convertToCSV(reportDataDownload);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${selReportHeader}.csv`;
      a.click();
      URL.revokeObjectURL(url);
      }
    };

      // Function to convert table data to XLSX format
  const convertToXLSX = (data) => {
    const columns = getColumnNames();

    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    
    // Add column headers
    worksheet.addRow(columns);

    // Add data rows
    data.forEach((row) => {
      const rowData = columns.map((column) => {
        let value = row[column];
  
        // Handle special case for 'Client_name' column
        if (column === 'Client_name' && value !== 'No Projects Assigned') {
          value = clientLov[value]?.client_name || value;
        }
  
        // Handle special case for 'Project_name' column
        if (column === 'Project_name' && value !== '') {
          value = projLov[value]?.project_name || value;
        }
        
        // Check if the value contains commas or arrays
        if (typeof value === 'string' && (value.includes(',') || value.includes('[') || value.includes(']'))) {
          value = `"${value}"`; // Wrap the value with double quotes
        }
        return value;
      });
      worksheet.addRow(rowData);
    });

    // Generate and return the Excel file as a Blob
    return workbook.xlsx.writeBuffer();
  };

  // Function to trigger the XLSX download
  const downloadAsXLSX = async () => {
    const currentDate = new Date().toISOString().slice(0, 10);

    if (selReportHeader==="Employees list with projects they are working in" || selReportHeader==="Employees buffer list"
    || selReportHeader==="Employees bench list"){
    const filteredreportdata = reportDataDownload.filter((row) => row.Emp_end_date>=currentDate)
    const xlsxBuffer = await convertToXLSX(filteredreportdata);
    const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${selReportHeader}.xlsx`);
    }
    else{
      const xlsxBuffer = await convertToXLSX(reportDataDownload);
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${selReportHeader}.xlsx`);
    }
  };

function fetchReport(searchStr) {
    (async () => {
        setIsSubmitting(true);
        setSearchQueryReportData(searchStr ? searchStr : searchQueryReportData);
        let myHeaders = new Headers();
        let accessToken = await getAccessToken();
        myHeaders.append("Content-Type", "application/json");
        const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/reports/generate_report?reportName=' + (searchStr ? searchStr.toLowerCase() : searchQueryReportData.toLowerCase()),
            {
                headers: new Headers({
                    'Authorization': accessToken
                })
            }
        );
        const result = await response.json();
        if (searchStr==="employees list with projects they are working in" || 
        searchStr==="employees buffer list" || searchStr==="employees with no assigned projects" ){
        result.sort((a, b) => a.Employee_ID.localeCompare(b.Employee_ID)); // Sort the fetched data by first_name)
        setReportData(result); // Store the fetched report names
        }
        else if (searchStr==="expiring sow"){
        result.sort((a, b) => a.SOW_end_date.localeCompare(b.SOW_end_date)); // Sort the fetched data by first_name)
        const currentDate = new Date();
        const filteredResult = result.filter((item) => !item.SOW_end_date || new Date(item.SOW_end_date) >= currentDate);
        setReportData(filteredResult); // Store the fetched report names
        }
        else if (searchStr==="employees bench list"){
          if (result.length===0){
            setReportData([]);
          }
          else{
            setReportData(result);
          }
        }
        else if (searchStr==="employees - projects ending"){
          result.sort((a, b) => a.Emp_end_date.localeCompare(b.Emp_end_date)); // Sort the fetched data by first_name)
          setReportData(result); // Store the fetched report names
          }

        else if (searchStr==="employees - assets"){
          result.sort((a, b) => a.Employee_ID.localeCompare(b.Employee_ID)); // Sort the fetched data by first_name)
          setReportData(result); // Store the fetched report names
          }
        else if (searchStr==="employees - 11th month"){
          result.sort((a, b) => a.Employee_ID.localeCompare(b.Employee_ID)); // Sort the fetched data by first_name)
          setReportData(result); // Store the fetched report names
          }
          
        console.log('report_data',result)
        //setReportData(result); // Store the fetched report names
        setIsSubmitting(false);
        setShowReportTable(true);
    })();
}

function fetchReportandDownload(searchStr) {
  (async () => {
      setIsSubmitting(true);
      setSearchQueryReportData(searchStr ? searchStr : searchQueryReportData);
      let myHeaders = new Headers();
      let accessToken = await getAccessToken();
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/reports/generate_report?reportName=' + (searchStr ? searchStr.toLowerCase() : searchQueryReportData.toLowerCase()),
          {
              headers: new Headers({
                  'Authorization': accessToken
              })
          }
      );
      const result = await response.json();
      setReportDataDownload(result); // Store the fetched report names
      console.log('report_data',result)
      setIsSubmitting(false);
      //downloadAsCSV();
  })();
}
  // useEffect to trigger download when reportData and selReportHeader are ready
  useEffect(() => {
    console.log("yes")
    if (reportDataDownload.length > 0 && selReportHeader) {
      //downloadAsCSV();
      downloadAsXLSX();
    }
  }, [reportDataDownload]);

function viewReport(report) {
    setSelReportName(report.search_name);
    setSelReportHeaders(report.report_name);
    fetchReport(report.search_name)
}

function handleDownloadReport(report) {
  setSelReportName(report.search_name);
  setSelReportHeaders(report.report_name);
  fetchReportandDownload(report.search_name)
}
    return (
        <>
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
            <ReportTable show={showReportTable} setShow={setShowReportTable} selreport={reportData} 
             selReportName={selReportName} selReportHeader={selReportHeader} clientLov={clientLov} 
             projLov={projLov} viewAlert={viewAlert} />
  {isSubmitting  ? (
    <div id="loader-container">
      <div className="loader"></div>
    </div>
  ) : (null
  )}
            <Container fluid>
                <Row >
                    <Col xs lg="3">
                        <InputGroup className="mb-3">

                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                placeholder="Search for a report"
                                type="text"
                                value={searchQueryReportName}
                                onChange={(e) => setSearchQueryReportName(e.target.value)}
                            /><Button variant="outline-secondary" id="button-addon2" onClick={() => fetchReportNames()}>
                                <i className="bi bi-search"></i>
                            </Button>


                        </InputGroup>
                    </Col>

                </Row>
                {(searching || (Object.keys(empLov).length === 0)) ? (
                    <Row ><Spinner animation="border" /></Row>
                  ):(
                    <>
                        {reportNames.length === 0 ?(
                            <Row ><p>No reports found. Please redefine the search criteria</p>
                            </Row>
                           ):(
                            <>
                           
                                <table className="table">
<thead>
  <tr>
    <th onClick={() => handleSort("report_name")}>Report Name {renderSortIcon("report_name")}</th>
    <th>Actions</th>
  </tr>
</thead>

                <tbody>
                  {sortedReports.map((report, index) => {
                    const sequentialNumber = index + 1; // Calculate the sequential number
                    return (
                    <tr key={report.report_name}  style={{ cursor: 'pointer' }}>
                      <td  onClick={() => viewReport(report)}>{sequentialNumber}. {report.report_name}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          style={{marginRight:'2px'}}
                          onClick={() => viewReport(report)}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                         <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          style={{ marginRight: '2px' }}
                          onClick={() => handleDownloadReport(report)} // Call the handleDownload function on click
                        >
              <i className="bi bi-download"></i>
            </button>
                      </td>
                    </tr>
                  );
                    })}
                </tbody>
              </table>
          </>
        )}
      </>
    )}
  </Container>
</>

    )
}