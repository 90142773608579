import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import './AlertModal.css';

export default function AlertModal({ show, setShow, message, header }) {
  const handleClose = () => setShow(false);
  return (
    <>
    <Modal className='alertmodal-tasksheet' show={show} onHide={handleClose} keyboard={false}>
      <Modal.Header closeButton={false}>
        <Modal.Title className="modal-title">
          <h3>{message.header}</h3>
        </Modal.Title>
        <button className="close" onClick={handleClose}>
          <AiOutlineClose />
        </button>
      </Modal.Header>
      <Modal.Body>{message.body}</Modal.Body>
    </Modal>
    {show && <div className="overlay-tasksheet"></div>}

    </>
  );
}












