
import { useContext } from 'react'
//Context
import { UserContext } from './contexts/UserContext'
//Pages
import LoginPage from './Components/LoginPage'
//import InnerApp from './Pages/InnerApp'
import Apps from './Components/App'
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  const { user } = useContext(UserContext)
  if (user) {
    localStorage.removeItem('loggedin'); // Clear the cached data from local storage when the component unmounts
    return <Apps/>
  } else {
    localStorage.removeItem('userData'); // Clear the cached data from local storage when the component unmounts
    localStorage.removeItem('userDataHelpDesk');
    return <LoginPage />
  }
}

export default App