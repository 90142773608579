import jsPDF from 'jspdf';
import exceljs from 'exceljs'; // Import exceljs library

// Helper function to flatten nested objects
const formattedCurrentDate = new Date().toISOString().slice(0, 10); // Get current date in yyyy-mm-dd format

function flattenObject(obj, SOWLov, projLov) {
  const flattened = {};

  function flatten(currentObj, propName) {
    if (currentObj === null || typeof currentObj !== 'object' || Array.isArray(currentObj)) {
      flattened[propName] = currentObj;
    } else {
      for (const [key, value] of Object.entries(currentObj)) {
        const newPropName = propName ? `${propName}.${key}` : key;

        if (key === 'sow') {
          // Handle project_team_member separately
          if (Array.isArray(value)) {
            flattened[newPropName] = value
              .filter((sow) => (SOWLov[sow]?.sow_end_date==='' || SOWLov[sow]?.sow_end_date >= formattedCurrentDate))
              .map((sow) => SOWLov[sow]?.sow_name)
              .join(', ');
          } else {
            flattened[newPropName] = null;
          }
        }
        else if (key === 'project_details') {
          // Handle project_details separately
          if (Array.isArray(value)) {
            flattened[newPropName] = value
              .filter((project) => (projLov[project]?.project_end_date==='' || projLov[project]?.project_end_date >= formattedCurrentDate))
              .map((project) => projLov[project]?.project_name)
              .join(', ');
          } else {
            flattened[newPropName] = [];
          }
        }
         else {
          flatten(value, newPropName);
        }
      }
    }
  }

  flatten(obj, '');

  return flattened;
}

// Export employee data as CSV
export const exportCSV = (data, SOWLov, projLov) => {
  const flattenedData = data.map((client) => flattenObject(client, SOWLov, projLov));

  // Define the desired column order
  const columnOrder = [
    'client_name','sow','project_details','address_street','address_city',
    'address_state','zipcode','po', 'client_manager','client_remarks'];

  // Filter the columns based on the desired order
  const filteredHeaders = columnOrder.filter((header) => Object.keys(flattenedData[0]).includes(header));

  const rows = flattenedData.map((client) =>
    filteredHeaders.map((header) => `"${client[header]?.toString().replace(/"/g, '""') || ''}"`)
  );

  const csvContent = `${filteredHeaders.join(',')}\n${rows.map((row) => row.join(',')).join('\n')}`;
  return csvContent;
};

export const exportSOWPDF = (data, SOWLov,selClient) => {
  const flattenedData = data.map((sow) => {
    return {
      SOWNumber: sow,
      SOWName: SOWLov[sow]?.sow_name || 'N/A',
      StartDate: SOWLov[sow].sow_start_date,
      EndDate: SOWLov[sow].sow_end_date,
    };
  });

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
      //const marginLeft = 250;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);  const title=selClient.client_name + ' SOW Data'

       // Get the width of the title
       const titleWidth = doc.getTextWidth(title);

       // Calculate the x-coordinate of the starting point
        const xPos = (doc.internal.pageSize.width - titleWidth) / 2;
   
        doc.text(title, xPos, 40);

  const tableHeaders = Object.keys(flattenedData[0]);
  const tableData = flattenedData.map((sow) => Object.values(sow));

  const tableStyle = {
    theme: 'grid',
    headStyles: {
      fillColor: [235, 191, 97],
      textColor: [51, 51, 51],
      //fontStyle: 'bold',
      //fontSize: 12
    },

    styles: {
      lineWidth: 0.2,
      lineColor: [192, 192, 192],
      fontStyle: 'normal',
      fontSize: 10,
      cellPadding: 5
    }
  };

    doc.autoTable({
      startY: 50,
      head: [tableHeaders],
      body: tableData,
      tableWidth: 'auto',
      columnStyles: 'auto', // set the width of the 4th column to 80
      ...tableStyle
    });

  return doc.output('blob');
};


export const exportProjectsPDF = (data, projLov,selClient) => {
  const flattenedData = data.map((project) => {
    return {
      ID: project,
      ProjectName: projLov[project]?.project_name || 'N/A',
      StartDate: projLov[project].project_start_date,
      EndDate: projLov[project].project_end_date,
    };
  });

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
      //const marginLeft = 250;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);  const title=selClient.client_name + ' Projects Data'

       // Get the width of the title
       const titleWidth = doc.getTextWidth(title);

       // Calculate the x-coordinate of the starting point
        const xPos = (doc.internal.pageSize.width - titleWidth) / 2;
   
        doc.text(title, xPos, 40);

  const tableHeaders = Object.keys(flattenedData[0]);
  const tableData = flattenedData.map((sow) => Object.values(sow));

  const tableStyle = {
    theme: 'grid',
    headStyles: {
      fillColor: [235, 191, 97],
      textColor: [51, 51, 51],
      //fontStyle: 'bold',
      //fontSize: 12
    },

    styles: {
      lineWidth: 0.2,
      lineColor: [192, 192, 192],
      fontStyle: 'normal',
      fontSize: 10,
      cellPadding: 5
    }
  };

    doc.autoTable({
      startY: 50,
      head: [tableHeaders],
      body: tableData,
      tableWidth: 'auto',
      columnStyles: 'auto', // set the width of the 4th column to 80
      ...tableStyle
    });

  return doc.output('blob');
};



export const exportXLSX = (data, SOWLov, projLov) => {
  const flattenedData = data.map((client) => flattenObject(client, SOWLov, projLov));

  // Define the desired column order
  const columnOrder = [
    'client_name','sow','project_details','address_street','address_city',
    'address_state','zipcode','po', 'client_manager','client_remarks'];

  const workbook = new exceljs.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Add column headers
  const columns = columnOrder.filter((header) => Object.keys(flattenedData[0]).includes(header));
  worksheet.addRow(columns);

  // Add data rows
  flattenedData.forEach((row) => {
    const rowData = columns.map((column) => row[column]?.toString().replace(/"/g, '""') || '');
    worksheet.addRow(rowData);
  });

  // Generate and return the Excel file as a Blob
  return workbook.xlsx.writeBuffer();
};