
import { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {Auth} from '@aws-amplify/auth';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import Badge from 'react-bootstrap/Badge';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { BsDownload } from 'react-icons/bs';
import { exportCSV, exportPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports
import { saveAs } from 'file-saver';

export default function SOWForm({ type, show, setShow, fetchSOWs, selSOW, viewAlert,clientLov ,setSelClient,selClient,setProjectDetails,projectDetails,projLov }) {

    const sowNumberField = useRef();
    const nameField = useRef();
    const numberofpeopleField = useRef();
    const sowStartDateField = useRef();
    const sowEndDateField = useRef();
    const poField = useRef();
    const clientNameField = useRef();
    const projectDetailsField = useRef();
    const sowRemarksField = useRef();

    const [processing, setProcessing] = useState(false);
    const [showSOWNumberWarning, setShowSOWNumberWarning] = useState(false);
    const [showSOWNameWarning, setShowSOWNameWarning] = useState(false);
    const [showSOWClientNameWarning, setShowSOWClientNameWarning] = useState(false);


    const modalTitle = (type === 'update' ? 'Update' : 'Create') + ' SOW';

    const submitTitle = type === 'update' ? 'Update' : 'Create';
    const cancelTitle = type === 'view' ? 'Close' : 'Cancel';


    const handleClose = () =>{

      setShow(false);
      setShowSOWNumberWarning(false);
      setShowSOWNameWarning(false);
      setShowSOWClientNameWarning(false)
    }  

    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

    const onSubmit = (ev) => {
        console.log(selClient)
        ev.preventDefault();
        if(sowNumberField.current.value==='' || nameField.current.value==='' || selClient.length===0 ){
            
        if(sowNumberField.current.value===''){
            setShowSOWNumberWarning(true);
                    }
        if(nameField.current.value===''){
            setShowSOWNameWarning(true);
        }
        if(selClient.length===0){
            setShowSOWClientNameWarning(true);
        }
    }
        else{
            const id = type === 'new' ? nameField.current.value +  Auth.user.attributes.email +  moment().format('YYYY/MM/DD, HH:mm:ss A')
            : selSOW.ID ;
        setProcessing(true);

        const newSOW = {
            "ID": id,
            "sow_number": sowNumberField.current.value,
            "sow_name":nameField.current.value,
            "client_name": selClient[0]?.ID,
            "number_of_people": numberofpeopleField.current.value,
            "sow_start_date": sowStartDateField.current.value,
            "sow_end_date": sowEndDateField.current.value,
            "po": poField.current.value,
            "project_details": projectDetails,
            "user_email":Auth.user.attributes.name,
            "sow_remarks": sowRemarksField.current.value
            //"status": sowEndDateField.current.value && new Date(sowEndDateField.current.value) < new Date() ? 'in-active' : 'active'
        };

        newSOW["search_name"] = (newSOW["sow_name"]).toLowerCase();

        (async () => {
            let accessToken = await getAccessToken();
            const rawResponse = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/sow', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':accessToken
                },
                body: JSON.stringify(newSOW)
            });
            const content = await rawResponse.json();
            console.log('after form submit',content)
            if (type === 'update'|| type === 'new') {
                fetchSOWs();
            }
            setProcessing(false);
            handleClose();
            const alertMessage = {
                header: "SOW " + (type === 'update' ? 'updated' : 'created'),
                body: "SOW " + newSOW["sow_name"] + " is successfully " + (type === 'update' ? 'updated' : 'created')
            }
            viewAlert(alertMessage);
        })();
      }
    }

    const handleSOWNumberChange = (event) => {
        const id = event.target.value;
   
        if (id.length > 0) {
         setShowSOWNumberWarning(false); // Clear the warning
      } else {
         setShowSOWNumberWarning(true); // Show warning when project name is not filled
         }
       };

       const handleSOWNameChange = (event) => {
        const name = event.target.value;
   
        if (name.length > 0) {
         setShowSOWNameWarning(false); // Clear the warning
      } else {
         setShowSOWNameWarning(true); // Show warning when project name is not filled
         }
       };

       const handleSOWClientNameChange = (event) => {

        const clientname = event;
        if (clientname.length > 0) {
         setSelClient(clientname)
         setShowSOWClientNameWarning(false); // Clear the warning
      } else {
         setSelClient([]); // Reset clientname if no selection
         setShowSOWClientNameWarning(true); // Show warning when project name is not filled
         }
       };

        //populate client lov    
        const clientOptions = [];
        for (let clientID in clientLov) {
            let lovClient= { 'ID': clientID, 'display_key': clientLov[clientID].display_key }
            clientOptions.push(lovClient);
        }

            //populate emp lov    
    const options = [];
    for (let projID in projLov) {
        let lovProject = { 'ID': projID, 'display_key': projLov[projID].display_key }
        options.push(lovProject);
    }
    const [selProject, setSelProject] = useState([]);

    const addProject = () => {
        if (selProject.length > 0) {
            const project = selProject[0];
            if (!(projectDetails.includes(project.ID))) {
                projectDetails.push(project.ID);
                setProjectDetails([...projectDetails]);
                projectDetailsField.current.clear();
            }
        }
    }

    const deleteMember = (projID) => {
        const newProjects = projectDetails.filter(project => project !== projID);
        setProjectDetails(newProjects);
    }

    const formattedCurrentDate = new Date().toISOString().slice(0, 10); // Get current date in yyyy-mm-dd format

    const navigate = useNavigate();

    function RedirectToSearchProject(projectId) {
        navigate(`/projecttracker/project?projectId=${projectId}`);
      }

      const [showCurrentProjects, setShowCurrentProjects] = useState(false);

      const toggleShowCurrentProjects = () => {
        setShowCurrentProjects(!showCurrentProjects);
      };

      const filteredProjects = showCurrentProjects
        ? projectDetails.filter(project => (projLov[project].project_end_date >= formattedCurrentDate || projLov[project].project_end_date ===''))
        : projectDetails;

      const exportData = (e) => {
        // Export as CSV
        if(e==='csv'){
          if (filteredProjects.length>0){
        const csvData = exportCSV(filteredProjects, projLov, selSOW);
        const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(csvBlob, 'employees.csv');
        //document.getElementById("export_select").value = "";
          }
    }
       else{
        // Export as PDF
        if (filteredProjects.length>0){
          const pdfData = exportPDF(filteredProjects, projLov, selSOW);
          const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
          saveAs(pdfBlob, selSOW.sow_name +'-Projects.pdf');
        //document.getElementById("export_select").value = ""; 
        }
    }
      };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h5>{modalTitle}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>SOW Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Number"
                            ref={sowNumberField}
                            defaultValue={selSOW.sow_number}
                            disabled={type == 'view' ? true : false}
                            onChange={handleSOWNumberChange}
                        />
                          {showSOWNumberWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a SOW Number.
                            </div>
                           )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>SOW Name</Form.Label>
                        <Form.Control
                            type="text" 
                            placeholder="Enter Name" 
                            ref={nameField} 
                            defaultValue={selSOW.sow_name} 
                            disabled={type === 'view' ? true : false}
                            onChange={handleSOWNameChange}
                                                        />
                        {showSOWNameWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a SOW Name.
                            </div>
                           )}
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>Client Name</Form.Label>
                           <Typeahead
                           id="Client-name-typeahead"
                           labelKey="display_key"
                           options={clientOptions}
                           onChange={handleSOWClientNameChange}
                           selected={selClient}
                           placeholder="Search client"
                           ref={clientNameField}
                           disabled={type === "view" ? true : false}
                     />
                       {showSOWClientNameWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a Client Name.
                            </div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>Number of people</Form.Label>
                            <Form.Control type="number" placeholder="Number of people" ref={numberofpeopleField} defaultValue={selSOW.number_of_people} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Label>PO Number</Form.Label>
                            <Form.Control type="text" placeholder="PO Number" ref={poField} defaultValue={selSOW.po} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>SOW Start Date</Form.Label>
                            <Form.Control type="date" ref={sowStartDateField} defaultValue={selSOW.sow_start_date} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                            <Form.Label>SOW End Date</Form.Label>
                            <Form.Control type="date" ref={sowEndDateField} defaultValue={selSOW.sow_end_date} disabled={type === 'view' ? true : false} />

                        </Form.Group>

                    </Row>
                    <Row>
                        <Container>
                        <div className="d-flex justify-content-between align-items-center mb-2">

                            <p>Projects <Badge bg="warning" text="dark">
                                {projectDetails.length}
                            </Badge>
                            </p>
                        <div className="d-flex">
                            <Button
                               variant="primary"
                               className="me-2"
                               onClick={toggleShowCurrentProjects}
                            >
                               {showCurrentProjects ? 'Show All Projects' : 'Show Current Projects'}
                            </Button>
                           <button type="button" className="btn btn-link" onClick={exportData} disabled={!(type === 'view')}>
                              <BsDownload /> Download PDF
                           </button>
                       </div>
                       </div>
                            <Table hover className='table-sm m-2'>
                                <tbody>
                                    {filteredProjects.map(project => {
                                        return (<tr key={project} 
                                            className={(projLov[project].project_end_date==='' || projLov[project].project_end_date>=formattedCurrentDate) ? '' : 'non-current-member'}
                                            >
                                            <td onClick={() => RedirectToSearchProject(projLov[project].project_name)}>{projLov[project].project_id}</td>
                                            <td onClick={() => RedirectToSearchProject(projLov[project].project_name)}>{projLov[project].project_name}</td>
                                            <td onClick={() => RedirectToSearchProject(projLov[project].project_name)}>
                                             <Form.Control
                                              type="date"
                                              value={projLov[project].project_start_date}
                                              disabled={true}
                                              />
                                            </td>
                                            <td onClick={() => RedirectToSearchProject(projLov[project].project_name)}>
                                             <Form.Control
                                              type="date"
                                              value={projLov[project].project_end_date}
                                              disabled={true}
                                              />
                                            </td>
                                            <td><button disabled={type === 'view' ? true : false} type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteMember(project)}><i className="bi bi-trash"></i>
                                            </button></td>
                                        </tr>)
                                    }
                                    )}

                                </tbody>
                            </Table>
                        </Container>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Typeahead
                                id="project-members-typeahead"
                                labelKey="display_key"
                                options={options}
                                onChange={setSelProject}
                                placeholder="Search project"
                                ref={projectDetailsField}
                                disabled={type === 'view' ? true : false}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Button variant="primary" onClick={addProject} className="me-2 mb-2" disabled={processing || (type === 'view' ? true : false)}>Add Project</Button>

                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="validationCustom02">
                      <Form.Label>SOW Remarks</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="SOW Remarks"
                          ref={sowRemarksField}
                          defaultValue={selSOW.sow_remarks}
                          disabled={type === 'view' ? true : false}
                        />
                    </Form.Group>
                    {type === 'view' ? <></> : <Button variant="primary" type="submit" className='me-2 mb-2' id='button-addon1' disabled={processing}>{submitTitle}</Button>}
                    <Button variant="secondary" onClick={handleClose} className="me-2 mb-2" disabled={processing}>{cancelTitle}</Button>
                </Form>
            </Modal.Body>
        </Modal >
    );
}
