
import { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import {Auth} from '@aws-amplify/auth';
//import './EmpForm.css'
import { Typeahead } from 'react-bootstrap-typeahead';
import { BsDownload } from 'react-icons/bs';
//import { exportCSV, exportPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports
import { saveAs } from 'file-saver';

export default function EmpForm({ type, show, setShow, fetchEmps, selEmp,projectDetails,setProjectDetails ,skillset,setSkillset,setProjects,projects, viewAlert , projLov}) {

    const idField = useRef();
    const firstNameField = useRef();
    const lastNameField = useRef();
    const addressStreetField = useRef();
    const cityField = useRef();
    const stateField = useRef();
    const zipField = useRef();
    const dobField = useRef();
    const joiningDtField = useRef();
    const itExperienceField = useRef();
    const relievedDtField = useRef();
    const officeEmailField = useRef();
    const personalEmailField = useRef();
    const projectstartDateField = useRef();
    const projectendDateField = useRef();
    const projectDetailsField = useRef();
    const contactNumberField = useRef();
    const projectBillableField = useRef();
    
    const [processing, setProcessing] = useState(false);
    const [showEmpIDWarning, setShowEmpIDWarning] = useState(false);
    const [showFirstNameWarning, setShowFirstNameWarning] = useState(false);
    const [showLastNameWarning, setShowLastNameWarning] = useState(false);

    const modalTitle = (type === 'update' ? 'Update' : 'User Details') ;

    const submitTitle = type === 'update' ? 'Update' : 'Create';
    const cancelTitle = type === 'view' ? 'Close' : 'Cancel';

    const navigate = useNavigate();
    const [selProject, setSelProject] = useState([]);

    const handleClose = () =>{
         setShow(false);
    }

        //populate proj lov    
        const options = [];
        for (let projID in projLov) {
            let lovproject = { 'ID': projID, 'display_key': projLov[projID].display_key }
            options.push(lovproject);
        }

    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}
    
    const onSubmit = (ev) => {
        console.log('onsubmit',projectDetails)
        ev.preventDefault();

        if(idField.current.value==='' || firstNameField==='' ||lastNameField===''){
          if(idField.current.value===''){
            setShowEmpIDWarning(true)}
        } if(firstNameField.current.value===''){
          setShowFirstNameWarning(true)
        } if(lastNameField.current.value===''){
          setShowLastNameWarning(true)
        }
        else{
        setProcessing(true);
       console.log(projectDetails)
        const newEmp = {
            "joining_date": joiningDtField.current.value,
            "email_id_office": officeEmailField.current.value,
            "relieved_date": relievedDtField.current.value,
            "address_state": stateField.current.value,
            "date_of_birth": dobField.current.value,
            "address_city": cityField.current.value,
            "email_id_personal": personalEmailField.current.value,
            "it_experience_from": itExperienceField.current.value,
            "zipcode": zipField.current.value,
            "last_name": lastNameField.current.value,
            "first_name": firstNameField.current.value,
            "ID": idField.current.value,
            "address_street": addressStreetField.current.value,
            "skillset":skillset,
            "contact_number": contactNumberField.current.value,
            "project_details":projectDetails
        };

        newEmp["search_name"] = (newEmp["first_name"] + " " + newEmp["last_name"]).toLowerCase();

        (async () => {
            let accessToken = await getAccessToken();
            const rawResponse = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/emp', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':accessToken
                },
                body: JSON.stringify(newEmp)
            });
            const content = await rawResponse.json();

            if (type === 'update') {
                fetchEmps();
            }
            setProcessing(false);
            handleClose();
            const alertMessage = {
                    header:"Employee "+(type === 'update' ? 'updated' : 'created'),
                    body:"Employee "+newEmp["ID"]+" is successfully "+(type === 'update' ? 'updated' : 'created')
                }
            viewAlert(alertMessage);
        })();
        }
    }

    const handleEmpIDChange = (event) => {
        const ID = event.target.value;
        if (ID.length > 0) {
         setShowEmpIDWarning(false); // Clear the warning
      } else {
         setShowEmpIDWarning(true); // Show warning when project name is not filled
         }
       };

        const handleFirstNameChange = (event) => {
        const name = event.target.value;
   
        if (name.length > 0) {
         setShowFirstNameWarning(false); // Clear the warning
      } else {
         setShowFirstNameWarning(true); // Show warning when project name is not filled
         }
       };

       const handleLastNameChange = (event) => {
        const name = event.target.value;
   
        if (name.length > 0) {
         setShowLastNameWarning(false); // Clear the warning
      } else {
         setShowLastNameWarning(true); // Show warning when project name is not filled
         }
       };

       //const [skills, setSkills] = useState([]);
       const [currentSkill, setCurrentSkill] = useState('');
     
       const handleInputChange = (event) => {
         setCurrentSkill(event.target.value);
       };
     
       const handleAddSkill = () => {
         if (currentSkill.trim() !== '') {
           setSkillset((prevSkills) => [...prevSkills, currentSkill.toLowerCase()]);
           setCurrentSkill('');
         }
       };
     
       const handleRemoveSkill = (index) => {
         setSkillset((prevSkills) => {
           const updatedSkills = [...prevSkills];
           updatedSkills.splice(index, 1);
           return updatedSkills;
         });
       };

       const updateProjectDates = (projectID, startDate, endDate, billable,status) => {
        const updatedProjects = projectDetails.map(project => {
          if (project.ID === projectID) {
            return {
              ...project,
              startDate,
              endDate,
              billable,
              status: endDate >= formattedCurrentDate || endDate === '' ? 'active' : 'in-active'
            };
          }
          return project;
        });
        setProjectDetails(updatedProjects);
      };

      const deleteProject = (projectID) => {
        const newProjects = projectDetails.filter(project => project.ID !== projectID);
        setProjectDetails(newProjects);
      };

      const formattedCurrentDate = new Date().toISOString().slice(0, 10); // Get current date in yyyy-mm-dd format

      const addProject = () => {
        console.log(projectendDateField.current.value)
        if (selProject.length > 0) {
          const selectedProject = selProject[0];
          if (!projectDetails.some(project => project.ID === selectedProject.ID)) {
            const newProject = {
              ID: selectedProject.ID,
              startDate: projectstartDateField.current.value,
              endDate: projectendDateField.current.value,
              billable: projectBillableField.current.checked,
              status: (projectendDateField.current.value >= formattedCurrentDate ||
                projectendDateField.current.value==='') ? 'active' : 'in-active'
            };
            setProjectDetails([...projectDetails, newProject]);
            projectDetailsField.current.clear();
            setSelProject([]);
            projectstartDateField.current.value = '';
            projectendDateField.current.value = '';
            projectBillableField.current.checked = false;
          }
        }
      };

      const [showCurrentProjects, setShowCurrentProjects] = useState(false);

      const toggleShowCurrentProjects = () => {
        setShowCurrentProjects(!showCurrentProjects);
      };

      const filteredProjects = showCurrentProjects
        ? projectDetails.filter(project => (project.endDate >= formattedCurrentDate || project.endDate===''))
        : projectDetails;
/*
        const exportData = (e) => {
          // Export as CSV
          if(e==='csv'){
            if (filteredProjects.length>0){
          const csvData = exportCSV(filteredProjects, projLov, selEmp);
          const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          saveAs(csvBlob, 'employees.csv');
          //document.getElementById("export_select").value = "";
            }
      }
         else{
          // Export as PDF
          if (filteredProjects.length>0){
            const pdfData = exportPDF(filteredProjects, projLov, selEmp);
            const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
            saveAs(pdfBlob, selEmp.first_name+'_'+selEmp.last_name +'-projects.pdf');
          //document.getElementById("export_select").value = ""; 
          }
      }
        };
*/
        function RedirectToSearchProject(projectId) {
          navigate(`/projecttracker/project?projectId=${projectId}`);
        }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h5>{modalTitle}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>ID *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Employee ID"
                            ref={idField}
                            defaultValue={selEmp.ID?.S}
                            disabled={type == 'new' ? false : true}
                            onChange={handleEmpIDChange}
                        />
                        { showEmpIDWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please enter a Employee ID.
                          </div>
                        )}
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First name"
                                ref={firstNameField}
                                defaultValue={selEmp.first_name?.S}
                                disabled={type === 'view' ? true : false}
                                onChange={handleFirstNameChange}
                            />
                        { showFirstNameWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please enter a First Name.
                          </div>
                        )}
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last name"
                                ref={lastNameField}
                                defaultValue={selEmp.last_name?.S}
                                disabled={type === 'view' ? true : false}
                                onChange={handleLastNameChange}
                            />
                        { showLastNameWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please enter a Last Name.
                          </div>
                        )}
                        </Form.Group>
                    </Row>
                    {/*
                    <Form.Group controlId="formBasicEmail">
  <Form.Label>Skillset</Form.Label>
  <div className="skill-list">
    {skillset.map((skill, index) => (
      <div key={index} className="skill-item">
        {skill.charAt(0).toUpperCase() + skill.slice(1).toLowerCase()}
        <Button
          variant="link"
          className="remove-skill-button"
          onClick={() => handleRemoveSkill(index)}
          disabled={type === 'view' ? true : false}
        >
          x
        </Button>
      </div>
    ))}
  </div>
  {type === 'view' && skillset.length>0 ? null : 
  <div className="input-container">
    <Form.Control
      type="text"
      placeholder="Enter a skill"
      value={currentSkill}
      onChange={handleInputChange}
      className="skill-input"
      disabled={type === 'view' ? true : false}
    />
    <Button className="add-skill-button" onClick={handleAddSkill} disabled={type === 'view' ? true : false}>
      Add Skill
    </Button>
  </div>}
</Form.Group>
                    <Row>
                        <Container>
<div className="d-flex justify-content-between align-items-center mb-2">
  <p>
    Projects <Badge bg="warning" text="dark">
      {projects.length}
    </Badge>
  </p>
  <div className="d-flex">
    <Button
      variant="primary"
      className="me-2"
      onClick={toggleShowCurrentProjects}
    >
      {showCurrentProjects ? 'Show All Projects' : 'Show Current Projects'}
    </Button>
    <button type="button" className="btn btn-link" onClick={exportData} disabled={!(type === 'view')}>
      <BsDownload /> Download PDF
    </button>
  </div>
</div>
                            <Table hover className='table-sm m-2'>
                            <tbody>
                            <tr>
    <th>Project Name</th>
    <th>Start Date</th>
    <th>End Date</th>
    <th>Billable</th> 
    <th></th>
  </tr>
          {filteredProjects.map(project => (
  <tr key={project.ID} className={(project.status==='active') ? '' : 'non-current-member'}
  >

  <td onClick={() => RedirectToSearchProject(projLov[project.ID].project_name)}>{projLov[project.ID].project_name}</td>
      <td>
        <Form.Control
          type="date"
          value={project.startDate}
          disabled={type === 'view'}
          onChange={(e) => updateProjectDates(project.ID, e.target.value, project.endDate, project.billable,project.status)}
        />
      </td>
      <td>
        <Form.Control
          type="date"
          value={project.endDate}
          disabled={type === 'view'}
          onChange={(e) => updateProjectDates(project.ID, project.startDate, e.target.value, project.billable,project.status)}
        />
      </td>
      <td>
      <Form.Check
        type="checkbox"
        checked={project.billable}
        disabled={type === 'view'}
        onChange={(e) => updateProjectDates(project.ID, project.startDate, project.endDate, e.target.checked,project.status)}
        style={{textAlign:'center'}}
      />
    </td>
      <td>
        <button
          disabled={type === 'view'}
          type="button"
          className="btn btn-outline-danger btn-sm"
          onClick={() => deleteProject(project.ID)}
        >
          <i className="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  ))}
</tbody>

                            </Table>
                        </Container>
                    </Row>
                    <Row className="mb-3">
  <Form.Group as={Col} md="4" controlId="validationCustom03">
    <Typeahead
      id="project-members-typeahead"
      labelKey="display_key"
      options={options}
      onChange={setSelProject}
      placeholder="Search project"
      ref={projectDetailsField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
  <Form.Group as={Col} md="3" controlId="validationCustom04">
    <Form.Control
      type="date"
      placeholder="Start Date"
      ref={projectstartDateField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
  <Form.Group as={Col} md="3" controlId="validationCustom05">
    <Form.Control
      type="date"
      placeholder="End Date"
      ref={projectendDateField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
  <Form.Group as={Col} md="2" controlId="validationCustom05">
  <Form.Check
    type="checkbox"
    label="Billable"
    ref={projectBillableField}
    disabled={type === "view" ? true : false}
  />
</Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04" style={{marginTop:'7px'}}>
                            <Button variant="primary" onClick={addProject} className="me-2 mb-2" id='button-addon1' disabled={processing || (type === 'view' ? true : false)}>Add Project</Button>

                        </Form.Group>
                    </Row>
          */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address Street</Form.Label>
                        <Form.Control type="text" placeholder="Enter Address" ref={addressStreetField} defaultValue={selEmp.address_street?.S} disabled={type === 'view' ? true : false} />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" ref={cityField} defaultValue={selEmp.address_city?.S} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" placeholder="State" ref={stateField} defaultValue={selEmp.address_state?.S} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text" placeholder="Zipcode" ref={zipField} defaultValue={selEmp.zipcode?.S} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom06">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="text"placeholder="Contact Number"ref={contactNumberField}defaultValue={selEmp.contact_number?.S}disabled={type === 'view' ? true : false}/>
  </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control type="date" ref={dobField} defaultValue={selEmp.date_of_birth?.S} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>Joining Date</Form.Label>
                            <Form.Control type="date" ref={joiningDtField} defaultValue={selEmp.joining_date?.S} disabled={type === 'view' ? true : false} />

                        </Form.Group>
  
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                            <Form.Label>Email id Office</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    aria-describedby="inputGroupPrepend"
                                    ref={officeEmailField}
                                    defaultValue={selEmp.email_id_office?.S}
                                    disabled={type === 'view' ? true : false}
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                            <Form.Label>Email id Personal</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    aria-describedby="inputGroupPrepend"
                                    ref={personalEmailField}
                                    defaultValue={selEmp.email_id_personal?.S}
                                    disabled={type === 'view' ? true : false}
                                />

                            </InputGroup>
                        </Form.Group>
                    </Row>
                    {type === 'view' ? <></> : <Button variant="primary" type="submit" className='me-2 mb-2' id="button-addon1" disabled={processing}>{submitTitle}</Button>}
                    <Button variant="secondary" onClick={handleClose} className="me-2 mb-2" disabled={processing}>{cancelTitle}</Button>
                </Form>
            </Modal.Body>
        </Modal >
    );
}
