import jsPDF from 'jspdf';
import exceljs from 'exceljs'; // Import exceljs library

// Helper function to flatten nested objects
function flattenObject(obj) {
  const flattened = {};

  function flatten(currentObj, propName) {
    if (currentObj === null || typeof currentObj !== 'object' || Array.isArray(currentObj)) {
      flattened[propName] = currentObj;
    } else {
      for (const [key, value] of Object.entries(currentObj)) {
        const newPropName = propName ? `${propName}.${key}` : key;
          flatten(value, newPropName);
      }
    }
  }

  flatten(obj, '');

  return flattened;
}

// Export employee data as CSV
export const exportCSV = (data) => {
  const flattenedData = data.map((vendor) => flattenObject(vendor));

  // Define the desired column order
  const columnOrder = [
    'vendor_name','vendor_email','vendor_phone',
    'street_address','city_name','state_name', 'zipcode',
     'start_date','approved_by','approved_date','vendor_remarks'
  ];

  // Filter the columns based on the desired order
  const filteredHeaders = columnOrder.filter((header) => Object.keys(flattenedData[0]).includes(header));

  const rows = flattenedData.map((project) =>
    filteredHeaders.map((header) => `"${project[header]?.toString().replace(/"/g, '""') || ''}"`)
  );

  const csvContent = `${filteredHeaders.join(',')}\n${rows.map((row) => row.join(',')).join('\n')}`;
  return csvContent;
};

export const exportPDF = (data, empLov,selProject) => {
  const flattenedData = data.map((member) => {
    return {
      ID: member.ID,
      EmployeeName: `${empLov[member.ID]?.first_name} ${empLov[member.ID]?.last_name}` || 'N/A',
      StartDate: member.startDate,
      EndDate: member.endDate,
      Billable: member.billable ? 'Yes' : 'No',
    };
  });

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
      //const marginLeft = 250;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);  const title=selProject.project_name + ' ProjectMembers Data'

       // Get the width of the title
       const titleWidth = doc.getTextWidth(title);

       // Calculate the x-coordinate of the starting point
        const xPos = (doc.internal.pageSize.width - titleWidth) / 2;
   
        doc.text(title, xPos, 40);

  const tableHeaders = Object.keys(flattenedData[0]);
  const tableData = flattenedData.map((member) => Object.values(member));

  const tableStyle = {
    theme: 'grid',
    headStyles: {
      fillColor: [235, 191, 97],
      textColor: [51, 51, 51],
      //fontStyle: 'bold',
      //fontSize: 12
    },

    styles: {
      lineWidth: 0.2,
      lineColor: [192, 192, 192],
      fontStyle: 'normal',
      fontSize: 10,
      cellPadding: 5
    }
  };

    doc.autoTable({
      startY: 50,
      head: [tableHeaders],
      body: tableData,
      tableWidth: 'auto',
      columnStyles: 'auto', // set the width of the 4th column to 80
      ...tableStyle
    });

  return doc.output('blob');
};



export const exportXLSX = (data) => {
  const flattenedData = data.map((vendor) => flattenObject(vendor));

  // Define the desired column order
  const columnOrder = [
    'vendor_name','vendor_email','vendor_phone',
    'street_address','city_name','state_name', 'zipcode',
     'start_date','approved_by','approved_date','vendor_remarks'
  ];

  const workbook = new exceljs.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Add column headers
  const columns = columnOrder.filter((header) => Object.keys(flattenedData[0]).includes(header));
  worksheet.addRow(columns);

  // Add data rows
  flattenedData.forEach((row) => {
    const rowData = columns.map((column) => row[column]?.toString().replace(/"/g, '""') || '');
    worksheet.addRow(rowData);
  });

  // Generate and return the Excel file as a Blob
  return workbook.xlsx.writeBuffer();
};