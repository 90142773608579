import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './ReportTable.css'
import { useState } from 'react';
import exceljs from 'exceljs'; // Import exceljs library
import { saveAs } from 'file-saver';

const ReportTable = ({ show, setShow, selreport, selReportName, selReportHeader,clientLov, projLov, viewAlert }) => {

  const [expandedGroups, setExpandedGroups] = useState([]);

  // Function to close the report table
  const closeReportTable = () => {
    setShowActiveEmployees(true);
    setShow(false);
  };

  // Function to get the column names from the first report data object
  const getColumnNames = () => {
    if (selreport.length > 0) {
      return Object.keys(selreport[0]);
    }
    return [];
  };

  
// Function to convert table data to CSV format
const convertToCSV = (data) => {
  const columns = getColumnNames();
  const csvRows = [];

  // Add the column headers as the first row
  const header = columns.join(',');
  csvRows.push(header);

  // Convert each row of data to CSV format
  data.forEach((row) => {
    const values = columns.map((column) => {
      let value = row[column];

      // Handle special case for 'client_name' column
      if (column === 'Client_name' && value!=='No Projects Assigned') {
        value = clientLov[value]?.client_name || value;
      }
      if (column === 'Project_name' && value!=='') {
        value =projLov[value]?.project_name || value;
      }
      // Check if the value contains commas or arrays
      if (typeof value === 'string' && (value.includes(',') || value.includes('[') || value.includes(']'))) {
        value = `"${value}"`; // Wrap the value with double quotes
      }
      
      return value;
    });

    const csvRow = values.join(',');
    csvRows.push(csvRow);
  });

  // Combine all rows into a single CSV string
  return csvRows.join('\n');
};

  
    // Function to trigger the download
    const downloadAsCSV = () => {

      if (selReportHeader==="Employees list with projects they are working in" || selReportHeader==="Employees buffer list"
      || selReportHeader==="Employees bench list"){
      const csvData = convertToCSV(filteredReport);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${selReportHeader}.csv`;
      a.click();
      URL.revokeObjectURL(url);
      }
      else{
        const csvData = convertToCSV(selreport);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${selReportHeader}.csv`;
        a.click();
        URL.revokeObjectURL(url);
      }
    };


          // Function to convert table data to XLSX format
  const convertToXLSX = (data) => {
    const columns = getColumnNames();

    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    
    // Add column headers
    worksheet.addRow(columns);

    // Add data rows
    data.forEach((row) => {
      const rowData = columns.map((column) => {
        let value = row[column];
  
        // Handle special case for 'Client_name' column
        if (column === 'Client_name' && value !== 'No Projects Assigned') {
          value = clientLov[value]?.client_name || value;
        }
  
        // Handle special case for 'Project_name' column
        if (column === 'Project_name' && value !== '') {
          value = projLov[value]?.project_name || value;
        }
        
        // Check if the value contains commas or arrays
        if (typeof value === 'string' && (value.includes(',') || value.includes('[') || value.includes(']'))) {
          value = `"${value}"`; // Wrap the value with double quotes
        }
        return value;
      });
      worksheet.addRow(rowData);
    });

    // Generate and return the Excel file as a Blob
    return workbook.xlsx.writeBuffer();
  };

  // Function to trigger the XLSX download
  const downloadAsXLSX = async () => {
    if (selReportHeader==="Employees list with projects they are working in" || selReportHeader==="Employees buffer list"
    || selReportHeader==="Employees bench list"){
    const xlsxBuffer = await convertToXLSX(filteredReport);
    const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${selReportHeader}.xlsx`);
    }
    else{
      const xlsxBuffer = await convertToXLSX(selreport);
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${selReportHeader}.xlsx`);
    }
  };

    const [showActiveEmployees, setShowActiveEmployees] = useState(true);

    const toggleShowActiveEmployees = () => {
      setShowActiveEmployees(!showActiveEmployees);
    };

    const filteredReport = showActiveEmployees
      ? selreport.filter(employee => (employee.Emp_status === "active"))
      : selreport;

// Function to render the table headers dynamically
const renderTableHeaders = () => {
  const columns = getColumnNames();
  const indexedColumns = ['Index', ...columns]; // Add 'Index' as the first column

  if (selReportName === 'employees list with projects they are working in') {
    const allowedColumns = [ "Index", "Employee_ID", "FirstName", "LastName", "Project_name", "Emp_billable","Emp_status"];

    return (
      <tr>
        {allowedColumns.map((column, index) => {
          if (allowedColumns.includes(column)) {
            return <th key={index}>{column}</th>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    );
    
  } 
  else if (selReportName === 'employees buffer list') {
    const allowedColumns = [ "Index", "Employee_ID", "FirstName", "LastName","Project_name"];

    return (
      <tr>
        {allowedColumns.map((column, index) => {
          if (allowedColumns.includes(column)) {
            return <th key={index}>{column}</th>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    );
  }
  else if (selReportName === 'expiring sow') {
    const allowedColumns = [ "Index", "SOW_number", "SOW_name", "SOW_start_date", "SOW_end_date","Project_name"];

    return (
      <tr>
        {allowedColumns.map((column, index) => {
          if (allowedColumns.includes(column)) {
            return <th key={index}>{column}</th>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    );
  }
  else if (selReportName === 'employees bench list') {
    const allowedColumns = [ "Index", "Employee_ID", "FirstName", "LastName", "Project_name"];

    return (
      <tr>
        {allowedColumns.map((column, index) => {
          if (allowedColumns.includes(column)) {
            return <th key={index}>{column}</th>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    );
  }

  
  else if (selReportName === 'employees - projects ending') {
    const allowedColumns = [ "Index", "Employee_ID", "FirstName", "LastName", "Project_name", "Emp_end_date","Emp_billable"];

    return (
      <tr>
        {allowedColumns.map((column, index) => {
          if (allowedColumns.includes(column)) {
            return <th key={index}>{column}</th>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    );
    
  } 


      else {
    return (
      <tr>
        {indexedColumns.map((column, index) => (
          <th key={index}>{column}</th>
        ))}
      </tr>
    );
  }
};

const groupData = (data) => {
  // Implement your grouping logic here
  // Example: Group by Employee ID and Client Name
  const groupedData = {};
  data.forEach((row) => {
    const key = `${row.Employee_ID}_${row.Client_name}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(row);
  });
  return Object.values(groupedData);
};

const groupedRows = groupData(filteredReport);


 // Function to toggle group expansion
 const toggleGroupExpansion = (groupIndex) => {
  const newExpandedGroups = [...expandedGroups];
  if (newExpandedGroups.includes(groupIndex)) {
    const index = newExpandedGroups.indexOf(groupIndex);
    newExpandedGroups.splice(index, 1);
  } else {
    newExpandedGroups.push(groupIndex);
  }
  setExpandedGroups(newExpandedGroups);
};

const renderGroupedRows = () => {
  return groupedRows.map((group, groupIndex) => (
    <React.Fragment key={groupIndex}>
      <tr onClick={() => toggleGroupExpansion(groupIndex)}>
        <td>{groupIndex+1}</td>
        <td>{group[0].Employee_ID}</td>
        <td>{group[0].FirstName}</td>
        <td>{group[0].LastName}</td>
        <td>{clientLov[group[0].Client_name].client_name}</td>

      </tr>
      {expandedGroups.includes(groupIndex) &&
        group.map((data, dataIndex) => (
          <tr key={dataIndex}>
            {/* Render individual row data */}
            <td>-</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data.Project_name}</td>
            {/* ... Render other columns */}
          </tr>
        ))}
    </React.Fragment>
  ));
};
// Function to render the table rows dynamically
const renderTableRows = () => {
  const columns = getColumnNames();

  if (selReportName === 'employees list with projects they are working in') {
    const allowedColumns = ["Employee_ID", "FirstName", "LastName", "Project_name", "Emp_billable","Emp_status"];

    return filteredReport.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td> {/* Add the indexing here */}
        {allowedColumns.map((column, columnIndex) => {
          if (allowedColumns.includes(column)) {
            return <td key={columnIndex} style={{ color: data['Emp_status'] === "in-active" ? "red" : null }}>{data[column]}</td>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    ));
  } 
  else if (selReportName === 'employees buffer list') {
    const allowedColumns = ["Employee_ID", "FirstName", "LastName", "Project_name"];

    return groupedRows.map((group, groupIndex) => (
      <React.Fragment key={groupIndex}>
      {/* Group header row */}
      <tr onClick={() => toggleGroupExpansion(groupIndex)}>
        <td key={groupIndex} style={{ color: group[0]['Emp_status'] === "in-active" ? "red" : null }}>{groupIndex + 1}</td>
        <td key={groupIndex} style={{ color: group[0]['Emp_status'] === "in-active" ? "red" : null }}>{group[0].Employee_ID}</td>
        <td key={groupIndex} style={{ color: group[0]['Emp_status'] === "in-active" ? "red" : null }}>{group[0].FirstName}</td>
        <td key={groupIndex} style={{ color: group[0]['Emp_status'] === "in-active" ? "red" : null }}>{group[0].LastName}</td>
        <td key={groupIndex} style={{ color: group[0]['Emp_status'] === "in-active" ? "red" : null }}>+ {clientLov[group[0].Client_name].client_name}</td>
        {/* Add expand/collapse icon */}
        <td>
          <i
            className={`bi ${
              expandedGroups.includes(groupIndex)
                ? 'bi-chevron-up collapse-icon'
                : 'bi-chevron-down expand-icon'
            }`}
          ></i>
        </td>
      </tr>
      {/* Expanded rows */}
      {expandedGroups.includes(groupIndex) &&
        group.map((data, dataIndex) => (
          <tr key={dataIndex}>
            {/* Render individual row data */}
            <td>-</td>
            <td></td>
            <td></td>
            <td></td>
            <td> - {data.Project_name}</td>
            {/* ... Render other columns */}
          </tr>
        ))}
    </React.Fragment>
    ));
  }
  else if (selReportName === 'expiring sow') {
    const allowedColumns = [ "SOW_number", "SOW_name", "SOW_start_date", "SOW_end_date","Project_name"];

    return selreport.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td> {/* Add the indexing here */}
        {allowedColumns.map((column, columnIndex) => {
          if (allowedColumns.includes(column)) {
            if(column==="Client_name"){
              return <td key={columnIndex}>{clientLov[data[column]].client_name}</td>;
            }
            else if(column==="Project_name"){
              return <td key={columnIndex}>{projLov[data[column]]?.project_name}</td>;
            }
            
            return <td key={columnIndex}>{data[column]}</td>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    ));
  }
  else if (selReportName === 'employees bench list') {
    const allowedColumns = ["Employee_ID", "FirstName", "LastName", "Project_name"];

    return filteredReport.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td> {/* Add the indexing here */}
        {allowedColumns.map((column, columnIndex) => {
          if (allowedColumns.includes(column)) {
            return <td key={columnIndex}>{data[column]}</td>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    ));
  }
  else if (selReportName === 'employees - projects ending') {
    const allowedColumns = ["Employee_ID", "FirstName", "LastName", "Project_name","Emp_end_date", "Emp_billable"];

    return filteredReport.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td> {/* Add the indexing here */}
        {allowedColumns.map((column, columnIndex) => {
          if (allowedColumns.includes(column)) {
            return <td key={columnIndex} style={{ color: data['Emp_status'] === "in-active" ? "red" : null }}>{data[column]}</td>;
          }
          return null; // Exclude other columns from rendering
        })}
      </tr>
    ));
  } 
  
  else {
    return selreport.map((data, index) => (
      <tr key={index}>
        <td>{index + 1}</td> {/* Add the indexing here */}
        {columns.map((column, columnIndex) => (
          <td key={columnIndex}>{data[column]}</td>
        ))}
      </tr>
    ));
  }
};

  return (
    <Modal className="report-modal" show={show} onHide={closeReportTable}>
      <Modal.Header closeButton>
        <Modal.Title>{selReportHeader}</Modal.Title>
        <div className="d-flex">
        {selReportName=== "employees list with projects they are working in" ||
         selReportName=== "employees buffer list" ||
         selReportName=== "employees bench list" ? 
        <Button
        variant="primary"
        className="me-2 toggleButton"
        onClick={toggleShowActiveEmployees}
      >
        {showActiveEmployees ? 'All Records' : 'Active Records'}
      </Button>
        : null}
        {/*
        <Button className="report-download-csv" variant="primary" onClick={downloadAsCSV}>
        <i className="bi bi-download"></i>  Download CSV
        </Button>

        <Button className="report-download-csv" variant="primary" onClick={downloadAsXLSX}>
        <i className="bi bi-download"></i>  Download Excel
        </Button>
  */}
        <select id="export_select_prj_report_table" onChange={(e) => {
                            if (e.target.value === 'csv') {
                              downloadAsCSV();
                            } else if (e.target.value === 'xlsx') {
                              downloadAsXLSX();
                              }
                            }}>
                        <option value="" disabled selected>Export As</option>
                        <option value="xlsx">Excel</option>
                        <option value="csv">CSV</option>
                       </select>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover responsive>
        {selreport.length===0 ? <h4 style={{textAlign:"center"}}>No records found </h4> : (
          <>
          <thead>{renderTableHeaders()}</thead>
          <tbody>{renderTableRows()}</tbody>
          </>
        )
        }
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeReportTable}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportTable;


