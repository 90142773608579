
import { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Auth } from '@aws-amplify/auth';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './IssueFormUpdate.css'
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import AlertModal from "./AlertModal";

//import 'bootstrap/dist/css/bootstrap.css';

export default function ProjectForm({ type, show, setShow, fetchdata, selIssue,selStatus,setSelStatus,emails,setSelAssignedEmail,
                                      selAssignedEmail,setSelRemarksHistory,selRemarksHistory, setSelTicketCategory, selTicketCategory,
                                      setSelHardwareType, selHardwareType,
                                      setSelTicketType, selTicketType,role,setSelApprovalEmail,selApprovalEmail, setApproval,
                                      approval,viewAlert,initialSelStatus,fetchdatabytokennumber,isPreviousFetchData, selProjectName,
                                      setSelProjectName,assigned,setAssigned }) {

    const ticketNumberField = useRef();
    const dateField = useRef();
    const nameField = useRef();
    const emailField = useRef();
    const subjectLineField = useRef();
    const issueDescriptionField = useRef();
    const remarksField = useRef();
    const statusField = useRef();
    const isApprovalNeededField = useRef();
    const assingedemailField = useRef();
    const ticketCategoryField = useRef();
    const hardwareTypeField = useRef();
    const ticketTypeField = useRef();
    const approvalemailField = useRef();
    const projectNameField = useRef();
    const isAssignThisTicketField = useRef();

    const user_email = Auth.user.attributes.email

    const [processing, setProcessing] = useState(false);
    
    const modalTitle = (type === 'update' ? 'Update' : 'View') + ' status';

    const submitTitle = type === 'update' ? 'Update' : 'Create';
    const cancelTitle = type === 'view' ? 'Close' : 'Cancel';

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});

    const [lastRemark, setLastRemark] = useState("");

    const [showStatusWarning, setShowStatusWarning] = useState(false);
    const [showRemarksWarning, setShowRemarksWarning] = useState(false);
    const [showApprovalFromWarning, setShowApprovalFromWarning] = useState(false);
    const [showAssignedtoWarning, setShowAssignedtoWarning] = useState(false);

    const handleClose = () => {
        setShow(false);
        setViewModeRemarksHistory('close')
        setShowStatusWarning(false)
        setShowRemarksWarning(false)
        setShowApprovalFromWarning(false)
        setShowAssignedtoWarning(false)
      };
      
      function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
      }

    //populate emp lov    
    const emailOptionsAssigned = [];
      for (let email in emails) {
        if(emails[email].email!==user_email){
            let emailMember = { 'ID': emails[email].email, 'display_key': emails[email].email }
            emailOptionsAssigned.push(emailMember);
      }
    }
    //populate emp lov    
    const emailOptionsApproval = [];
    for (let email in emails) {
      if(emails[email].is_approval_from_email==='yes' && emails[email].email!=user_email){
          let emailMember = { 'ID': emails[email].email, 'display_key': emails[email].email }
          emailOptionsApproval.push(emailMember);
    }
  }

  const statusOptions = [
    ...(initialSelStatus[0]?.Type === 'In-Progress' || initialSelStatus[0]?.Type === 'Completed' ||
        initialSelStatus[0]?.Type === 'To be Reviewed' || initialSelStatus[0]?.Type === 'Approved' ||
        initialSelStatus[0]?.Type === 'Re-Opened'
      ? [{ Type: 'New', display_key: 'New', disabled: true }]
      : [{ Type: 'New', display_key: 'New' }]
    ),

    ...( initialSelStatus[0]?.Type === 'Completed' || initialSelStatus[0]?.Type === 'To be Reviewed'
    ? [{ Type: 'In-Progress', display_key: 'In-Progress', disabled: true  }]:
    [{ Type: 'In-Progress', display_key: 'In-Progress'}]
    ),

    ...(role === 'assigned-admin'
      ? [{ Type: 'To be Reviewed', display_key: 'To be Reviewed' }]
      : []
    ),

    ...(role !== 'assigned-admin'
      ? [{ Type: 'Completed', display_key: 'Completed' }]
      : []
    )
  ];

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    const onSubmit = (ev) => {
      ev.preventDefault();
    
      setProcessing(true);
    
      const status = approval === 'yes' && selIssue.is_approved === '' ? 'Approval-Pending' : selStatus[0]?.Type;
    
      if (
        !status ||
        remarksField.current.value === '' ||
        (approval === 'yes' && selApprovalEmail.length === 0) ||
        (assigned === 'yes' && selAssignedEmail.length === 0) ||
        (selApprovalEmail[0] && selApprovalEmail[0] && selAssignedEmail[0]?.ID === selApprovalEmail[0]?.ID) ||
        (selApprovalEmail[0] && selApprovalEmail[0].ID === selIssue.email) ||
        (selAssignedEmail[0] && selAssignedEmail[0].ID === selIssue.email)
      ) {
        // Error handling code
        if (!status) {
          setShowStatusWarning(true);
        }
        if (remarksField.current.value === '') {
          setShowRemarksWarning(true);
        }
        if (approval === 'yes' && selApprovalEmail.length === 0) {
          setShowApprovalFromWarning(true);
        }
        if (assigned === 'yes' && selAssignedEmail.length === 0) {
          setShowAssignedtoWarning(true);
        }
        if (selApprovalEmail[0] && selApprovalEmail[0] && selAssignedEmail[0]?.ID === selApprovalEmail[0]?.ID) {
          const alertMessage = {
            header: 'Error',
            body: 'Approval from and Ticket Assigned to cannot be the same person',
          };
          viewAlert(alertMessage);
        }
        if (selApprovalEmail[0] && selApprovalEmail[0].ID === selIssue.email) {
          const alertMessage = {
            header: 'Error',
            body: 'Approval from and Ticket raised email cannot be the same',
          };
          viewAlert(alertMessage);
        }
        if (selAssignedEmail[0] && selAssignedEmail[0].ID === selIssue.email) {
          const alertMessage = {
            header: 'Error',
            body: 'Assigned to and Ticket raised email cannot be the same',
          };
          viewAlert(alertMessage);
        }
    
        setProcessing(false);
      } else {
        const timestamp = moment().format('DD/MM/YYYY, HH:mm:ss A');
        let newRemarks = remarksField.current.value.trim();
    
        // Append new "edited on" timestamp to task description
        newRemarks = `${newRemarks}\n(${timestamp})`;
    
        const newRemark = {
          name: Auth.user.attributes.name,
          remarks: newRemarks,
        };
    
        setLastRemark(remarksField.current.value + ' ' + `(${Auth.user.attributes.name})`);
        setSelRemarksHistory((prevRemarks) => {
          const updatedRemarks = [...prevRemarks, newRemark];
    
          const newIssue = {
            ticket_number: selIssue.ticket_number,
            date: selIssue.date,
            name: selIssue.name,
            email: selIssue.email,
            subject_line: selIssue.subject_line,
            issue_description: selIssue.issue_description,
            remarks: '',
            status: status,
            timestamp: selIssue.timestamp,
            is_approval_needed: approval,
            is_approved: selIssue.is_approved,
            assigned_to: selAssignedEmail[0] ? selAssignedEmail[0].ID : '',
            approval_from: selApprovalEmail[0] ? selApprovalEmail[0].ID : '',
            remark_history: updatedRemarks,
            ticket_category: selIssue.ticket_category,
            ticket_type: selIssue.ticket_type,
            hardwaretype: selIssue.hardwaretype,
            last_remark: remarksField.current.value + ' ' + `(${Auth.user.attributes.name})`,
            attachment: selIssue.attachment,
            projectname: selIssue.projectname,
            role:'admin',
            assign_this_ticket:assigned
          };
    
          (async () => {
            let accessToken = await getAccessToken();
            const rawResponse = await fetch('https://1gu7g5j872.execute-api.us-east-1.amazonaws.com/dev/updateissue2', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: accessToken,
              },
              body: JSON.stringify(newIssue),
            });
    
            const content = await rawResponse.json();
            console.log(content);
            if (type === 'update') {
              if (isPreviousFetchData){
                fetchdata();
              }
              else{
                fetchdatabytokennumber(selIssue.ticket_number);
              }
              
            }
            setProcessing(false);
            setLastRemark('');
            setApproval('');
            handleClose();
            const alertMessage = {
              header: 'Ticket ' + (type === 'update' ? 'updated' : 'created'),
              body: 'Ticket ' + newIssue['ticket_number'] + ' is successfully ' + (type === 'update' ? 'updated' : 'created'),
            };
            viewAlert(alertMessage);
          })();
    
          return updatedRemarks;
        });
      }
    };
    

    //const [approval, setApproval] = useState(''); // Initialize the approval state

    const handleApprovalChange = (e) => {
        setApproval(e.target.value);
        if (e.target.value==='no'){
          setShowApprovalFromWarning(false);
          setSelApprovalEmail([]);
        }
      };

      const handleAssignedChange = (e) => {
        setAssigned(e.target.value);
        if (e.target.value==='no'){
          setShowAssignedtoWarning(false);
          setSelAssignedEmail([]);
        }
      };
      
      //useEffect(() => {
        // Set the initial approval value when the component mounts
        //setApproval(selIssue.is_approval_needed ? selIssue.is_approval_needed : 'no');
     // }, []);



    /*const handleSubmit = () => {
      if (remarksField.current.value!==""){
        const timestamp = moment().format('DD/MM/YYYY, HH:mm:ss A') ;
        let newRemarks = remarksField.current.value.trim();

        // Append new "edited on" timestamp to task description
      newRemarks = `${newRemarks}\n(${timestamp})`;
      
        const newRemark = {
          name: Auth.user.attributes.name,
          remarks: newRemarks
        }
        setLastRemark(remarksField.current.value+' '+`(${Auth.user.attributes.name})`)
        setSelRemarksHistory([...selRemarksHistory, newRemark]);
      }
    };*/

    const [viewModeRemarksHistory, setViewModeRemarksHistory] = useState('close'); // 'all' or 'current'

  
    const toggleRemarksHistory = () => {
      setViewModeRemarksHistory(prevMode => (prevMode === 'open' ? 'close' : 'open'));
    };

    const handleStatusChange = (selectedStatus) => {

      if (selectedStatus.length > 0) {
        setSelStatus(selectedStatus);
        setShowStatusWarning(false); // Clear the warning
      } else {
        setSelStatus([]); // Reset project lead if no selection
        setShowStatusWarning(true); // Show warning when project lead is not selected
      }
    };
    const handleRemarksChange = (event) => {
      const Remarks = event.target.value;
      if (Remarks.length > 0) {
       setShowRemarksWarning(false); // Clear the warning
    } else {
       setShowRemarksWarning(true); // Show warning when project name is not filled
       }
     };

     const handleApprovalFromChange = (selectedEmail) => {

      if (selectedEmail.length > 0) {
        setSelApprovalEmail(selectedEmail);
        setShowApprovalFromWarning(false); // Clear the warning
      } else {
        setSelApprovalEmail([]); // Reset project lead if no selection
        setShowApprovalFromWarning(true); // Show warning when project lead is not selected
      }
    };

    const handleAssignedtoChange = (selectedEmail) => {

      if (selectedEmail.length > 0) {
        setSelAssignedEmail(selectedEmail);
        setShowAssignedtoWarning(false); // Clear the warning
      } else {
        setSelAssignedEmail([]); // Reset project lead if no selection
        setShowAssignedtoWarning(true); // Show warning when project lead is not selected
      }
    };

    return (
      <>
      <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h5>{modalTitle}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={onSubmit}>
                    <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Ticket Number 
                        {selIssue.is_approved === 'approved' && (
                              <span style={{ color: 'green', marginLeft: '5px' }}>(Approved)</span>
                         )}
                        {selIssue.is_approved === 'rejected' && (
                              <span style={{ color: 'red', marginLeft: '5px' }}>(Rejected)</span>
                         )}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            ref={ticketNumberField}
                            defaultValue={selIssue.ticket_number}
                            disabled={true}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom011">
                        <Form.Label> Date </Form.Label>
                        <Form.Control
                            type="date" 
                            ref={dateField} 
                            defaultValue={selIssue.date}
                            disabled={true} />

                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label>Name </Form.Label>
                        <Form.Control
                            type="text"
                            ref={nameField}
                            defaultValue={selIssue.name}
                            disabled={true}
                        />

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                          <Form.Label>Email id</Form.Label>
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    aria-describedby="inputGroupPrepend"
                                    ref={emailField}
                                    defaultValue={selIssue.email}
                                    disabled={true }
                                />

                            </InputGroup>
                      </Form.Group>
                      </Row>
                      <Row className="mb-3">
                      <Form.Group as={Col} md={selTicketCategory.display_key==='Hardware' ?"4":"6"} controlId="validationCustom02">
                            <Form.Label>Ticket Category </Form.Label>
                            <Typeahead
                                id="ticket-category"
                                labelKey="display_key"
                                options={[{ Type: 'Hardware', display_key: 'Hardware' },
                                { Type: 'Software', display_key: 'Software' },
                                { Type: 'Database', display_key: 'Database' }]}
                                onChange={setSelTicketCategory}
                                placeholder="Select Ticket Category"
                                selected={selTicketCategory}
                                ref={ticketCategoryField}
                                disabled={true }
                            />

</Form.Group>
{selTicketCategory.display_key==='Hardware' ? 
<Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Hardware Type </Form.Label>
                            <Typeahead
                                id="hardware-type"
                                labelKey="display_key"
                                options={[{ Type: 'Desktop', display_key: 'Desktop' },
                                { Type: 'Laptop', display_key: 'Laptop' },]}
                                onChange={setSelHardwareType}
                                placeholder="Select Hardware Type"
                                selected={selHardwareType}
                                ref={hardwareTypeField}
                                disabled={true}
                            /> 

</Form.Group> : null}
<Form.Group as={Col} md={selTicketCategory.display_key==='Hardware' ?"4":"6"} controlId="validationCustom02">
                            <Form.Label>Ticket Type </Form.Label>
                            <Typeahead
                                id="ticket-category"
                                labelKey="display_key"
                                options={[{ Type: 'Issue', display_key: 'Issue' },
                                { Type: 'Service request', display_key: 'Service request' }]}
                                onChange={setSelTicketType}
                                placeholder="Select Ticket Type"
                                selected={selTicketType}
                                ref={ticketTypeField}
                                disabled={true}
                            />

</Form.Group>
                      </Row>

                      <Form.Group className="mb-3" controlId="validationCustom03">
                            <Form.Label>Project Name</Form.Label>
                            <Typeahead
                                id="projectname"
                                labelKey="display_key"
                                options={[]}
                                onChange={setSelProjectName}
                                placeholder="No Project selected"
                                selected={selProjectName}
                                ref={projectNameField}
                                disabled={true}
                            />

</Form.Group>
                      <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Subject Line </Form.Label>
                        <Form.Control
                            type="text"
                            ref={subjectLineField}
                            defaultValue={selIssue.subject_line}
                            disabled={true}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="validationCustom02">
  <Form.Label>Issue Description</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    ref={issueDescriptionField}
    defaultValue={selIssue.issue_description}
    disabled={true}
  />
</Form.Group>

{selIssue.attachment!=="" && (
  <div>
    <h6>Attachment:</h6>
    <Table responsive>
      <tbody>
              <a href={selIssue.attachment} target="_blank" rel="noopener noreferrer">
                Download
              </a>
        
      </tbody>
    </Table>
  </div>
)}

<Row className="mb-3">
<Form.Group as={Col} md="3" controlId="validationCustom02">
  <Form.Label>Approval Needed?</Form.Label>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name="approval"
      value="yes"
      checked={approval === 'yes'}
      onChange={handleApprovalChange}
      ref={isApprovalNeededField}
      disabled={type === 'view' ? true : false}
    />
    <label className="form-check-label">Yes</label>
  </div>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name="approval"
      value="no"
      checked={approval === 'no'}
      onChange={handleApprovalChange}
      ref={isApprovalNeededField}
      disabled={type === 'view'? true : false}
    />
    <label className="form-check-label">No</label>
  </div>
</Form.Group>

{approval==="yes" ?
<Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Approval From: </Form.Label>
                            <Typeahead
                                id="assinged-email-typeahead"
                                labelKey="display_key"
                                options={emailOptionsApproval}
                                onChange={handleApprovalFromChange}
                                placeholder="Search email"
                                selected={selApprovalEmail}
                                ref={approvalemailField}
                                disabled={type === 'view' ? true : false}
                            />
                                                        { showApprovalFromWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select a email to get approval.
                          </div>
                        )}

</Form.Group> :null}
</Row>

<Row className="mb-3">
<Form.Group as={Col} md="3" controlId="validationCustom02">
  <Form.Label>Assign this ticket?</Form.Label>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name="assigned"
      value="yes"
      checked={assigned === 'yes'}
      onChange={handleAssignedChange}
      ref={isAssignThisTicketField}
      disabled={type === 'view'||role!=='admin' ||(initialSelStatus[0]?.Type!=='New'
                && initialSelStatus[0]?.Type!=='Re-Opened') ? true : false}    />
    <label className="form-check-label">Yes</label>
  </div>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name="assigned"
      value="no"
      checked={assigned === 'no'}
      onChange={handleAssignedChange}
      ref={isAssignThisTicketField}
      disabled={type === 'view'||role!=='admin' ||(initialSelStatus[0]?.Type!=='New'
                && initialSelStatus[0]?.Type!=='Re-Opened') ? true : false}    />
    <label className="form-check-label">No</label>
  </div>
</Form.Group>

{assigned==="yes" ?
<Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Ticket Assigned to: </Form.Label>
                            <Typeahead
                                id="assinged-email-typeahead"
                                labelKey="display_key"
                                options={emailOptionsAssigned}
                                onChange={handleAssignedtoChange}
                                placeholder="Search email"
                                selected={selAssignedEmail}
                                ref={assingedemailField}
                                disabled={type === 'view'||role!=='admin' ||(initialSelStatus[0]?.Type!=='New'
                                           && initialSelStatus[0]?.Type!=='Re-Opened') ? true : false}
                            />
                              { showAssignedtoWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select a email to get assigned.
                          </div>
                        )}

</Form.Group>:null}
</Row>
<Form.Group className="mb-3" controlId="validationCustom02">
  <Form.Label>Remarks *</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    ref={remarksField}
    defaultValue={selIssue.remarks}
    disabled={type === "view" ? true : false}
    onChange={handleRemarksChange}
  />
                              { showRemarksWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please enter a few lines of remarks.
                          </div>
                        )}
</Form.Group>
{selRemarksHistory.length > 0 && (
          <Form.Group>
            <Form.Label>Remarks History</Form.Label>
            <Button variant="outline-secondary" onClick={toggleRemarksHistory} style={{fontSize:'15px',marginLeft:'5px',padding:'0px 2px 0px 2px'}}>
              {viewModeRemarksHistory === 'open' ? <i className="fas fa-chevron-up"></i> :   <i className="fas fa-chevron-down"></i> }
            </Button>
            {viewModeRemarksHistory==="close" ? (
              <Container className="remarks-container">
                <Table className="remarks-table" responsive>
                  <tbody>
                    {selRemarksHistory.map((remark, index) => (
                      <tr key={index} className="remarks-row">
                        <td className="remarks-name">{remark.name} : </td>
                        <td className="remarks-message">{remark.remarks.split('\n').map((line, i) => <div key={i}>{line}<br/></div>)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
            </Container>
            ) : (
null
            )}
          </Form.Group>
        )}
        
                    <Form.Group className="mb-3"  controlId="validationCustom01">
                        <Form.Label>Status </Form.Label>

                        <Typeahead
  id="status-typeahead"
  labelKey="display_key"
  options={statusOptions}
      onChange={handleStatusChange}
      selected={selStatus}
      placeholder="Search status"
      ref={statusField}
      disabled={type === 'view' ? true : false}
    />
                            { showStatusWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select a status.
                          </div>
                        )}
                    </Form.Group>
                   
                    {type === 'view' ? <></> :
                    <Button variant="primary" type="submit" className='me-2 mb-2' id='button-addon1' disabled={processing} 
                       >{submitTitle}</Button>}
                    <Button variant="secondary" onClick={handleClose} className="me-2 mb-2" disabled={processing}>{cancelTitle}</Button>
                </Form>

            </Modal.Body>
        </Modal >
        </>
    );
}