import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Auth } from '@aws-amplify/auth';
import moment from 'moment';
import { AiOutlineClose } from 'react-icons/ai';

export default function EditRecord({ show, setShow, editData, fetchdata,setIsModalOpen,setIsSubmitting, 
                                     editDataSelProject,viewAlert }) {

  const handleClose = () => setShow(false);

  const getAccessToken = async () => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return accessToken;
  };

  const editRecord = async () => {
    let shouldCloseModal = true;

    setIsSubmitting(true);
    const timestamp = moment().format('DD/MM/YYYY, HH:mm:ss A');

    let newTaskDescription = editData.taskdescription.trim();

    // Append new "edited on" timestamp to task description
    newTaskDescription = `${newTaskDescription}\n\n(edited on ${timestamp})   `;

    try {
      const accessToken = await getAccessToken();
     console.log('going')
      const data = {
        name: editData.name,
        email: editData.email,
        projectname: editDataSelProject[0]?.value,
        date: editData.date,
        hours: editData.hours,
        minutes: editData.minutes,
        taskdescription: newTaskDescription, // modify taskdescription field
        sort_key: editData.sort_key,
      };

      const response = await fetch('https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/edit_tasksheet_record', {
        method: 'POST',
        headers: {
          Authorization: accessToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Success:', data);
        const alertMessage = {
          header: 'Edit Record',
          body: 'Edited successfully',
        };
        viewAlert(alertMessage);
        fetchdata();
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
   
    if (shouldCloseModal) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Modal className='alertmodal-tasksheet' show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h3>Edit Record</h3>
          </Modal.Title>
          <button className="close" onClick={handleClose}>
          <AiOutlineClose />
        </button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to edit this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="btn-sm">
            Cancel
          </Button>
          <Button variant="primary" onClick={editRecord} className="btn-sm" id="button-addon1">
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      {show && <div className="overlay-tasksheet"></div>}
    </>
  );
}