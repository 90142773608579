import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Outlet, useLocation } from 'react-router-dom';
import TasksheetApp from './Apps/TasksheetApp/TasksheetApp.js'
import './ProjectTrackerLandingPage.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import logo from './images/logo-grey.png';
import { Auth } from 'aws-amplify';
import HelpDeskApp from './Apps/HelpsDeskApp/HelpDeskApp.js'
import ResourceTab from './Apps/ResourceTab/resourceTab.js'

const LandingPage = () => {
  return (
    <>
    <div className='P_header'>
    <img src={logo} style={{width:"200px" ,height:"70px"}} alt="Logo" />
    <div className="P_signout-container">
          <button onClick={() => Auth.signOut()} id='P_signout'>Sign out</button>
    </div>
    </div>
    <div className='choose_application'>
      <h1 id='P_heading1'>Choose an Application</h1>
      <nav>
        <ul className='P_options'>
          <li>
            <Link to="/tasksheet" className='Plink Pbutton'>
              <span className="Picon">
                <i className="bi bi-file-earmark-text" ></i>
              </span>
              Tasksheet
            </Link>
          </li>

          <li>
            <Link to="/helpdesk" className='Plink Pbutton'>
              <span className="Picon">
                <i className="bi bi-question-circle-fill"  ></i>
              </span>
              HelpDesk
            </Link>
          </li>

          <li>
              <Link to="/resource" className='Plink Pbutton'>
                <span className="Picon">
                  <i className="bi bi-book-half"></i>
                </span>
                Employee Handbook
              </Link>
            </li>    
        </ul>
      </nav>
    </div>
  </>
  );
};



const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/tasksheet/*" element={<TasksheetApp />} />
        <Route path="/helpdesk/*" element={<HelpDeskApp />} />
        <Route path="/resource/*" element={<ResourceTab />} />
      </Routes>
    </Router>
  );
};

export default App;