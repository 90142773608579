import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import './AlertModal.css';

export default function AlertModal({ show, setShow, message, header }) {
  const handleClose = () => setShow(false);

    const modalStyle = {
    zIndex: 1057, // Set the desired z-index value
  };
  return (
    <>
    <Modal className="alertmodal-helpdesk" show={show} onHide={handleClose} keyboard={false} styles={modalStyle}>
      <Modal.Header closeButton={false}>
        <Modal.Title className="modal-title">
          <h3>{message.header}</h3>
        </Modal.Title>
        <button className="close" onClick={handleClose}>
          <AiOutlineClose />
        </button>
      </Modal.Header>
      <Modal.Body>{message.body}</Modal.Body>
    </Modal>
    {show && <div className="overlay-helpdesk"></div>}

    </>
  );
}












