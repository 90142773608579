import { Auth } from 'aws-amplify'
import logo from './images/logo-grey.png';
import "./LoginPage.css"

function LoginPage() {
  
  const handleSignIn = async () => {
    localStorage.setItem('loggedin', 'yes!'); // Store the data in local storage
    try {
      await Auth.federatedSignIn({ provider: 'Google' });
    } catch (error) {
      console.log(error);
      // or display the error to the user using a toast, modal, etc.
    }
  };

  const cachedsignindata = localStorage.getItem('loggedin');
  if (cachedsignindata){
    //console.log("log in only with dataterrain")
    alert("Sign-in with only your dataterrain email id")
    localStorage.removeItem('loggedin'); // Clear the cached data from local storage when the component unmounts
  }

  return (
    <div class="signin">
      <img src={logo} alt="Logo"  />
      <h2 id='welcome_note' >Welcome to DataTerrain Internal Application</h2>
      <button id="signin-button" onClick={handleSignIn}>
        Sign In with DataTerrain Email-ID
      </button>
    </div>
  )
}

export default LoginPage
