import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Auth } from '@aws-amplify/auth';

export default function VendorDelete({ show, setShow, selVendor, fetchVendors, viewAlert }) {

    const handleClose = () => setShow(false);
    const [processing, setProcessing] = useState(false);

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() };

    const deleteVendor = () => {
        setProcessing(true);
        (async () => {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/vendor?ID=' + selVendor.ID+'&user_email=' + Auth.user.attributes.name, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setProcessing(false);
            handleClose();
            fetchVendors();
            const alertMessage = {
                header: "Vendor deleted",
                body: "Vendor " + selVendor["vendor_name"] + " is successfully deleted"
            }
            viewAlert(alertMessage);
        })();

    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5>Delete vendor</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure want to delete vendor {selVendor.vendor_name}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="btn-sm" disabled={processing}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={deleteVendor} className="btn-sm" id='button-addon1' disabled={processing}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}