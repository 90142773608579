import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Auth } from '@aws-amplify/auth';

export default function ClientDelete({ show, setShow, selClient, fetchClients, viewAlert }) {

    const handleClose = () => setShow(false);
    const [processing, setProcessing] = useState(false);
    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    const deleteClient = () => {
        setProcessing(true);
        (async () => {
            let myHeaders = new Headers();
            let accessToken = await getAccessToken();
            myHeaders.append("Content-Type", "application/json");
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client?ID=' + selClient.ID +'&user_email=' + Auth.user.attributes.name, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setProcessing(false);
            handleClose();
            fetchClients();
            const alertMessage = {
                header: "Client deleted",
                body: "Client " + selClient["client_name"] + " is successfully deleted"
            }
            viewAlert(alertMessage);
        })();

    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5>Delete client</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure want to delete client {selClient.client_name}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="btn-sm" disabled={processing}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={deleteClient} className="btn-sm" id='button-addon1' disabled={processing}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}