import { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Form, Button, InputGroup } from "react-bootstrap";
import VendorDelete from "./VendorDelete";
import VendorForm from "./VendorForm";
import Card from 'react-bootstrap/Card';
import 'bootstrap-icons/font/bootstrap-icons.css'
import AlertModal from "../AlertModal";
import {Auth} from '@aws-amplify/auth';
import { saveAs } from 'file-saver';
import { exportCSV,exportXLSX, exportPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports


export default function VendorHome() {
    const [vendors, setVendors] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searching, setSearching] = useState(false);

    const [selVendor, setSelVendor] = useState({});

    //AlertModal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});

    //DeleteModal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //Vendor Form Modal
    const [showVendorModal, setShowVendorModal] = useState(false);
    const [vendorModalType, setVendorModalType] = useState("new");

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    function fetchVendors(searchStr) {
        (async () => {
            setSearching(true);
            setSearchQuery(searchStr ? searchStr : searchQuery);
            let myHeaders = new Headers();
            let accessToken = await getAccessToken();

            myHeaders.append("Content-Type", "application/json");
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/vendor?searchName=' + (searchStr ? searchStr.toLowerCase() : searchQuery.toLowerCase()),
                {
                    headers: new Headers({
                        'Authorization': accessToken
                    })
                }
            );
            const result = await response.json();
            result.sort((a, b) => a.vendor_name.localeCompare(b.vendor_name)); // Sort the fetched data by first_name
            setVendors(result);
            setSearching(false);
        })();
    }

    useEffect(() => {
      fetchVendors()
  }, []);

    function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
    }

    function newVendor() {
        setVendorModalType("new");
        setSelVendor({});
        setShowVendorModal(true);
    }

    function updateVendor(vendor) {
        setVendorModalType("update");
        setSelVendor(vendor);
        setShowVendorModal(true);
    }

    function viewVendor(vendor) {
        setVendorModalType("view");
        setSelVendor(vendor);
        setShowVendorModal(true);
    }


    function deleteVendor(vendor) {
        setSelVendor(vendor);
        setShowDeleteModal(true);
    }

    const [viewMode, setViewMode] = useState('table');

    const toggleViewMode = () => {
      setViewMode(prevMode => (prevMode === 'card' ? 'table' : 'card'));
    };

    const exportData = (e) => {
      // Export as CSV
      if(e==='csv'){
      const csvData = exportCSV(vendors);
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(csvBlob, 'vendors.csv');
      document.getElementById("export_select").value = "";
  }
  else if(e=='xlsx'){
    exportXLSX(vendors)
    .then((xlsxBuffer) => {
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'vendors.xlsx');
      document.getElementById("export_select").value = "";
    })
    .catch((error) => {
      console.error('Error exporting XLSX:', error);
    });
   }
     else{
      // Export as PDF
      const pdfData = exportPDF(vendors);
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      saveAs(pdfBlob, 'vendors.pdf');
      document.getElementById("export_select").value = "";
  }
    };

    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState("");

    const handleSort = (column) => {
      if (sortColumn === column) {
        // If the same column is clicked, toggle the sorting order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If a different column is clicked, set it as the new sort column
        setSortColumn(column);
        setSortOrder("asc");
      }
    };

    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        if (sortOrder === "asc") {
          return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
        } else {
          return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
        }
      } else {
        return null; // Don't display any icon if the column is not the current sort column
      }
    };

    // Add this code inside the component before rendering the table
let sortedVendors = [...vendors];
if (sortColumn && sortOrder) {
sortedVendors.sort((a, b) => {
  const columnA = a[sortColumn];
  const columnB = b[sortColumn];
  if (columnA < columnB) {
    return sortOrder === "asc" ? -1 : 1;
  }
  if (columnA > columnB) {
    return sortOrder === "asc" ? 1 : -1;
  }
  return 0;
});
}
    return (
        <>
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
            <VendorDelete show={showDeleteModal} setShow={setShowDeleteModal} selVendor={selVendor} fetchVendors={fetchVendors} viewAlert={viewAlert} />
            <VendorForm type={vendorModalType} show={showVendorModal} setShow={setShowVendorModal} selVendor={selVendor} fetchVendors={fetchVendors} viewAlert={viewAlert} />
            <Container fluid>
                <Row >
                    <Col xs lg="3">
                        <InputGroup className="mb-3">

                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                placeholder="Search for a vendor"
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            /><Button variant="outline-secondary" id="button-addon2" onClick={() => fetchVendors()}>
                                <i className="bi bi-search"></i>
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col xs lg="3">
                        <InputGroup className="mb-3">


                            <Button variant="primary" id="button-addon1" onClick={newVendor}>
                                <i className="bi bi-file-earmark-plus"></i> Create new
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
        <Button variant="outline-secondary" onClick={toggleViewMode}>
          {viewMode === 'card' ? 'Table View' : 'Card View'}
        </Button>

<select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 exportData('csv');
                            } else if (e.target.value === 'pdf') {
                                exportData('pdf');
                              }
                              else if (e.target.value === 'xlsx') {
                                exportData('xlsx');
                              }
                            }}
                            style={{padding:'5px 6px 7px 6px',
                            backgroundColor:'white',color:'#3e3e3e',border:'1px solid',
                            marginLeft:'5px'}}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        {/*<option value="pdf">PDF</option>*/}
                        <option value="xlsx">Excel</option>
                       </select>
      </Col>
                </Row>
                {searching ? (
                    <Row ><Spinner animation="border" /></Row>
                  ):(
                    <>
                        {vendors.length === 0 ?(
                            <Row ><p>No vendors found. Please redefine the search criteria</p>
                            </Row>
                        ):(
                            <>
                            {viewMode === 'card' ? (
                            <Row xs={1} md={5} className="g-4">
                                {vendors.map((vendor, index) => {
                                    const sequentialNumber = index + 1; // Calculate the sequential number
                                    return (
                                        <Col key={vendor.ID}>
                                            <Card>
                                                <Card.Header>{sequentialNumber}. {vendor.vendor_name}</Card.Header>
                                                <Card.Body style={{ fontSize: "13px" }}>
                                                    <Card.Text>Email: {vendor.vendor_email}</Card.Text>
                                                    <Card.Text>Phone: {vendor.vendor_phone}</Card.Text>
                                                    <Card.Text>Start Date: {vendor.start_date}</Card.Text>
                                                    <Row >
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => viewVendor(vendor)}><i className="bi bi-eye"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => updateVendor(vendor)}><i className="bi bi-pencil"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteVendor(vendor)}><i className="bi bi-trash"></i>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                              </Row>
                            ) : (
                                                <table className="table">
                <thead>
                  <tr>
                    <th onClick={() => handleSort("vendor_name")}>Vendor Name {renderSortIcon("vendor_name")}</th>
                    <th onClick={() => handleSort("vendor_email")}>Email {renderSortIcon("vendor_email")}</th>
                    <th onClick={() => handleSort("vendor_phone")}>Phone {renderSortIcon("vendor_phone")}</th>
                    <th onClick={() => handleSort("start_date")}>Start Date {renderSortIcon("start_date")}</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                
                                <tbody>
                                  {sortedVendors.map((vendor, index) => {
                                    const sequentialNumber = index + 1; // Calculate the sequential number
                                    return (
                                    <tr key={vendor.ID} >
                                      <td>{sequentialNumber}. {vendor.vendor_name}</td>
                                      <td>{vendor.vendor_email}</td>
                                      <td>{vendor.vendor_phone}</td>
                                      <td>{vendor.start_date}</td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary btn-sm"
                                          onClick={() => viewVendor(vendor)}
                                          style={{marginRight:'2px'}}
                                        >
                                          <i className="bi bi-eye"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          onClick={() => updateVendor(vendor)}
                                          style={{marginRight:'2px'}}
                                        >
                                          <i className="bi bi-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-outline-danger btn-sm"
                                          onClick={() => deleteVendor(vendor)}
                                          style={{marginRight:'2px'}}
                                        >
                                          <i className="bi bi-trash"></i>
                                          </button>
                                      </td>
                                    </tr>
                                  );
                                 })}
                                </tbody>
                              </table>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </>
                
                    )
                }