import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

export default function AlertModal({ show, setShow, message, header}) {

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5>{message.header}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message.body}
                </Modal.Body>
            </Modal>
        </>
    );
}