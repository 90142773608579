import { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import SOWDelete from "./SOWDelete";
import SOWForm from "./SOWForm";
import Card from 'react-bootstrap/Card';
import 'bootstrap-icons/font/bootstrap-icons.css'
import AlertModal from "../AlertModal";
import { Auth } from '@aws-amplify/auth';
import { saveAs } from 'file-saver';
import { exportCSV,exportXLSX, exportPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports

export default function SOWHome() {
    const [SOWs, setSOWs] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searching, setSearching] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();

    const [selSOW, setSelSOW] = useState({});

    //AlertModal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});

    //DeleteModal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //SOW Form Modal
    const [showSOWModal, setShowSOWModal] = useState(false);
    const [SOWModalType, setSOWModalType] = useState("new");

    const [clientLov, setClientLov] = useState({});
    const [projLov, setProjLov] = useState({});

    const [selClient, setSelClient] = useState([]);

    const [projectDetails, setProjectDetails] = useState([]);

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    function fetchSOWs(searchStr) {
        (async () => {
            setSearching(true);
            setSearchQuery(searchStr ? searchStr : searchQuery);
            let myHeaders = new Headers();
            let accessToken = await getAccessToken();
            myHeaders.append("Content-Type", "application/json");
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/sow?searchName=' + (searchStr ? searchStr.toLowerCase() : searchQuery.toLowerCase()),
                {
                    headers: new Headers({
                        'Authorization': accessToken
                    })
                }
            );
            const result = await response.json();
            result.sort((a, b) => a.sow_name.localeCompare(b.sow_name)); // Sort the fetched data by first_name
            setSOWs(result);
            //console.log('sow',result,clientLov)
            setSearching(false);
        })();
    }

    function fetchClientLov() {
        (async () => {
            setSearching(true);
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client/clientlov', {
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setClientLov(result);
            //console.log('clientlov',result)
            setSearching(false);
            const searchParams = new URLSearchParams(location.search);
            const sowId = searchParams.get('sowId');
            if (sowId) {
              // Fetch SOW based on the sowId
              fetchSOWs(sowId);
            } else {
              // Fetch all SOWs
              fetchSOWs();}
        })();
    }

    function fetchProjLov() {
      (async () => {
        setSearching(true);
          let accessToken = await getAccessToken();
          const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project/projlov', {
              headers: new Headers({
                  'Authorization': accessToken
              })
          });
          const result = await response.json();
          setProjLov(result);
          setSearching(false);
      })();
  }

  useEffect(() => {
    fetchClientLov();
    fetchProjLov();
  }, []);
  
  useEffect(() => {
    //console.log("clientLov updated:", clientLov);
    // Perform any necessary actions with the updated clientLov state
  }, [clientLov]);
  

    function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
    }

    function newSOW() {
        setSOWModalType("new");
        setSelSOW({});
        setProjectDetails([])
        setSelClient([]);
        //console.log(projLov)
        setShowSOWModal(true);
    }

    function updateSOW(SOW) {
        setSOWModalType("update");
        setSelSOW(SOW);
        setProjectDetails(SOW.project_details ? SOW.project_details:[] )
        setSelClient(SOW.client_name ? [{ ID: SOW.client_name, display_key: clientLov[SOW.client_name].display_key }] : []);
        setShowSOWModal(true);
    }

    function viewSOW(SOW) {
        setSOWModalType("view");
        setSelSOW(SOW);
        setProjectDetails(SOW.project_details ? SOW.project_details:[] )
        setSelClient(SOW.client_name ? [{ ID: SOW.client_name, display_key: clientLov[SOW.client_name].display_key }] : []);
        setShowSOWModal(true);
    }


    function deleteSOW(SOW) {
        setSelSOW(SOW);
        setShowDeleteModal(true);
    }

    const [viewMode, setViewMode] = useState('table');

    const toggleViewMode = () => {
      setViewMode(prevMode => (prevMode === 'card' ? 'table' : 'card'));
    };

    const [viewModeSOW, setViewModeSOW] = useState('current');

    const toggleViewModeSOW = () => {
      setViewModeSOW(viewModeSOW === 'all' ? 'current' : 'all');
    };

    const exportData = (e) => {
      // Export as CSV
      if(e==='csv'){
      const csvData = exportCSV(SOWs,clientLov,projLov);
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(csvBlob, 'SOWs.csv');
      document.getElementById("export_select").value = "";
  }
  else if(e=='xlsx'){
    exportXLSX(SOWs,clientLov,projLov)
    .then((xlsxBuffer) => {
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'SOWs.xlsx');
      document.getElementById("export_select").value = "";
    })
    .catch((error) => {
      console.error('Error exporting XLSX:', error);
    });
   }
     else{
      // Export as PDF
      const pdfData = exportPDF(SOWs);
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      saveAs(pdfBlob, 'SOWs.pdf');
      document.getElementById("export_select").value = "";
  }
    };

    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState("");

    const handleSort = (column) => {
      if (sortColumn === column) {
        // If the same column is clicked, toggle the sorting order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If a different column is clicked, set it as the new sort column
        setSortColumn(column);
        setSortOrder("asc");
      }
    };

    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        if (sortOrder === "asc") {
          return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
        } else {
          return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
        }
      } else {
        return null; // Don't display any icon if the column is not the current sort column
      }
    };

// Filter SOWs based on view mode and end date
const filteredSOWs = SOWs.filter((SOW) => {
  if (viewModeSOW === 'current') {
    return true; // Show all SOWs
  } else {
    const endDate = SOW.sow_end_date;
    const currentDate = new Date();
    return (endDate==='' || new Date(endDate) >= currentDate); // Show only SOWs with end date >= current date
  }
});

    // Add this code inside the component before rendering the table
let sortedSOWs = [...filteredSOWs];
if (sortColumn && sortOrder) {
sortedSOWs.sort((a, b) => {
  const columnA = a[sortColumn];
  const columnB = b[sortColumn];
  if (columnA < columnB) {
    return sortOrder === "asc" ? -1 : 1;
  }
  if (columnA > columnB) {
    return sortOrder === "asc" ? 1 : -1;
  }
  return 0;
});
}

//console.log(clientLov)
    return (
        <>
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
            <SOWDelete show={showDeleteModal} setShow={setShowDeleteModal} selSOW={selSOW} fetchSOWs={fetchSOWs} viewAlert={viewAlert} />
            <SOWForm type={SOWModalType} show={showSOWModal} setShow={setShowSOWModal} selSOW={selSOW} fetchSOWs={fetchSOWs} viewAlert={viewAlert}
            clientLov={clientLov} setSelClient={setSelClient} selClient={selClient} setProjectDetails={setProjectDetails} projectDetails={projectDetails}
            projLov={projLov}  />
            <Container fluid>
                <Row >
                    <Col xs lg="3">
                        <InputGroup className="mb-3">

                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                placeholder="Search for a SOW"
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            /><Button variant="outline-secondary" id="button-addon2" onClick={() => fetchSOWs()}>
                                <i className="bi bi-search"></i>
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col xs lg="3">
                        <InputGroup className="mb-3">


                            <Button variant="primary" id="button-addon1" onClick={newSOW}>
                                <i className="bi bi-file-earmark-plus"></i> Create new
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>

                    <Button variant="outline-secondary" onClick={toggleViewModeSOW} style={{marginRight:'5px'}}>
          {viewModeSOW === 'all' ? "All SOW's" : "Current SOW's"}
        </Button>
        <Button variant="outline-secondary" onClick={toggleViewMode}>
          {viewMode === 'card' ? 'Table View' : 'Card View'}
        </Button>

<select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 exportData('csv');
                            } else if (e.target.value === 'pdf') {
                                exportData('pdf');
                              }
                              else if (e.target.value === 'xlsx') {
                                exportData('xlsx');
                              }
                            }}
                            style={{padding:'5px 6px 7px 6px',
                            backgroundColor:'white',color:'#3e3e3e',border:'1px solid',
                            marginLeft:'5px'}}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        {/*<option value="pdf">PDF</option>*/}
                        <option value="xlsx">Excel</option>
                       </select>
      </Col>
                </Row>
                {searching ? (
                    <Row ><Spinner animation="border" /></Row>
                 ) : (
                    <>
                        {SOWs.length === 0 ? (
                            <Row ><p>No SOWs found. Please redefine the search criteria</p>
                            </Row>
                        ):(
                            <>
                            {viewMode === 'card' ? (
                            <Row xs={1} md={5} className="g-4">
                                {filteredSOWs.map((SOW, index) => {
                                    const sequentialNumber = index + 1; // Calculate the sequential number
                                    return (
                                        <Col key={SOW.ID}>
                                           <Card className={SOW.sow_end_date && new Date(SOW.sow_end_date) < new Date() ? 'non-current-member' : ''}>
                                                <Card.Header>{sequentialNumber}. {SOW.sow_name}</Card.Header>
                                                <Card.Body style={{ fontSize: "13px" }}>
                                                    <Card.Text>SOW Number: {SOW.sow_number}</Card.Text>
                                                    <Card.Text>SOW Start Date: {SOW.sow_start_date}</Card.Text>
                                                    <Card.Text>Client Name: {SOW.client_name ?clientLov[SOW.client_name].client_name:[]}</Card.Text>
                                                    <Row >
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => viewSOW(SOW)}><i className="bi bi-eye"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => updateSOW(SOW)}><i className="bi bi-pencil"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteSOW(SOW)}><i className="bi bi-trash"></i>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                  )
                })}
              </Row>
            ) : (
                                <table className="table">
<thead>
  <tr>
    <th onClick={() => handleSort("sow_name")}>Name {renderSortIcon("sow_name")}</th>
    <th onClick={() => handleSort("ID")}>Number {renderSortIcon("ID")}</th>
    <th onClick={() => handleSort("sow_start_date")}>Start Date {renderSortIcon("sow_start_date")}</th>
    <th onClick={() => handleSort("client_name")}>Client Name {renderSortIcon("client_name")}</th>
    <th>Actions</th>
  </tr>
</thead>

                <tbody>
                  {sortedSOWs.map((SOW , index) => {
                      const sequentialNumber = index + 1; // Calculate the sequential number
                    return (
                    <tr key={SOW.ID} className={SOW.sow_end_date && new Date(SOW.sow_end_date) < new Date() ? 'non-current-member' : ''}>
                      <td>{sequentialNumber}. {SOW.sow_name}</td>
                      <td>{SOW.sow_number}</td>
                      <td>{SOW.sow_start_date}</td>
                      <td>{clientLov[SOW.client_name].client_name}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => viewSOW(SOW)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => updateSOW(SOW)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => deleteSOW(SOW)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-trash"></i>
                          </button>
                      </td>
                    </tr>
                  );
                 })}
                </tbody>
              </table>
            )}
          </>
        )}
      </>
    )}
  </Container>
</>

    )
}