import './AccessPage.css'
import { useState ,useEffect} from 'react';
import { Auth } from 'aws-amplify'
import Select from 'react-select';
import AlertModal from './AlertModal';

function AccessPage() {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  function resetFormState() {
    updateFormState('email', '');
    updateFormState('role', '');

  }

  const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

  //The below code is to trigger the submit button through Rest api call ->that will trigger a lambda function->to store in Project_Details db
async function submit_data(){

 //console.log(formState.email, jsonDataaccess[0].email.length > 0 , jsonDataaccess[0].email.includes(formState.email))

  if (formState.email==='' || formState.role==='')
{ 
    const alertMessage = {
      header: 'Error',
      body: "Input field cannot be empty",
    };
    viewAlert(alertMessage);
  }

  else{
    setIsSubmitting(true);

  const accessToken = await getAccessToken();
  const data = {
    "email": formState.email,
    "role": formState.role,
  }

  fetch('https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/tasksheet_access_role_submit', 
  {
  method: 'POST',
  headers: {'Authorization':accessToken,
  'Content-Type': 'application/json',},
  body: JSON.stringify(data),
  })
  .then((response) => response.json())

  //Then with the data from the response in JSON...
  .then((data) => {
  console.log('Success:', data);

  const alertMessage = {
    header: 'Role Submit',
    body: "Successfully Submitted",
  };
  viewAlert(alertMessage);

  setIsSubmitting(false);
  //reseting the form field to empty after submit
  document.getElementById("access_form_main").reset();
  handleMenuOpenEmail();
  handleMenuOpenRole();
  })

  //Then with the error genereted...
  .catch((error) => {
  console.error('Error:', error);
  });
  

  //calling reset function to reset all my state to null once form is submitted 
  resetFormState();
}

}
  
function viewAlert(alertMessage) {
  setAlertMessage(alertMessage);
  setShowAlertModal(true);
}
  const [formState, setFormState] = useState({
    email:'',
    role: '',
  });
  //This is to update formstate of each input field
  const updateFormState = (key, value) => {
    //console.log(key,value)
    setFormState((prevFormState) => ({
      ...prevFormState,
      [key]: value,
    }));
  };


  const [selectedemail, setSelectedemail] = useState();
  const [emails, setEmails] = useState([]);
  const [selectedrole, setSelectedrole] = useState();
  useEffect(() => {
    fetchEmails();
  }, []);

  async function fetchEmails() {
    try {
	  let accessToken = await getAccessToken();
      const response = await fetch('https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/get_emails' ,{
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
      const data = await response.json();
      setEmails(data); // Set the fetched data directly
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  }

  const handleChangeEmail = (selectedemail) => {
    setSelectedemail(selectedemail);
    updateFormState('email' , selectedemail.value)
  };

  const handleMenuOpenEmail = () => {
    setSelectedemail(null);
    updateFormState('email' , '')
  };

  const handleChangeRole = (selectedrole) => {
    setSelectedrole(selectedrole);
    updateFormState('role' , selectedrole.value)
  };

  const handleMenuOpenRole = () => {
    setSelectedrole(null);
    updateFormState('role' , '')
  };

  return (
  <>
   <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />

  <div class="body_background">
  {isSubmitting  ? (
    <div id="loader-container">
      <div className="loader"></div>
    </div>
  ) : (null
  )}
   <div className="access_form">
  <h2 style={{textAlign:'center' , color:'#2b2b2b'}}>Grant Access Role</h2>
  <br></br>
    <form id='access_form_main'> 
      <label className="access_label" htmlFor="email">Email:</label>

      <Select className ="access_input" id="access_email" placeholder="name@dataterrian.com"
         value ={selectedemail}
         options={[
          ...emails.map((email) => ({ value: email.email, label: email.email }))
         ]}
         onChange={handleChangeEmail}
         required list = "autocomplete-options"/>

      
      <br></br>

      <label className="access_label" htmlFor="role">Role:</label>
      <Select className ="access_input" id='access_role' placeholder="Choose a role"
        value ={selectedrole}
        options={[
          { value: 'user', label: 'User' },
          { value: 'admin', label: 'Admin' }
         ]} 
        onChange={handleChangeRole}
      />
      

      <br></br>
     <center>
      <button type="button" onClick={submit_data}>Submit</button>
      </center>
    </form>
    </div>
    </div>
  </>
  )
}

export default AccessPage