
import { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {Auth} from '@aws-amplify/auth';
import moment from 'moment';


export default function VendorForm({ type, show, setShow, fetchVendors, selVendor, viewAlert }) {

    const nameField = useRef();
    const emailField = useRef();
    const phoneField = useRef();
    const addressStreetField = useRef();
    const cityField = useRef();
    const stateField = useRef();
    const zipField = useRef();
    const startDateField = useRef();
    const approvedByField = useRef();
    const approvedDateField = useRef();
    const vendorRemarksField = useRef();

    const [processing, setProcessing] = useState(false);
    const [showVendorNameWarning, setShowVendorNameWarning] = useState(false);

    const modalTitle = (type === 'update' ? 'Update' : 'Create') + ' vendor';

    const submitTitle = type === 'update' ? 'Update' : 'Create';
    const cancelTitle = type === 'view' ? 'Close' : 'Cancel';

    const navigate = useNavigate();

    const handleClose = () =>{
         setShow(false);
         setShowVendorNameWarning(false);

    }
  
    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

    const onSubmit = (ev) => {
        ev.preventDefault();
       
        if(nameField.current.value===''){
            setShowVendorNameWarning(true);
        }
        else{
        const id = type === 'new' ? nameField.current.value +  Auth.user.attributes.email +  moment().format('YYYY/MM/DD, HH:mm:ss A')
        : selVendor.ID ;
        
        setProcessing(true);

        const newVendor = {
            "ID" : id,
            "vendor_name": nameField.current.value,
            "vendor_email": emailField.current.value,
            "vendor_phone": phoneField.current.value,
            "street_address": addressStreetField.current.value,
            "city_name": cityField.current.value,
            "state_name": stateField.current.value,
            "zipcode": zipField.current.value,
            "start_date": startDateField.current.value,
            "approved_by": approvedByField.current.value,
            "approved_date": approvedDateField.current.value,
            "user_email":Auth.user.attributes.name,
            "vendor_remarks": vendorRemarksField.current.value
        };

        newVendor["search_name"] = (newVendor["vendor_name"]).toLowerCase();

        (async () => {
            let accessToken = await getAccessToken();
            const rawResponse = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/vendor', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':accessToken
                },
                body: JSON.stringify(newVendor)
            });
            const content = await rawResponse.json();
            if (type === 'update') {
                fetchVendors();
            }
            setProcessing(false);
            handleClose();
            const alertMessage = {
                header: "Vendor " + (type === 'update' ? 'updated' : 'created'),
                body: "Vendor " + newVendor["vendor_name"] + " is successfully " + (type === 'update' ? 'updated' : 'created')
            }
            viewAlert(alertMessage);
        })();
      }
    }

    const handleVendorNameChange = (event) => {
        const name = event.target.value;

        if (name.length > 0) {
         setShowVendorNameWarning(false); // Clear the warning
      } else {
         setShowVendorNameWarning(true); // Show warning when project name is not filled
         }
       };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h5>{modalTitle}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Vendor Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Vendor Name"
                            ref={nameField}
                            defaultValue={selVendor.vendor_name}
                            disabled={type == 'new' ? false : true}
                            onChange={handleVendorNameChange}
                        />
                          {showVendorNameWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a vendor name.
                            </div>
                           )}
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                            <Form.Label>Contact Email</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Email Address"
                                    aria-describedby="inputGroupPrepend"
                                    ref={emailField}
                                    defaultValue={selVendor.vendor_email}
                                    disabled={type === 'view' ? true : false}
                                />

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Phone number"
                                ref={phoneField}
                                defaultValue={selVendor.vendor_phone}
                                disabled={type === 'view' ? true : false}
                            />

                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address Street</Form.Label>
                        <Form.Control type="text" placeholder="Enter Address" ref={addressStreetField} defaultValue={selVendor.street_address} disabled={type === 'view' ? true : false} />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" ref={cityField} defaultValue={selVendor.city_name} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" placeholder="State" ref={stateField} defaultValue={selVendor.state_name} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text" placeholder="Zipcode" ref={zipField} defaultValue={selVendor.zipcode} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" ref={startDateField} defaultValue={selVendor.start_date} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>Approved By</Form.Label>
                            <Form.Control type="text" ref={approvedByField} defaultValue={selVendor.approved_by} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                            <Form.Label>Approved Date</Form.Label>
                            <Form.Control type="date" ref={approvedDateField} defaultValue={selVendor.approved_date} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="validationCustom02">
                      <Form.Label>Vendor Remarks</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Vendor Remarks"
                          ref={vendorRemarksField}
                          defaultValue={selVendor.vendor_remarks}
                          disabled={type === 'view' ? true : false}
                        />
                    </Form.Group>
                    {type === 'view' ? <></> : <Button variant="primary" type="submit" className='me-2 mb-2' id = 'button-addon1' disabled={processing}>{submitTitle}</Button>}
                    <Button variant="secondary" onClick={handleClose} className="me-2 mb-2" disabled={processing}>{cancelTitle}</Button>
                </Form>
            </Modal.Body>
        </Modal >
    );
}
