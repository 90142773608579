import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarA from './Components/NavbarA';
import NavbarB from './Components/NavbarB';
import InnerApp from './Pages/InnerApp';
import AccessPage from './Pages/AccessPage';
import ReportPageA from './Pages/ReportPage_A';
import ReportPageB from './Pages/ReportPage_B';
import './TasksheetApp.css';
import SignOutTimer from './SignOutTimer';
import 'normalize.css';



function App() {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const isUserAuthorized = userData && userData.length > 0 && userData[1] && userData[1][0].role.toLowerCase() === 'admin';

  return (
    <body>
        {isUserAuthorized ? (
          <div>
            <NavbarA />
            <Routes>
              <Route path="/" element={<InnerApp />} />
              <Route path="/access" element={<AccessPage />} />
              <Route path="/reports" element={<ReportPageA />} />
            </Routes>
          </div>
        ) : (
          <div>
            <NavbarB />
            <Routes>
              <Route path="/" element={<InnerApp />} />
              <Route path="/access" element={null} />
              <Route path="/reports" element={<ReportPageB />} />
            </Routes>
          </div>
        )}
    </body>
  );
}

export default App;