import React ,{useState} from 'react';
import {FaBars , FaTimes} from 'react-icons/fa';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from './images/logo-grey.png';
import EmpForm from "./EmpForm";


function Navbar(){

  const [showEmpModal, setShowEmpModal] = useState(false);
  const [empModalType, setEmpModalType] = useState("new");
  const [selEmp, setSelEmp] = useState({});

  //AlertModal
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const [icon,seticon] = useState(false);
  const location = useLocation();

  const [userFullDetails, setUserFullDetails] = useState(false);

  const handleclick = () =>{
    seticon(!icon)
  }

  const closeSideDrawer = () => {
    seticon(false)
  }
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  }

  function viewAlert(alertMessage) {
    setAlertMessage(alertMessage);
    setShowAlertModal(true);
  }
  
  function handleViewEmp() {
    if (localStorage.getItem('userData')){
      const userDetails = JSON.parse(localStorage.getItem('userData'))[2]
      if(userDetails.length>0){
      setUserFullDetails(userDetails[0])
      closeSideDrawer();
      setEmpModalType('view');
      setSelEmp(userFullDetails);
      setShowEmpModal(true);
      console.log("check",userDetails[0].ID.S)
      //console.log("userFullDetails",userDetails[0]['first_name'])
      }
    }
  }

//let name = Auth.user.attributes.email.split("@")[0];
//let upper_name= name.charAt(0).toUpperCase() + name.slice(1);
return (
  <>
    <EmpForm type={empModalType} show={showEmpModal} setShow={setShowEmpModal} 
  selEmp={userFullDetails} viewAlert={viewAlert}/>
  <nav id="header" className="navbar navbar-expand-lg navbar-light bg-light">
   <div className="navbar-toggler-container">
   <Link to="/" className="navbar-brand">
    <img src={logo} style={{width:"200px" ,height:"70px"}}  alt="Logo" />
   </Link>
   <button
          className="navbar-toggler menu-icon"
          type="button"
         onClick={handleclick}
      >
          {icon ? (
            <FaTimes className="navbar-icon" />
          ) : (
            <FaBars className="navbar-icon" />
          )}
        </button>
      </div>
      <div
        className={`collapse navbar-collapse ${icon ? 'show' : ''}`}
        style={{ justifyContent: 'flex-end' }}
        id="navbarNav"
      >
        <ul className="navbar-nav ml-auto align-items-center">

      <li className="nav-item">
        <Link 
           to='/tasksheet' 
           className={`nav-link link ${isActive('/tasksheet')}`} 
           onClick={closeSideDrawer}>
           Home
        </Link>
      </li>

      <li className="nav-item">
        <Link 
           to='/tasksheet/reports' 
           className={`nav-link link  ${isActive('/tasksheet/reports')}`}
           onClick={closeSideDrawer}>
           Reports
        </Link>
      </li>

      <li className="nav-item">
        <Link 
        /* to='/tasksheet' */
           className="nav-link link" 
           onClick={handleViewEmp}>
          {Auth.user.attributes.name} 
        </Link>
      </li>

       <li className="nav-item">
        <Link
          className="nav-link link"
          onClick={() => Auth.signOut()} >
          Sign out
        </Link>
        </li>
        </ul>
      </div>
    </nav>
    </>
)
}

export default Navbar