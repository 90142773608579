import { useState } from 'react';
import { Auth } from 'aws-amplify'
import './InnerApp.css'
import moment from 'moment';
import AlertModal from "./AlertModal";
import Select from 'react-select';


//function to format and get date in yyyy/mm/dd format
  function format(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${year}-${month}-${date}`;
  }

    //fomatdate function for formstate.date
   /*  function formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    }*/

    const formatDate = (date) => {
      const formattedDate = moment(date).format('DD/MM/YYYY');
      return formattedDate;
    }
    

//content inside the innerapp

function InnerApp() {


  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const [selectedProject, setSelectedProject] = useState(null);

  //const [isSubmited, setIsSubmitted] = useState(false);
  //function that will reset all state to null when submit is triggered
  function resetFormState() {
    updateFormState('projectname', '');
    updateFormState('date', '');
    updateFormState('hours', '');
    updateFormState('minutes', '');
    updateFormState('taskdescription', '');
  }

  function viewAlert(alertMessage) {
    setAlertMessage(alertMessage);
    setShowAlertModal(true);
}
  const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}
  //The below code is to trigger the submit button through Rest api call ->that will trigger a lambda function->to store in Project_Details db
async function submit_data(){

  const cur_date = format(new Date());
  
  //const regex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;// regular expression to match special characters
  //((formState.taskdescription.match(regex) || []).length > 20)
  const regex = /\w+/;
  let minutes
  if (formState.minutes===''){
    minutes = '00'
  }
  else{
    minutes = formState.minutes
  }

  if (formState.taskdescription==='' || formState.date==='' || (formState.hours==='00' && minutes==='00') || formState.hours===''
   ||formState.date > cur_date || formState.projectname===''||  (!regex.test(formState.taskdescription)) )
    {

    if (formState.date > cur_date){
      const alertMessage = {
        header: 'Error',
        body: "You can't fill form for future date's",
      };
      viewAlert(alertMessage);
    }
    else if (formState.hours==='00' && minutes==='00'){
      const alertMessage = {
        header: 'Error',
        body: "hours and minutes cannot be 00 and 00",
      };
      viewAlert(alertMessage);
    }
    else if (formState.taskdescription===""){
      const alertMessage = {
        header: 'Error',
        body: "Input field cannot be empty",
      };
      viewAlert(alertMessage);
    }
    else if ((!regex.test(formState.taskdescription))){
      const alertMessage = {
        header: 'Error',
        body: "Task Description field must contain words",
      };
      viewAlert(alertMessage);
    }
    else{
    const alertMessage = {
      header: 'Error',
      body: "Input field cannot be empty",
    };
    viewAlert(alertMessage);
    }
  }

  else{
    setIsSubmitting(true);
    const formatted_date = formatDate(formState.date)

    const accessToken = await getAccessToken();

    const timestamp = moment().format('DD/MM/YYYY, HH:mm:ss A') ;

    let newTaskDescription = formState.taskdescription.trim();

      // Append new "edited on" timestamp to task description
    newTaskDescription = `${newTaskDescription}\n\n(submitted on ${timestamp})`;
    
    //setIsSubmitted(false);
    //console.log(minutes)
    //console.log(cur_date,formState.date)
    //updateFormState('date', formatDate(formState.date));
    //console.log(cur_date,formState.date)

  const data = {
    "name": Auth.user.attributes.name,
    "email": Auth.user.attributes.email,
    "projectname": formState.projectname,
	  "date": formState.date,
    'hours':formState.hours,
    'minutes':minutes,
	  "taskdescription": newTaskDescription,
    "sort_key": moment().format('YYYY/MM/DD, HH:mm:ss A') 
  }

  fetch('https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/new_taskheet_record', 
  {
  method: 'POST',
  headers: {'Authorization':accessToken,
  'Content-Type': 'application/json',},
  body: JSON.stringify(data),
  })
  .then((response) => response.json())
  //Then with the data from the response in JSON...
  .then((data) => {
  console.log('Success:', data);
  //console.log('0',isSubmited,isSubmitting)
  //setIsSubmitting(false);
  //setIsSubmitted(true);
  //console.log('1',isSubmited,isSubmitting)
  //alert("Successfully Submitted");
  setIsSubmitting(false);
  const alertMessage = {
    header:"Record Submitted",
    body:"Your record for the day "+formatted_date+" is successfully submitted"
}
viewAlert(alertMessage);
  
  //reseting the form field to empty after submit
  document.getElementById("Project survey").reset();
  setSelectedProject(null)
  document.getElementById('date').value = '';
  })

  //Then with the error genereted...
  .catch((error) => {
  console.error('Error:', error);
  });
  

  //calling reset function to reset all my state to null once form is submitted 
  resetFormState();
}

}


//the below code is to edit the dynamodb data by calling the api that will trigger the lambda function
//this code will only edit the task description field
/* async function edit_data(){
  const cur_date = format(new Date());
 if (formState.taskdescription==='' || formState.date===''
   ||formState.date > cur_date || formState.projectname==='' || formState.hours==='')  {
    if (formState.date > cur_date){
      alert("You can't fill form for future date's")
    }else{
    alert("Input field cannot be empty")
    }
 }
  else{
  const data = {
    "name": Auth.user.attributes.name,
   "email": Auth.user.attributes.email,
    "projectname": formState.projectname,
	  "date": formState.date,
    "hours" :formState.hours,
	  "taskdescription": formState.taskdescription,
  }
 fetch('https://44t8ro5g80.execute-api.us-east-1.amazonaws.com/v1', 
 {
  method: 'POST',
  headers: {'Content-Type': 'application/json',},
  body: JSON.stringify(data),
  })
  .then((response) => response.json())
  //Then with the data from the response in JSON...
  .then((data) => {
  console.log('Success:', data);
  alert(data.message);
  //reseting the form field to empty after edit
  document.getElementById("Project survey").reset();
  document.getElementById('date').value = '';
  })
  //.then(alert(data))
  //Then with the error genereted...
  .catch((error) => {
  console.error('Error:', error);
  });
  
  //calling reset function to reset all my state to null once form is submitted 
  resetFormState();
  }
} */

let sortedProjectNameValues = []
if (localStorage.getItem('userData')){
  const projectname = JSON.parse(localStorage.getItem('userData'))[0]
  //console.log("sortedCloumn1",projectname)
  const projectNameValues = projectname.map(item => item.project_name.S)
  sortedProjectNameValues = projectNameValues.sort((a,b) => a.localeCompare(b));
  //console.log("sortedCloumn",sortedProjectNameValues)
}

//extracting name from email and capitalizing the first letter of the name
//let name = Auth.user.attributes.email.split("@")[0];
//let upper_name= name.charAt(0).toUpperCase() + name.slice(1);

//<h1 className='welcome' style={{"text-align":"center"}}>Please fill in your Efforts for the correct project </h1>
//<button id="submit" type="button" onClick={edit_data}>Edit</button>
// Add this function outside of the login-form div
//creating a state for each input field
const [formState, setFormState] = useState({
  projectname:'',
  date: '',
  hours: '',
  minutes:'',
  taskdescription:''
});
//This is to update formstate of each input field
const updateFormState = (key, value) => {
  setFormState((prevFormState) => ({
    ...prevFormState,
    [key]: value,
  }));
};

// Define custom styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '45px',
    fontWeight: state.selectProps.placeholderIsFocused ? 'bold' : 'normal',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.8)', // Update the color here
    opacity: 1, // Update the opacity here

  }),
};

//console.log('name' , Auth.user.attributes)

  return (
    <>
     
     <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
      <div class="login-form">
  {isSubmitting  ? (
    <div id="loader-container">
      <div className="loader"></div>
    </div>
  ) : (null
  )}
         <div id ='form1' className='form'>
           <form id="Project survey" >

           <div class='form-row'>
           <Select
               id="dropdown_1"
               className="input_label1"
               options={localStorage.getItem('userData') ? sortedProjectNameValues.map((project, index) => ({
                   value: project,
                   label: project
                        })) : []}
                   value={selectedProject}
                   placeholder="Project Name *"
                   onChange={(selectedOption) => {
                   setSelectedProject(selectedOption);
                   updateFormState('projectname', selectedOption?.value || '');
                   }}
                   styles={customStyles}
            />

        

	           <input id="date" type="date" className='input_label2' placeholder="Enter date" style ={{textAlign:'center'}}
               required onChange={e => updateFormState('date', e.target.value)} 
               />


       
             <select id="hours" className='input_label1' onChange={e => updateFormState('hours', e.target.value)}
             >
              <option value="">Hours *</option>
              <option value='00' >00</option>
              <option value="01" >01</option>
              <option value="02" >02</option>
              <option value="03" >03</option>
              <option value="04" >04</option>
              <option value="05" >05</option>
              <option value="06" >06</option>
              <option value="07" >07</option>
              <option value="08" >08</option>
              <option value="09" >09</option>
              <option value="10" >10</option>
              <option value="11" >11</option>
              <option value="12" >12</option>
              <option value="13" >13</option>
              <option value="14" >14</option>
              <option value="15" >15</option>
              <option value="16" >16</option>
            
              </select>

  

               <select id="minutes" className='input_label1' onChange={e => updateFormState('minutes', e.target.value)}
             >
              <option value="00" >Minutes </option>
              <option value='00' >00</option>
              <option value="10" >10</option>
              <option value="20" >20</option>
              <option value="30" >30</option>
              <option value="40" >40</option>
              <option value="50" >50</option>
            
            </select> 
              </div>
             <br></br><br></br>
 
              
         
             <textarea
                id="taskdescription"
                maxLength="1000"
                placeholder="Task Description*"
                className="input"
                onChange={(e) => updateFormState("taskdescription", e.target.value)}
              
            />

             <br></br>
            <center>
               <button id="submit" type="button" onClick={submit_data}>Submit</button>
            </center>

           </form>
         </div>
         </div>
     
     </>
  )
}

export default InnerApp