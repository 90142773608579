import { Auth } from 'aws-amplify';

const getAccessToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

export const checkUserRole = async () => {
  const params = new URLSearchParams({
    email: Auth.user.attributes.email,
  });
  try {
    const accessToken = await getAccessToken();

    // Make the API call to get the user's role and compare it with the previous role stored in localStorage
    // Adjust the API endpoint and parameters based on your specific implementation
    const response = await fetch(`https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/get_tasksheet_access_role_data?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': accessToken,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const newData = await response.json();
    const oldData = JSON.parse(localStorage.getItem('userData'));
    //console.log('iam in')
    //console.log(newData , oldData)
    if (newData && newData.length > 0 && newData[1] && newData[1][0].role &&
        oldData && oldData.length > 0 && oldData[1] && oldData[1][0].role) {
      if (newData[1][0].role !== oldData[1][0].role) {
        console.log(newData[1][0].role ,  oldData[1][0].role)
        await Auth.signOut();
        // Perform any additional clean-up tasks
      }
    }
  } catch (error) {
    console.log('Error checking user role:', error.message);
  }
};

// Schedule the checkUserRole function to run every 10 minutes
//const intervalId = setInterval(checkUserRole, 10 * 60 * 1000);

// To stop the recurring task, call clearInterval(intervalId) when needed