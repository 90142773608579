import React, { useState , useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Select from 'react-select';
import "./StatusPage.css";
import AlertModal from "./AlertModal";
import { RiEdit2Line, RiEyeLine } from 'react-icons/ri';
import IssueFormAdmin from './IssueFormUpdateAdmin'
import IssueFormAssignedAdmin from './IssueFormUpdateAdmin'
import IssueFormApprovalAdmin from './IssueFormUpdateApproval'

//function to format and get date in this format yyyy/mm/dd 
function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

  return `${year}-${month}-${date}`;
}


const ReportPage = () => {

  const location = useLocation();

  const user_email = Auth.user.attributes.email

  const [jsonData, setJsonData] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const [isFirstRender, setIsFirstRender] = useState(true); // Track first render

  const [selectedStatus, setSelectedStatus] = useState([{value:"New",label:"New"},{value:"Re-Opened",label:"Re-Opened"},
                                                        {value:"To be Reviewed",label:"To be Reviewed"}]);
  const [allselectedStatus, setAllSelectedStatus] = useState([]);
  const [approval, setApproval] = useState(''); // Initialize the approval state
  const [assigned, setAssigned] = useState(''); // Initialize the approval state

  const [role , setrole]=useState("")

  const[isPreviousFetchData, setIsPreviousFetchData] = useState(false);

  //creating a state for each input field
  const [formState, setFormState] = useState({
    email: '',
    startdate: '',
    enddate: '',
    email_local:''
  });
  
  const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

  const fetchdata = async() => {

    if (formState.startdate==='' || formState.enddate==='' ){
    const alertMessage = {
      header: 'Error',
      body: "Input field cannot be empty",
    };
    viewAlert(alertMessage);
    }
    else{
      setIsSubmitting(true);
      setCurrentPage(Number(1));

    const accessToken = await getAccessToken();

    const emailValue = formState.email === '' ? 'all' : formState.email;

    const params = new URLSearchParams({
      email: emailValue,
      startdate: formState.startdate,
      enddate: formState.enddate,
      status: allselectedStatus.join(','),
      role: 'admin'
    });
    //the below code is for sending the input values to get the filtered data from dynamodb through api
    fetch(`https://1gu7g5j872.execute-api.us-east-1.amazonaws.com/dev/getissue?${params}`, {
      method: 'GET',
      headers: { 'Authorization':accessToken,
      'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
        setIsSubmitting(false);
        setJsonData(data.sort((a, b) => b.timestamp.localeCompare(a.timestamp)));
        updateFormState('email_local', emailValue);
        setIsPreviousFetchData(true);
      })
      .catch((error) => console.log(error.message));
          
  };
}


function fetchdatabytokennumber(ticketnumber) {

  (async () => {
    setIsSubmitting(true);
  const accessToken = await getAccessToken();
  //the below code is for sending the input values to get the filtered data from dynamodb through api
  fetch(`https://1gu7g5j872.execute-api.us-east-1.amazonaws.com/dev/getissuewithtokennumber?ticketnumber=` +ticketnumber , {
    method: 'GET',
    headers: { 'Authorization':accessToken,
    'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
    .then((data) => {
      console.log('Success:', data);
      setIsSubmitting(false);
      setJsonData(data);
      setIsPreviousFetchData(false);
    })
    .catch((error) => console.log(error.message));
})();
}

const formatDate = (date) => {
  const formattedDate = moment(date).format('DD/MM/YYYY');
  return formattedDate;
}

useEffect(() => {
  // Run only on the first render

  const searchParams = new URLSearchParams(location.search);
  const ticketnumber = searchParams.get('ticketnumber');
  if (ticketnumber) {
        // Fetch SOW based on the sowId
        console.log('yes')
        setSelectedStatus([]);
        fetchdatabytokennumber(ticketnumber);
  }
  else{   
  const currentDate = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1); // Set start date 1 month ago

  const formattedStartDate = format(startDate); // Convert start date to yyyy-mm-dd format
  const formattedEndDate = format(currentDate); // Convert end date to yyyy-mm-dd format

  updateFormState('startdate', formattedStartDate);
  updateFormState('enddate', formattedEndDate);
  setAllSelectedStatus(['New','Re-Opened','To be Reviewed'])
  setIsFirstRender(false);}

  const userData=JSON.parse(localStorage.getItem('userDataHelpDesk'));
  setrole(userData && userData.length > 0 && userData[0] && userData[0][0].role.toLowerCase())
}, []);

useEffect(() => {
  if (!isFirstRender && formState.startdate !== '' && formState.enddate !== '') {
    fetchdata(); // Fetch data after updating the form state
  }
}, [formState.startdate, formState.enddate]);


  //the below code is for downloading the fetched data into csv file
  const handleDownloadCSV = (e) => {
    //e.preventDefault();
    if (jsonData.length > 0) {
    const csvData = convertToCSV(jsonData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${formState.email_local.toUpperCase() === 'ALL' ? 'All Report.csv' : jsonData[0].name + '-Report.csv'}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    document.getElementById("export_select").value = "";
  }
    else{
      document.getElementById("export_select").value = "";
    }
  };

  //converting the fetched json file to csv
  function convertToCSV(data) {
    const rows = [];

    let columns;
  
      columns = [
        { label: 'Date', key: 'date' },
        { label: 'Name' , key: 'name'},
        { label: 'Email' , key: 'email'},
        { label: 'Ticket Number', key: 'ticket_number' },
        { label: 'Subject Line', key: 'subject_line' },
        { label: 'Issue Description', key: 'issue_description' },
        { label: 'Remarks', key: 'remarks' },
        { label: 'Status', key: 'status' }
      ]; // the order of columns we want

  
    const columnLabels = columns.map((col) => col.label);
    rows.push(columnLabels.join(","));
  
    for (const row of data) {
      const values = [];
      for (const column of columns) {
        const cell = row[column.key];
        values.push(`"${cell.toString().replace(/"/g, '""')}"`);
      }
      rows.push(values.join(","));
    }
  
    return rows.join("\n");
  }


  //function that will trigger on onclick download as pdf
  const downloadPDF = () => {
    if (jsonData.length > 0) {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
  
      //const marginLeft = 250;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);
  
      //let name = formState.email.split("@")[0];
      //let upper_name2= name.charAt(0).toUpperCase() + name.slice(1);
      //const title = upper_name2 + ' Report';

      let headpdf,data,columnStyles ,title;

        headpdf = ['Date','Ticket Number','Issue Description','Status']; // the order of columns we want
        data = jsonData.map((item) => [formatDate(item.date),item.ticket_number,item.issue_description,item.status]); // the order of columns we want
        columnStyles={  2: { cellWidth: 200 } }
        title = `${formState.email_local.toUpperCase() === 'ALL' ? 'All Report' : jsonData[0].name + '-Report'}`;
    

     // Get the width of the title
     const titleWidth = doc.getTextWidth(title);

    // Calculate the x-coordinate of the starting point
     const xPos = (doc.internal.pageSize.width - titleWidth) / 2;

     doc.text(title, xPos, 40);

     const tableStyle = {
      theme: 'grid',
      headStyles: {
        fillColor: [235, 191, 97],
        textColor: [51, 51, 51],
        //fontStyle: 'bold',
        //fontSize: 12
      },
  
      styles: {
        lineWidth: 0.2,
        lineColor: [192, 192, 192],
        fontStyle: 'normal',
        fontSize: 10,
        cellPadding: 5
      }
    };

      doc.autoTable({
        startY: 50,
        head: [headpdf],
        body: data,
        tableWidth: 'auto',
        columnStyles: columnStyles, // set the width of the 4th column to 80
        ...tableStyle
      });
  
      doc.save(`${formState.email_local.toUpperCase() === 'ALL' ? 'All Report.pdf' : jsonData[0].name + '-Report.pdf'}`);
      document.getElementById("export_select").value = "";
    }
    else{
      document.getElementById("export_select").value = ""; 
    }
  };


  // Calculate the index of the last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  // Calculate the index of the first item on the current page
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Get the current page's items from the data array
  const currentItems = jsonData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(jsonData.length / itemsPerPage);

  // Create an array of page numbers to render the pagination buttons
  let pageNumbers = [];
  if (currentPage <= 3) {
    // If we're on the first three pages, show all pages up to page 5
    pageNumbers = [...Array(Math.min(totalPages, 5)).keys()].map((num) => num + 1);
  } else {
    // If we're past page 3, show pages 1, 2, 3, current-1, current, current+1, and the last page
    pageNumbers = [1, 2, 3, currentPage - 1, currentPage, currentPage + 1, totalPages];
    // Remove duplicates and out-of-bounds page numbers
    pageNumbers = pageNumbers.filter(
      (num, index, arr) => arr.indexOf(num) === index && num >= 1 && num <= totalPages
    );
  }
//handling pageclick
  const handlePageClick = (event) => {
    setCurrentPage(Number(event.target.id));
    //console.log(event.target.id)
  };
 
  


//This is to update formstate of each input field
  const updateFormState = (key, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [key]: value,
    }));
  };

 
  const [selectedemail, setSelectedemail] = useState({ value: 'all', label: 'All' });
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    fetchEmails();
  }, []);

  async function fetchEmails() {
    try {
	  let accessToken = await getAccessToken();
      const response = await fetch('https://1gu7g5j872.execute-api.us-east-1.amazonaws.com/dev/getemails' ,{
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
      const data = await response.json();
      //console.log('emails',data)
      setEmails(data); // Set the fetched data directly
    } catch (error) {
      //.error('Error fetching emails:', error);
    }
  }

  const handleEmailChange = (selectedemail) => {
    setSelectedemail(selectedemail);
    //console.log(selectedemail.value)
    updateFormState('email' , selectedemail.value)
  };


  function viewAlert(alertMessage) {
    setAlertMessage(alertMessage);
    setShowAlertModal(true);
  }


  const getStatusColor = (status) => {
    if (status === 'New' || status === 'Re-Opened') {
      return 'red';

    } else if (status === 'In-Progress') {
      return 'orange';

    }else if (status === 'Completed') {
      return 'green';
    } else {
      return '';
    }
  }

  const statusOptions = [
    { value: 'New', label: 'New' },
    { value: 'In-Progress', label: 'In-Progress' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Re-Opened', label: 'Re-Opened' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'To be Reviewed', label: 'To be Reviewed' },
    { value: 'Approval-Pending', label: 'Approval-Pending' },
  ];

  const [selStatus, setSelStatus] = useState([]);
  const [selIssue, setSelIssue] = useState({});

  const [initialSelStatus, setInitialSelStatus] = useState([]);

  const [showIssueModalAdmin, setShowIssueModalAdmin] = useState(false);
  const [showIssueModalApprovalAdmin, setShowIssueModalApprovalAdmin] = useState(false);
  const [showIssueModalAssignedAdmin, setShowIssueModalAssignedAdmin] = useState(false);

  const [issueModalType, setIssueModalType] = useState("new");
  
  const [selAssingedEmail , setSelAssignedEmail]=useState("")
  const [selApprovalEmail , setSelApprovalEmail]=useState("")

  const [selRemarksHistory , setSelRemarksHistory]=useState([])
  const [selTicketCategory , setSelTicketCategory]=useState([])
  const [selHardwareType , setSelHardwareType]=useState([])
  const [selTicketType , setSelTicketType]=useState([])
  const [selProjectName , setSelProjectName]=useState([])



  const handleStatusChange = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
    setAllSelectedStatus(selectedOptions.map((status) => status.value)) 
  };  

    // Define a click handler for the edit icon
    const handleEditClick = (item) => {
      setIssueModalType("update");
      setSelIssue(item);
      setSelStatus(item.status ? [{ Type: item.status, display_key: item.status }] : []);
      setInitialSelStatus(item.status ? [{ Type: item.status, display_key: item.status }] : []);
      setSelAssignedEmail(item.assigned_to ? [{ID:item.assigned_to, display_key:item.assigned_to }] : []);
      setSelApprovalEmail(item.approval_from ? [{ID:item.approval_from, display_key:item.approval_from }] : []);
      setSelTicketCategory(item.ticket_category ? [{ID:item.ticket_category , display_key:item.ticket_category  }] : []);
      setSelHardwareType(item.hardwaretype ? [{ID:item.hardwaretype, display_key:item.hardwaretype }] : []);
      setSelTicketType(item.ticket_type? [{ID:item.ticket_type, display_key:item.ticket_type }] : []);
      setSelProjectName(item.projectname? [{ID:item.projectname, display_key:item.projectname }] : []);
      setSelRemarksHistory(item.remark_history);
      setApproval(item.is_approval_needed);
      setAssigned(item.assign_this_ticket);

      if (user_email===item.approval_from){
        setrole('approval-admin')
        setShowIssueModalApprovalAdmin(true);
      }
      else if (user_email===item.assigned_to){
        setrole('assigned-admin')
        setShowIssueModalAssignedAdmin(true);
      }
      else {
        setrole('admin')
        setShowIssueModalAdmin(true);
      }
     
    };
  
    // Define a click handler for the view icon
    const handleViewClick = (item) => {
      setIssueModalType("view");
      setSelIssue(item);
      setSelStatus(item.status ? [{ Type: item.status, display_key: item.status }] : []);
      setSelAssignedEmail(item.assigned_to ? [{ID:item.assigned_to, display_key:item.assigned_to }] : []);
      setSelApprovalEmail(item.approval_from ? [{ID:item.approval_from, display_key:item.approval_from }] : []);
      setSelTicketCategory(item.ticket_category ? [{Type:item.ticket_category , display_key:item.ticket_category  }] : []);
      setSelHardwareType(item.hardwaretype ? [{Type:item.hardwaretype, display_key:item.hardwaretype }] : []);
      setSelTicketType(item.ticket_type? [{Type:item.ticket_type, display_key:item.ticket_type }] : []);
      setSelProjectName(item.projectname? [{ID:item.projectname, display_key:item.projectname }] : []);
      setSelRemarksHistory(item.remark_history);
      setApproval(item.is_approval_needed);
      setAssigned(item.assign_this_ticket);

      if (user_email===item.approval_from){
        setrole('approval-admin')
        setShowIssueModalApprovalAdmin(true);
      }
      else if (user_email===item.assigned_to){
        setrole('assigned-admin')
        setShowIssueModalAssignedAdmin(true);
      }
      else {
        setrole('admin')
        setShowIssueModalAdmin(true);
      }
    };

    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState("");

    const handleSort = (column) => {
      if (sortColumn === column) {
        // If the same column is clicked, toggle the sorting order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If a different column is clicked, set it as the new sort column
        setSortColumn(column);
        setSortOrder("asc");
      }
    };

    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        if (sortOrder === "asc") {
          return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
        } else {
          return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
        }
      } else {
        return null; // Don't display any icon if the column is not the current sort column
      }
    };

    // Add this code inside the component before rendering the table
let sortedIssue = [...currentItems];
if (sortColumn && sortOrder) {
  currentItems.sort((a, b) => {
  const columnA = a[sortColumn];
  const columnB = b[sortColumn];
  if (columnA < columnB) {
    return sortOrder === "asc" ? -1 : 1;
  }
  if (columnA > columnB) {
    return sortOrder === "asc" ? 1 : -1;
  }
  return 0;
});
}

  return (
    <>

    <body>
      <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
      <IssueFormAdmin type={issueModalType} show={showIssueModalAdmin} setShow={setShowIssueModalAdmin} 
                 selIssue={selIssue} fetchdata={fetchdata} selStatus={selStatus} setSelStatus={setSelStatus} emails={emails}
                 setSelAssignedEmail={setSelAssignedEmail} selAssignedEmail={selAssingedEmail} 
                 setSelRemarksHistory={setSelRemarksHistory} selRemarksHistory={selRemarksHistory}
                 setSelTicketCategory={setSelTicketCategory} selTicketCategory={selTicketCategory}
                 setSelHardwareType={setSelHardwareType} selHardwareType={selHardwareType}
                 setSelTicketType={setSelTicketType} selTicketType={selTicketType} role={role}
                 setSelApprovalEmail={setSelApprovalEmail} selApprovalEmail={selApprovalEmail} setApproval={setApproval} 
                 approval={approval} viewAlert={viewAlert} initialSelStatus={initialSelStatus} 
                 fetchdatabytokennumber={fetchdatabytokennumber} isPreviousFetchData={isPreviousFetchData} selProjectName={selProjectName}
                 setSelProjectName={setSelProjectName} assigned={assigned} setAssigned={setAssigned} />

      <IssueFormApprovalAdmin type={issueModalType} show={showIssueModalApprovalAdmin} setShow={setShowIssueModalApprovalAdmin} 
                 selIssue={selIssue} fetchdata={fetchdata} selStatus={selStatus} setSelStatus={setSelStatus}
                 setSelAssignedEmail={setSelAssignedEmail} selAssignedEmail={selAssingedEmail}
                 setSelRemarksHistory={setSelRemarksHistory} selRemarksHistory={selRemarksHistory}
                 setSelTicketCategory={setSelTicketCategory} selTicketCategory={selTicketCategory}
                 setSelHardwareType={setSelHardwareType} selHardwareType={selHardwareType}
                 setSelTicketType={setSelTicketType} selTicketType={selTicketType} 
                 setSelApprovalEmail={setSelApprovalEmail} selApprovalEmail={selApprovalEmail} setApproval={setApproval} 
                 approval={approval} role={role} viewAlert={viewAlert} initialSelStatus={initialSelStatus}
                 fetchdatabytokennumber={fetchdatabytokennumber} isPreviousFetchData={isPreviousFetchData} selProjectName={selProjectName}
                 setSelProjectName={setSelProjectName} assigned={assigned} setAssigned={setAssigned}/>

      <IssueFormAssignedAdmin type={issueModalType} show={showIssueModalAssignedAdmin} setShow={setShowIssueModalAssignedAdmin} 
                 selIssue={selIssue} fetchdata={fetchdata} selStatus={selStatus} setSelStatus={setSelStatus} emails={emails}
                 setSelAssignedEmail={setSelAssignedEmail} selAssignedEmail={selAssingedEmail}
                 setSelRemarksHistory={setSelRemarksHistory} selRemarksHistory={selRemarksHistory}
                 setSelTicketCategory={setSelTicketCategory} selTicketCategory={selTicketCategory}
                 setSelHardwareType={setSelHardwareType} selHardwareType={selHardwareType}
                 setSelTicketType={setSelTicketType} selTicketType={selTicketType} 
                 setSelApprovalEmail={setSelApprovalEmail} selApprovalEmail={selApprovalEmail} setApproval={setApproval} 
                 approval={approval} role={role} viewAlert={viewAlert} initialSelStatus={initialSelStatus} 
                 fetchdatabytokennumber={fetchdatabytokennumber} isPreviousFetchData={isPreviousFetchData} selProjectName={selProjectName}
                 setSelProjectName={setSelProjectName} assigned={assigned} setAssigned={setAssigned}/>

       {isSubmitting  ? (
            <div id="loader-container">
              <div className="loader"></div>
            </div>
              ) : (null
        )}

          <div class='form2-helpdesk-admin'>
            <table>
              <thead>
               <tr>
                <th id ="email_col" class="v-m" >Email ID: <Select
                   id="email"
                   className="email-select" // Remove the duplicate className prop
                   value={selectedemail}
                   options={[
                   { value: 'all', label: 'All' },
                       ...emails.map((email) => ({ value: email.email, label: email.email }))
                   ]}
                   placeholder="name@dataterrain.com"
                   onChange={handleEmailChange}
                   classNamePrefix="email-select"
                    />
                </th>
               </tr>
              </thead>

              <thead>
               <tr >
               <th className="v-m">Status: 
            <Select
              isMulti
              id="status"
              value={selectedStatus}
              options={allselectedStatus.length<3 ? statusOptions : []}
              onChange={handleStatusChange}
              className="status-select"
              placeholder="Select status..."
              />
          </th>
                <th class="v-m">From: <input
                         id="startdaten"
                         type="date"
                         className ='input-labeln'
                         placeholder="Enter date"
                         required
                         onChange={(e) => updateFormState('startdate', e.target.value)}
                         value={formState.startdate} // Set the default value for start date
                         />
                </th>
                <th class="v-m">To: <input
                         id="enddaten"
                         type="date"
                         className ='input-labeln'
                         placeholder="Enter date"
                         required
                         onChange={(e) => updateFormState('enddate', e.target.value)}
                         value={formState.enddate} // Set the default value for end date
                         />
                 </th>

                  <th class="v-m"><button 
                        type="button" className ='button' onClick={fetchdata} >Submit</button>
                  </th>
                  <th class="v-m">
                       <select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 handleDownloadCSV();
                            } else if (e.target.value === 'pdf') {
                                 downloadPDF();
                              }
                            }}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        <option value="pdf">PDF</option>
                       </select>

                  </th>
              </tr>
                           </thead>
                          </table>

          </div>
          
         
          {jsonData.length > 0 ? (
            <div className="container-table">
  <table>
    <thead>
      <tr style={{borderBottom: '1px solid lightgray'}}>
        <th className='sequentialNumber'></th>
        <th className="date-column" style={{borderRight: '1px solid lightgray'}} onClick={() => handleSort("date")}>Date {renderSortIcon("date")}</th>
        <th className="ticketnumber-column" style={{borderRight: '1px solid lightgray'}} onClick={() => handleSort("ticket_number")}>Ticket Number {renderSortIcon("ticket_number")}</th>
        <th className="name-column" style={{borderRight: '1px solid lightgray'}} onClick={() => handleSort("name")}>Name {renderSortIcon("name")}</th>
        <th className="subjectline-column" style={{borderRight: '1px solid lightgray'}} onClick={() => handleSort("subject_line")}>Subject Line {renderSortIcon("subject_line")}</th>
        <th className="status-column" style={{borderRight: '1px solid lightgray'}} onClick={() => handleSort("status")}>Status {renderSortIcon("status")}</th>
        <th className="actions-column" style={{borderRight: '1px solid lightgray'}} >Actions</th>
      </tr>
    </thead>
    <tbody>
      {currentItems.map((item, index) => {
         const sequentialNumber = index + 1; // Calculate the sequential number
        return(
        <tr key={index} style={{borderBottom: '1px solid lightgray'}}>
          <td className='sequentialNumber'>{sequentialNumber}. </td>
          <td className="date-column" style={{borderRight: '1px solid lightgray'}}>{formatDate(item.date)}</td>
          <td className="ticketnumber-column" style={{borderRight: '1px solid lightgray'}}>{item.ticket_number}
          {item.is_approved === 'approved' && <span style={{ color: 'green', marginLeft: '5px' }}>(Approved)</span>}
          {item.is_approved === 'rejected' && <span style={{ color: 'red', marginLeft: '5px' }}>(Rejected)</span>}
          {(item.assigned_to!==''  && item.assigned_to!== user_email )&&(item.assigned_to!==null) && 
          <span style={{ color: 'green', marginLeft: '5px' }}>(Ticket assigned to other)</span>}
          {item.assigned_to=== user_email && <span style={{ color: 'green', marginLeft: '5px' }}>(Ticket assigned to you)</span>}</td>
          <td className="name-column" style={{borderRight: '1px solid lightgray'}}>{item.name}</td>
          <td className="subjectline-column" style={{borderRight: '1px solid lightgray'}}>{item.subject_line}</td>
          <td className="status-column" style={{ color: getStatusColor(item.status),borderRight: '1px solid lightgray' }}>
            {item.status}
          </td>
          <td className="actions-column">
            <div className="icon-container">

              {item.is_approved==='rejected' || (item.is_approval_needed==='yes' && item.is_approved==="") ?null:
              <button onClick={() => handleEditClick(item)}
              disabled={item.is_approved==='rejected' || (item.is_approval_needed==='yes' && item.is_approved==="") ? true:false}>
                <RiEdit2Line style={{ fontSize: '24px' }} />
              </button>}
              <button onClick={() => handleViewClick(item)}>
                <RiEyeLine style={{ fontSize: '24px' }} />
              </button>
            </div>
      </td>
                                        </tr>
                                       )
                                      })}
                                    </tbody>
                     </table>
                  

          <div className='page'>
              {currentPage > 1 && (
                <button id={currentPage - 1} onClick={handlePageClick}>
                  Prev
                </button>
               )}
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  id={number}
                  onClick={handlePageClick}
                  className={currentPage === number ? 'active' : null}
                 >
               {number}
                </button>
            ))}
              {currentPage < totalPages && (
                <button id={currentPage + 1} onClick={handlePageClick}>
                  Next
                </button>
               )}
           </div>

              </div>
                   ) : (
                         <p style={{"text-align":"center"}}>No data available</p>
                       )}


    </body>
    </>
  );
};
export default ReportPage; 