import { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import ProjectDelete from "./ProjectDelete";
import ProjectForm from "./ProjectForm";
import Card from 'react-bootstrap/Card';
import 'bootstrap-icons/font/bootstrap-icons.css'
import AlertModal from "../AlertModal";
import { Auth } from '@aws-amplify/auth';
import { saveAs } from 'file-saver';
import { exportCSV,exportXLSX} from './exportUtils'; // Create these utility functions to handle CSV and PDF exports


export default function ProjectHome() {
    const [projects, setProjects] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searching, setSearching] = useState(false);

    const location = useLocation();


    const [selProject, setSelProject] = useState({});

    const [empLov, setEmpLov] = useState({});
    const [clientLov, setClientLov] = useState({});

    //search Member Objcts
    const [projectMembers, setProjectMembers] = useState([]);
    const [selProjectLead, setSelProjectLead] = useState("");
    const [selProjectType, setSelProjectType] = useState("");
    const [selClient, setSelClient] = useState([]);

    //AlertModal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});

    //DeleteModal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //Project Form Modal
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [projectModalType, setProjectModalType] = useState("new");



    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    function fetchEmpLov() {
        (async () => {
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/emp/emplov', {
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setEmpLov(result);
        })();
    }

    function fetchClientLov() {
        (async () => {
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client/clientlov', {
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setClientLov(result);
        })();
    }

    const [SOWs, setSOWs] = useState([]);
    const [SOWLov, setSOWLov] = useState({});
    const [projLov, setProjLov] = useState({});

    function fetchSOWLov() {
      (async () => {
        setSearching(true);
          let accessToken = await getAccessToken();
          const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/sow/sowlov', {
              headers: new Headers({
                  'Authorization': accessToken
              })
          });
          const result = await response.json();
          setSOWLov(result);
          setSearching(false);
      })();
  }

  function fetchProjLov() {
    (async () => {
      setSearching(true);
        let accessToken = await getAccessToken();
        const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project/projlov', {
            headers: new Headers({
                'Authorization': accessToken
            })
        });
        const result = await response.json();
        setProjLov(result);
        setSearching(false);
    })();
}

    useEffect(() => {
        fetchEmpLov()
        fetchClientLov()
        fetchSOWLov()
        fetchProjLov()
        const searchParams = new URLSearchParams(location.search);
        const projectId = searchParams.get('projectId');
        if (projectId) {
              // Fetch SOW based on the sowId
            fetchProjects(projectId);
          } else {
              // Fetch all SOWs
            fetchProjects();}
    }, []);



    function fetchProjects(searchStr) {
        (async () => {
            setSearching(true);
            setSearchQuery(searchStr ? searchStr : searchQuery);
            let myHeaders = new Headers();
            let accessToken = await getAccessToken();
            myHeaders.append("Content-Type", "application/json");
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project?searchName=' + (searchStr ? searchStr.toLowerCase() : searchQuery.toLowerCase()),
                {
                    headers: new Headers({
                        'Authorization': accessToken
                    })
                }
            );
            const result = await response.json();
            result.sort((a, b) => a.project_name.localeCompare(b.project_name)); // Sort the fetched data by first_name
            console.log(result)
            setProjects(result);
            setSearching(false);
        })();
    }

    function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
    }

    function newProject() {
        setProjectModalType("new");
        setSelProject({});
        setProjectMembers([]);
        setSelProjectType([]);
        setSelProjectLead([]);
        setSelClient([]);
        setSOWs([])
        setShowProjectModal(true);
    }

    function updateProject(project) {
        setProjectModalType("update");
        setSelProject(project);
        setSelProjectType(project.project_type ? [{ Type: project.project_type, display_key: project.project_type }] : []);
        setSelProjectLead(project.project_lead ? [{ ID: project.project_lead, display_key: empLov[project.project_lead].display_key }] : []);
        setProjectMembers(project.project_team_member==='' ? [] :project.project_team_member);
        setSelClient(project.client_name ? [{ ID: project.client_name, display_key: clientLov[project.client_name].display_key }] : []);
        setSOWs(project.sow ===''? []: project.sow)
        setShowProjectModal(true);
    }

    function viewProject(project) {
        setProjectModalType("view");
        setSelProject(project);
        setSelProjectType(project.project_type ? [{ Type: project.project_type, display_key: project.project_type }] : []);
        setSelProjectLead(project.project_lead ? [{ ID: project.project_lead, display_key: empLov[project.project_lead].display_key }] : []);
        setProjectMembers(project.project_team_member==='' ? [] :project.project_team_member);
        setSelClient(project.client_name ? [{ ID: project.client_name, display_key: clientLov[project.client_name].display_key }] : []);
        setSOWs(project.sow ===''? []: project.sow )
        setShowProjectModal(true);
    }


    function deleteProject(project) {
        setSelProject(project);
        setShowDeleteModal(true);
    }

    const [viewMode, setViewMode] = useState('table');

    const toggleViewMode = () => {
      setViewMode(prevMode => (prevMode === 'card' ? 'table' : 'card'));
    };

    const [viewModeProject, setViewModeProject] = useState('current');

    const toggleViewModeProject = () => {
      setViewModeProject(viewModeProject === 'all' ? 'current' : 'all');
    };

    const exportData = (e) => {
      // Export as CSV
      if(e==='csv'){
      const csvData = exportCSV(projects,empLov,clientLov);
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(csvBlob, 'projects.csv');
      document.getElementById("export_select").value = "";
  }
  else if(e=='xlsx'){
    exportXLSX(projects, empLov,clientLov)
    .then((xlsxBuffer) => {
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'projects.xlsx');
      document.getElementById("export_select").value = "";
    })
    .catch((error) => {
      console.error('Error exporting XLSX:', error);
    });
   }
     else{
      /* Export as PDF
      const pdfData = exportPDF(projects);
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      saveAs(pdfBlob, 'projects.pdf');
      document.getElementById("export_select").value = "";*/
  }
    };

    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState("");

    const handleSort = (column) => {
      if (sortColumn === column) {
        // If the same column is clicked, toggle the sorting order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If a different column is clicked, set it as the new sort column
        setSortColumn(column);
        setSortOrder("asc");
      }
    };

    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        if (sortOrder === "asc") {
          return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
        } else {
          return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
        }
      } else {
        return null; // Don't display any icon if the column is not the current sort column
      }
    };

    // Filter SOWs based on view mode and end date
const filteredProjects = projects.filter((Project) => {
  if (viewModeProject === 'current') {
    return true; // Show all SOWs
  } else {
    const endDate = Project.project_end_date;
    const currentDate = new Date();
    return (endDate==='' || new Date(endDate) >= currentDate); // Show only SOWs with end date >= current date
  }
});

    // Add this code inside the component before rendering the table
let sortedProjects = [...filteredProjects];
if (sortColumn && sortOrder) {
sortedProjects.sort((a, b) => {
  const columnA = a[sortColumn];
  const columnB = b[sortColumn];
  if (columnA < columnB) {
    return sortOrder === "asc" ? -1 : 1;
  }
  if (columnA > columnB) {
    return sortOrder === "asc" ? 1 : -1;
  }
  return 0;
});
}


    return (
        <>
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
            <ProjectDelete show={showDeleteModal} setShow={setShowDeleteModal} selProject={selProject} fetchProjects={fetchProjects} viewAlert={viewAlert} />
            <ProjectForm setSelProjectLead={setSelProjectLead} selProjectLead={selProjectLead} setProjectMembers={setProjectMembers} projectMembers={projectMembers} selProjectType={selProjectType} setSelProjectType={setSelProjectType} type={projectModalType} show={showProjectModal} setShow={setShowProjectModal} selProject={selProject} fetchProjects={fetchProjects} viewAlert={viewAlert}
             empLov={empLov} clientLov={clientLov} setSelClient={setSelClient} selClient={selClient} SOWs={SOWs} setSOWs={setSOWs} SOWLov={SOWLov} projLov={projLov} />
            <Container fluid>
                <Row >
                    <Col xs lg="3">
                        <InputGroup className="mb-3">

                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                placeholder="Search for a project"
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            /><Button variant="outline-secondary" id="button-addon2" onClick={() => fetchProjects()}>
                                <i className="bi bi-search"></i>
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col xs lg="3">
                        <InputGroup className="mb-3">


                            <Button variant="primary" id="button-addon1" onClick={newProject} disabled={searching ? true : false}>
                                <i className="bi bi-file-earmark-plus"></i> Create new
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                    <Button variant="outline-secondary" onClick={toggleViewModeProject} style={{marginRight:'5px'}}>
          {viewModeProject === 'all' ? "All Projects" : "Current Projects"}
        </Button>
        <Button variant="outline-secondary" onClick={toggleViewMode}>
          {viewMode === 'card' ? 'Table View' : 'Card View'}
        </Button>

<select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 exportData('csv');
                            } else if (e.target.value === 'pdf') {
                                exportData('pdf');
                              }
                              else if (e.target.value === 'xlsx') {
                                exportData('xlsx');
                              }
                            }}
                            style={{padding:'5px 6px 7px 6px',
                            backgroundColor:'white',color:'#3e3e3e',border:'1px solid',
                            marginLeft:'5px'}}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        {/*<option value="pdf">PDF</option>*/}
                        <option value="xlsx">Excel</option>
                       </select>
      </Col>

                </Row>
                {(searching || (Object.keys(empLov).length === 0)) ? (
                    <Row ><Spinner animation="border" /></Row>
                  ):(
                    <>
                        {projects.length === 0 ?(
                            <Row ><p>No projects found. Please redefine the search criteria</p>
                            </Row>
                           ):(
                            <>
                            {viewMode === 'card' ? (
                            <Row xs={1} md={5} className="g-4">
                                {filteredProjects.map((project, index) => {
                                   const sequentialNumber = index + 1; // Calculate the sequential number
                                    return (
                                        <Col key={project.ID}>
                                            <Card className={project.project_end_date && new Date(project.project_end_date) < new Date() ? 'non-current-member' : ''}>
                                                <Card.Header>{sequentialNumber}. {project.project_name}</Card.Header>
                                                <Card.Body style={{ fontSize: "13px" }}>
                                                    <Card.Text>Project ID: {project.project_id}</Card.Text>
                                                    <Card.Text>Lead: {project.project_lead}</Card.Text>
                                                    <Card.Text>Technology: {project.project_technology}</Card.Text>
                                                    <Row >
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => viewProject(project)}><i className="bi bi-eye"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => updateProject(project)}><i className="bi bi-pencil"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteProject(project)}><i className="bi bi-trash"></i>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                  )
                })}
              </Row>
            ) : (
                                <table className="table">
<thead>
  <tr>
    <th onClick={() => handleSort("project_name")}>Name {renderSortIcon("project_name")}</th>
    <th onClick={() => handleSort("project_id")}>Project ID {renderSortIcon("project_id")}</th>
    <th onClick={() => handleSort("project_lead")}>Project Lead {renderSortIcon("project_lead")}</th>
    <th onClick={() => handleSort("project_technology")}>Project Technology {renderSortIcon("project_technology")}</th>
    <th>Actions</th>
  </tr>
</thead>

                <tbody>
                  {sortedProjects.map((project, index) => {
                    const sequentialNumber = index + 1; // Calculate the sequential number
                    return (
                    <tr key={project.ID} className={project.project_end_date && new Date(project.project_end_date) < new Date() ? 'non-current-member' : ''}>
                      <td>{sequentialNumber}. {project.project_name}</td>
                      <td>{project.project_id}</td>
                      <td>{project.project_lead}</td>
                      <td>{project.project_technology}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => viewProject(project)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => updateProject(project)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => deleteProject(project)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-trash"></i>
                          </button>
                      </td>
                    </tr>
                  );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}
      </>
    )}
  </Container>
</>

    )
}