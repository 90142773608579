import React, { useState, useEffect } from 'react';
import './App.css';
import { Auth } from 'aws-amplify';
import SignOutTimer from './SignOutTimer';
import { checkUserRole } from './RoleCheck';
import TasksheetApp from './Apps/TasksheetApp/TasksheetApp'
//import ProjectTrackerEmp from './Apps/ProjectTrackerEmp/ProjectTrakerEmp'
//import { Helmet } from 'react-helmet';
import ProjectTrackerLandingPage from './ProjectTrackerLandingPage'
import TasksheetLandingPage from './TasksheetLandingPage'

function useFetchData() {
  const [userData, setUserData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const cachedData = localStorage.getItem('userData');
    if (cachedData) {
      setUserData(JSON.parse(cachedData));
      setIsDataLoaded(true);
      return;
    }
    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}
   
    const fetchData = async () => {
      setIsSubmitting(true);
      
      const accessToken = await getAccessToken();

      const params = new URLSearchParams({
        email: Auth.user.attributes.email,
      });
      
      try {
        const response = await fetch(
          `https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/get_tasksheet_access_role_data?${params}`, {
            method: 'GET',
            headers: { 'Authorization':accessToken,
            'Content-Type': 'application/json' },
          })
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setUserData(data);
        localStorage.setItem('userData', JSON.stringify(data));
        setIsDataLoaded(true);
        setIsSubmitting(false);
      } catch (error) {
        console.log(error.message);
        setIsSubmitting(false);
      }
    };

    if (!cachedData) {
      fetchData();
    }
  }, []);

  return { userData, isDataLoaded, isSubmitting };
}

function App() {
  const { userData, isDataLoaded, isSubmitting } = useFetchData();
  const isUserAuthorized = userData && userData.length > 0 && userData[1] && userData[1][0].role.toLowerCase() === 'admin';

  useEffect(() => {
    checkUserRole(); // Call the checkUserRole function
  }, []);

  return (
    <body>
      {isSubmitting && (
        <div id="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {!isDataLoaded && ( // Render loading state until data is loaded
         null
      )}
      {isDataLoaded && ( // Render the content only when data is loaded
        <>
          {isUserAuthorized ? ( 
          
              <ProjectTrackerLandingPage />
          
          ) : (
              <TasksheetLandingPage/>
          )}
        </>
      )}
      <SignOutTimer signOutAfter={10 * 60 * 1000} />
    </body>
  );
}

export default App;






