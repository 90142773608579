import { useState } from 'react';
import { Auth } from 'aws-amplify'
import './IssueForm.css'
import moment from 'moment';
import AlertModal from "./AlertModal";
import Select from 'react-select';


//function to format and get date in yyyy/mm/dd format
  function format(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${year}-${month}-${date}`;
  }


    const formatDate = (date) => {
      const formattedDate = moment(date).format('DD/MM/YYYY');
      return formattedDate;
    }
    

//content inside the innerapp

function InnerApp() {


  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const [selectedTicketCategory, setSelectedTicketCategory] = useState(null);
  const [selectedHardwareType, setSelectedHardwareType] = useState(null);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);



  function resetFormState() {
    updateFormState('subjectline', '');
    updateFormState('issuedescription', '');
    updateFormState('ticketcategory', '');
    updateFormState('hardwaretype', '');
    updateFormState('tickettype', '');
    updateFormState('attachment', '');
  }

  function viewAlert(alertMessage) {
    setAlertMessage(alertMessage);
    setShowAlertModal(true);
}
  const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

  async function submit_data(){

  const cur_date = format(new Date());
  
  const regex = /\w+/;

  if (formState.ticketcategory===""||(formState.ticketcategory==="Hardware" && formState.hardwaretype==="") ||
      formState.tickettype==="" || formState.subjectline==='' || (!regex.test(formState.issuedescription)) )
    {

      if (formState.ticketcategory===""){
        const alertMessage = {
          header: 'Error',
          body: "Ticker Category cannot be empty",
        };
        viewAlert(alertMessage);
      }
    else  if (formState.ticketcategory==="Hardware" && formState.hardwaretype===""){
        const alertMessage = {
          header: 'Error',
          body: "Hardware type cannot be empty",
        };
        viewAlert(alertMessage);
      }
    else  if (formState.tickettype===""){
        const alertMessage = {
          header: 'Error',
          body: "Ticket type cannot be empty",
        };
        viewAlert(alertMessage);
      }
    else if (formState.subjectline===''){
      const alertMessage = {
        header: 'Error',
        body: "Subject line cannot be empty",
      };
      viewAlert(alertMessage);
    }
    else if (formState.issuedescription===""){
      const alertMessage = {
        header: 'Error',
        body: "Issue description field cannot be empty",
      };
      viewAlert(alertMessage);
    }
    else if ((!regex.test(formState.issuedescription))){
      const alertMessage = {
        header: 'Error',
        body: "Issue Description field must contain words",
      };
      viewAlert(alertMessage);
    }
    else{
    const alertMessage = {
      header: 'Error',
      body: "Input field cannot be empty",
    };
    viewAlert(alertMessage);
    }
  }

  else{
    setIsSubmitting(true);
    const formatted_date = formatDate(formState.date)

    const accessToken = await getAccessToken();

    const email = Auth.user.attributes.email;
    const username = email.split("@")[0];
    const ticket_number = moment().format('YYYYMMDDHHmmss')+'-'+username;
    const date = moment().format('YYYY-MM-DD');
    const timestamp =  moment().format('YYYY/MM/DD, HH:mm:ss');

      
	  const attachments = formState.attachment ? [formState.attachment] : [];
	  const data = new FormData();

      // Append form data
      data.append('name', Auth.user.attributes.name);
      data.append('email', Auth.user.attributes.email);
      data.append('subject_line', formState.subjectline);
      data.append('issue_description', formState.issuedescription);
      data.append('remarks', '');
      data.append('status', 'New');
      data.append('ticket_number', ticket_number);
      data.append('date', date);
      data.append('timestamp', timestamp);
      data.append('is_approval_needed', 'no');
      data.append('is_approved', '');
      data.append('assign_this_ticket', 'no');
      data.append('assigned_to', '');
      data.append('approval_from', '');
      data.append('remark_history', '');
      data.append('ticket_category', formState.ticketcategory);
      data.append('ticket_type', formState.tickettype);
      data.append('hardwaretype', formState.hardwaretype);
      data.append('last_remark', '');
      data.append('projectname', formState.projectname);


      // Append attachment file(s)
      attachments.forEach((attachment, index) => {
        data.append(`attachment${index + 1}`, attachment);
      });

  fetch('https://1gu7g5j872.execute-api.us-east-1.amazonaws.com/dev/issuesubmitwithattachment', 
  {
  method: 'POST',
  headers: {'Authorization':accessToken,
},
  body: data,
  })
  .then((response) => response.json())
  //Then with the data from the response in JSON...
  .then((data) => {
  console.log('Success:', data);

  setIsSubmitting(false);
  const alertMessage = {
    header: "Submission Successful",
    body: "Your request has been successfully submitted. Your ticket number is " + ticket_number + "."
  };
  viewAlert(alertMessage);
  
  //reseting the form field to empty after submit
  document.getElementById("Issue survey").reset();
  setSelectedTicketCategory(null);
  setSelectedHardwareType(null);
  setSelectedTicketType(null);
  setSelectedProject(null);
  })

  //Then with the error genereted...
  .catch((error) => {
  console.error('Error:', error);
  });
  
  //calling reset function to reset all my state to null once form is submitted 
  resetFormState();
}

}


const [formState, setFormState] = useState({
  subjectline:'',
  issuedescription:'',
  ticketcategory:"",
  hardwaretype:"",
  tickettype:"",
  projectname:"",
  attachment:""
});
//This is to update formstate of each input field
const updateFormState = (key, value) => {
  setFormState((prevFormState) => ({
    ...prevFormState,
    [key]: value,
  }));
};


//console.log('name' , Auth.user.attributes)
// Define custom styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '45px',
    //fontWeight: state.selectProps.placeholderIsFocused ? 'bold' : 'normal',
  }),
  placeholder: (provided) => ({
    ...provided,
    //color: 'rgba(0, 0, 0, 0.8)', // Update the color here
    opacity: 1, // Update the opacity here

  }),
};


let sortedProjectNameValues = []
if (localStorage.getItem('userData')){
  const projectname = JSON.parse(localStorage.getItem('userData'))[0]
  //console.log("sortedCloumn1",projectname)
  const projectNameValues = projectname.map(item => item.project_name.S)
  sortedProjectNameValues = projectNameValues.sort((a,b) => a.localeCompare(b));
  //console.log("sortedCloumn",sortedProjectNameValues)
}

  return (
<>
  <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
  <div className="login-form">
    {isSubmitting ? (
      <div id="loader-container">
        <div className="loader"></div>
      </div>
    ) : null}

    <div id="form1" className="issue-form">
      <form id="Issue survey">
            <Select
              id={formState.ticketcategory ==='Hardware' ? "ticket_category":"ticket_category1"}
              className="input_label1"
              options={[
                { value: 'Hardware', label: 'Hardware' },
                { value: 'Software', label: 'Software' },
                { value: 'Database', label: 'Database' },
              ]}
              value={selectedTicketCategory}
              placeholder="Ticket Category *"
              onChange={(selectedOption) => {
                setSelectedTicketCategory(selectedOption);
                updateFormState('ticketcategory', selectedOption?.value || '');
              }}
              styles={customStyles}
            />
          {formState.ticketcategory === 'Hardware' && (
              <Select
                id="hardware_type"
                className="input_label1"
                options={[
                  { value: 'Desktop', label: 'Desktop' },
                  { value: 'Laptop', label: 'Laptop' },
                ]}
                value={selectedHardwareType}
                placeholder="Hardware Type *"
                onChange={(selectedOption) => {
                  setSelectedHardwareType(selectedOption);
                  updateFormState('hardwaretype', selectedOption?.value || '');
                }}
                styles={customStyles}
              />
          )}

            <Select
              id={formState.ticketcategory ==='Hardware' ? "ticket_type" : "ticket_type1"}
              className="input_label1"
              options={[
                { value: 'Issue', label: 'Issue' },
                { value: 'Service request', label: 'Service request' },
              ]}
              value={selectedTicketType}
              placeholder="Ticket Type *"
              onChange={(selectedOption) => {
                setSelectedTicketType(selectedOption);
                updateFormState('tickettype', selectedOption?.value || '');
              }}
              styles={customStyles}
            />

        <br/>

          <input
            type="text"
            id="subject_line"
            className="input_label1"
            placeholder="Subject line *"
            onChange={(e) => updateFormState('subjectline', e.target.value)}
          />
        
        <br />

        <textarea
          id="taskdescription"
          maxLength="1000"
          placeholder="Issue Description*"
          className="input"
          onChange={(e) => updateFormState('issuedescription', e.target.value)}
        />
        <br />

        <Select
              id="helpdesk-project_name"
              className="input_label1"
              options={localStorage.getItem('userData') ? sortedProjectNameValues.map((project, index) => ({
                value: project,
                label: project
                     })) : []}
              value={selectedProject}
              placeholder="Project Name"
              onChange={(selectedOption) => {
                setSelectedProject(selectedOption);
                updateFormState('projectname', selectedOption?.value || '');
              }}
            />

        <input
          type="file"
          id="attachment"
          className="input_label1"
          onChange={(e) => updateFormState('attachment', e.target.files[0])}
        />

        <br/>
        <br/>
        <center>
          <button id="submit" type="button" onClick={submit_data}>
            Submit
          </button>
        </center>
      </form>
    </div>
  </div>
</>
  )
}

export default InnerApp