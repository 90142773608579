import React,{useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./resourceTab.css"
import CodeEthics from "./images/code-ethics.png";
import EmailPolicy from "./images/email-policy.png"
import EmployeeHandbook from "./images/employee-handbook.png"
import AccessControl from "./images/access-control.png"
import AcceptableUsePolicy from "./images/acceptable-use-policy.png"
import HolidayList2024 from "./images/holiday-list-2024.png"
import AccessPage from '../TasksheetApp/Pages/AccessPage';
import Logo from "./images/logo-grey.png"
import { Auth } from 'aws-amplify';
import CodeEthicsPDF from "./pdf/code-ethics.pdf";
import EmailPolicyPDF from "./pdf/email-policy.pdf"
import EmployeeHandbookPDF from "./pdf/employee-handbook.pdf"
import AccessControlPDF from "./pdf/access-control.pdf"
import AcceptableUsePolicyPDF from "./pdf/acceptable-use-policy.pdf"
import HolidayList2024PDF from "./pdf/holiday-list-2024.pdf"

const ResourceTab = () => {

  const [PDFPath, setPDFPath] = useState("");

  function openPDFPopup(pdfPath) {
    setPDFPath(pdfPath)
    document.getElementById('pdfModal').style.display = 'flex';

  }

  function closePDFPopup() {
    setPDFPath("")
    document.getElementById('pdfModal').style.display = 'none';
  }

  return (
    <div>
    <div className='P_header'>
    <Link to="/" className="navbar-brand">
      <img src={Logo} style={{width:"200px" ,height:"70px"}}  alt="Logo" />
    </Link>
    <div className="P_signout-container">
          <button onClick={() => Auth.signOut()} id='P_signout'>Sign out</button>
    </div>
    </div>
      <div className="pdf-icons-container">
        <img src={CodeEthics} className="pdf-icon" alt="Code of Ethics" onClick={() => openPDFPopup(CodeEthicsPDF)} />
        <img src={EmailPolicy} className="pdf-icon" alt="Email Policy" onClick={() => openPDFPopup(EmailPolicyPDF)} />
        <img src={EmployeeHandbook} className="pdf-icon" alt="Employee Handbook" onClick={() => openPDFPopup(EmployeeHandbookPDF)} />
        <img src={AccessControl} className="pdf-icon" alt="Access Control" onClick={() => openPDFPopup(AccessControlPDF)} />
        <img src={AcceptableUsePolicy} className="pdf-icon" alt="Acceptable Use Policy" onClick={() => openPDFPopup(AcceptableUsePolicyPDF)} />
        <img src={HolidayList2024} className="pdf-icon" alt="Holiday List 2024" onClick={() => openPDFPopup(HolidayList2024PDF)} />
      </div>
      <div id="pdfModal" className="modal-overlay">
      <div className="modal-content" id="modal-content">
        <span className="close-btn" onClick={closePDFPopup}>&times;</span>
        { PDFPath!="" ?
          <iframe src={`${PDFPath}#zoom=50`} width="100%" height="100%" />
          :
          null
        }
      </div>
    </div>
    </div>
  );
};

export default ResourceTab;
