import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

function SignOutTimer(props) {
  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        Auth.signOut();
      }, props.signOutAfter);
    };

    const clearTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    // Set up event listeners to reset/clear the timer
    window.addEventListener('click', resetTimer);
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('touchmove', resetTimer);

    return () => {
      // Clean up event listeners when the component is unmounted
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('touchmove', resetTimer);
      clearTimer();
    };
  }, [props.signOutAfter]);

  return null;
}

export default SignOutTimer;