import { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import EmpDelete from "./EmpDelete";
import EmpForm from "./EmpForm";
import Card from 'react-bootstrap/Card';
import 'bootstrap-icons/font/bootstrap-icons.css'
import AlertModal from "../AlertModal";
import {Auth} from '@aws-amplify/auth';
import EmployeeHistoryModal from './EmployeeHistory';
import { saveAs } from 'file-saver';
import { exportCSV,exportXLSX, exportPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports

export default function EmpHome() {
    const [emps, setEmps] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searching, setSearching] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const location = useLocation();

    const [selEmp, setSelEmp] = useState({});

    //AlertModal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});

    //DeleteModal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //Emp Form Modal
    const [showEmpModal, setShowEmpModal] = useState(false);
    const [empModalType, setEmpModalType] = useState("new");

    const [skillset, setSkillset] = useState([]);

    const [projects, setProjects] = useState([]);

    const [searchFilter, setSearchFilter] = useState("name"); // Initial filter set to "name"

    const [employeeHistory, setEmployeeHistory] = useState([]);
    const [projectDetails, setProjectDetails] = useState([]);

    const [projLov, setProjLov] = useState({});
    const [selAsset, setSelAsset] = useState([]);
  
    function fetchProjLov() {
      (async () => {
        setSearching(true);
          let accessToken = await getAccessToken();
          const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project/projlov', {
              headers: new Headers({
                  'Authorization': accessToken
              })
          });
          const result = await response.json();
          console.log(result)
          setProjLov(result);
          setSearching(false);
      })();
  }

  useEffect(() => {
      fetchProjLov()
      
      const searchParams = new URLSearchParams(location.search);
      const empId = searchParams.get('empId');
      if (empId) {
            // Fetch SOW based on the sowId
            console.log('yes')
            fetchEmps(empId);
        } else {
            // Fetch all SOWs
            console.log('no')
            fetchEmps();}
  }, []);

    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

    function fetchEmps(searchStr) {        
            (async () => {
                setSearching(true);
                setSearchQuery(searchStr ? searchStr : searchQuery);
                let myHeaders = new Headers();
                let accessToken = await getAccessToken();
                const searchParam = searchFilter === "skill"
                         ? `searchSkill=${searchStr ? searchStr.toLowerCase() : searchQuery.toLowerCase()}`
                         : searchFilter === "id"
                         ? `ID=${searchQuery}`
                         : `searchName=${searchStr ? searchStr.toLowerCase() : searchQuery.toLowerCase()}`;
                myHeaders.append("Content-Type", "application/json");
                const response = await fetch(`https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/emp?${searchParam}`,
                    {headers: new Headers({
                        'Authorization':accessToken
                    })}
                );
                const result = await response.json();
                result.sort((a, b) => a.first_name.localeCompare(b.first_name)); // Sort the fetched data by first_name
                setEmps(result);
                console.log(result)
                setSearching(false);
            })();
    }

    function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
    }

    function newEmp() {
        setEmpModalType("new");
        setSelEmp({});
        setSkillset([]);
        setProjectDetails([]);
        setSelAsset([]);
        setShowEmpModal(true);
    }

    function updateEmp(emp) {
        setEmpModalType("update");
        setSelEmp(emp);
        emp.skillset==='' ? setSkillset([]) :setSkillset(emp.skillset);
        setProjectDetails(emp.project_details==='[]' ? [] : emp.project_details);
        setSelAsset(emp.asset ? [{Type:emp.asset, display_key:emp.asset}] : []);
        setShowEmpModal(true);
    }

    function viewEmp(emp) {
        setEmpModalType("view");
        setSelEmp(emp);
        emp.skillset==='' ? setSkillset([]) :setSkillset(emp.skillset);
        setProjectDetails(emp.project_details==='[]' ? [] : emp.project_details);
        setSelAsset(emp.asset ? [{Type:emp.asset, display_key:emp.asset}] : []);
        setShowEmpModal(true);
    }


    function deleteEmp(emp) {
        setSelEmp(emp);
        setShowDeleteModal(true);
    }
    const [showHistoryModal, setShowHistoryModal] = useState(false);



    function selectEmployee(emp) {
      setSelEmp(emp);
      setEmployeeHistory(emp.project_details);
      setShowHistoryModal(true);
      }

      const [viewMode, setViewMode] = useState('table');
      const [viewModeEmp, setViewModeEmp] = useState('current'); // 'all' or 'current'

      const toggleViewMode = () => {
        setViewMode(prevMode => (prevMode === 'card' ? 'table' : 'card'));
      };

      const toggleViewModeEmp = () => {
        setViewModeEmp(prevMode => (prevMode  === 'all' ? 'current' : 'all'));
      };

      const exportData = (e) => {
        // Export as CSV
        if(e==='csv'){
        const csvData = exportCSV(emps,projLov);
        const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(csvBlob, 'employees.csv');
        document.getElementById("export_select").value = "";
       }
       else if(e=='xlsx'){
        exportXLSX(emps, projLov)
        .then((xlsxBuffer) => {
          const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'employees.xlsx');
          document.getElementById("export_select").value = "";
        })
        .catch((error) => {
          console.error('Error exporting XLSX:', error);
        });
       }
       else{
        // Export as PDF
        const pdfData = exportPDF(emps);
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        saveAs(pdfBlob, 'employees.pdf');
        document.getElementById("export_select").value = "";
    }
      };

      const [sortOrder, setSortOrder] = useState("asc");
      const [sortColumn, setSortColumn] = useState("");

      const handleSort = (column) => {
        if (sortColumn === column) {
          // If the same column is clicked, toggle the sorting order
          setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
          // If a different column is clicked, set it as the new sort column
          setSortColumn(column);
          setSortOrder("asc");
        }
      };

      const renderSortIcon = (column) => {
        if (sortColumn === column) {
          if (sortOrder === "asc") {
            return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
          } else {
            return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
          }
        } else {
          return null; // Don't display any icon if the column is not the current sort column
        }
      };

      // Filter SOWs based on view mode and end date
const filteredEmps = emps.filter((emp) => {
  if (viewModeEmp === 'current') {
    return true; // Show all SOWs
  } else {
    const relieved_date = emp.relieved_date;
    console.log(relieved_date)
    return relieved_date===''; // Show only SOWs with end date >= current date
  }
});

      // Add this code inside the component before rendering the table
let sortedEmps = [...filteredEmps];
if (sortColumn && sortOrder) {
  sortedEmps.sort((a, b) => {
    const columnA = a[sortColumn];
    const columnB = b[sortColumn];
    if (columnA < columnB) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (columnA > columnB) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });
}



    return (
<>
  <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
  <EmpDelete show={showDeleteModal} setShow={setShowDeleteModal} selEmp={selEmp} fetchEmps={fetchEmps} viewAlert={viewAlert} />
  <EmpForm type={empModalType} show={showEmpModal} setShow={setShowEmpModal} selEmp={selEmp} projectDetails={projectDetails} setProjectDetails={setProjectDetails} fetchEmps={fetchEmps} skillset={skillset} setSkillset={setSkillset} setProjects={setProjects} projects={projects} viewAlert={viewAlert} 
   projLov={projLov} setSelAsset={setSelAsset} selAsset={selAsset}/>
  {isFetching && (
    <div id="loader-container">
      <div className="loader"></div>
    </div>
  )}

  <EmployeeHistoryModal
    employeeID={selEmp?.id}
    show={showHistoryModal}
    setshow={setShowHistoryModal}
    onHide={() => setShowHistoryModal(false)}
    setEmployeeHistory={setEmployeeHistory}
    employeeHistory={employeeHistory}
    projLov={projLov}
  />

  <Container fluid>
    <Row>
      <Col xs lg="5">
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="Search input"
            className="col-md-10"
            placeholder={`Search by ${searchFilter === 'skill' ? 'Skill' : searchFilter === 'id' ? 'ID' : 'Name'}`}
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "200px" }} // Adjust the width as needed
          />

          <Form.Select
            aria-label="Search filter"
            className="col-md-2"
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          >
            <option value="name">Search by Name</option>
            <option value="id">Search by ID</option>
            <option value="skill">Search by Skill</option>
          </Form.Select>


          <Button variant="outline-secondary" id="button-addon2" onClick={() => fetchEmps()}>
            <i className="bi bi-search"></i>
          </Button>
        </InputGroup>
      </Col>
      <Col xs lg="2" style={{ maxWidth: '160px' }}>
        <InputGroup className="mb-3">
          <Button variant="primary" className="P_button" id="button-addon1" onClick={newEmp}>
            <i className="bi bi-file-earmark-plus"></i> Create new
          </Button>
        </InputGroup>
      </Col>


      
      <Col style={{ textAlign: 'right' }}>
      <Button variant="outline-secondary" onClick={toggleViewModeEmp} style={{marginRight:'5px'}}>
          {viewModeEmp === 'all' ? "All Employees" : "Current Employees"}
        </Button>
        <Button variant="outline-secondary" onClick={toggleViewMode}>
          {viewMode === 'card' ? 'Table View' : 'Card View'}
        </Button>

<select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 exportData('csv');
                            } else if (e.target.value === 'pdf') {
                                exportData('pdf');
                              }
                              else if (e.target.value === 'xlsx') {
                                exportData('xlsx');
                              }
                            }}
                            style={{padding:'5px 6px 7px 6px',
                            backgroundColor:'white',color:'#3e3e3e',border:'1px solid',
                            marginLeft:'5px'}}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        {/*<option value="pdf">PDF</option>*/}
                         <option value="xlsx">Excel</option>
                       </select>
      </Col>
    </Row>
    {searchFilter === 'skill' && (
      <Row style={{ maxWidth: '470px',marginLeft:'2px',marginBottom:'10px' }}>
        
          <InputGroup.Text className="ml-2 text-danger">
            input more than one skill by using comma as a separator
          </InputGroup.Text>
        </Row>
      )}
    {searching ? (
      <Row>
        <Spinner animation="border" />
      </Row>
    ) : (
      <>
        {emps.length === 0 ? (
          <Row>
            <p>No employees found. Please redefine the search criteria</p>
          </Row>
        ) : (
          <>
            {viewMode === 'card' ? (
              <Row xs={1} md={5} className="g-4">
                {filteredEmps.map((emp, index) => { // Added index parameter
                  const sequentialNumber = index + 1; // Calculate the sequential number
                  return (
                    <Col key={emp.ID}>
                      <Card className={emp.relieved_date ? 'non-current-member' : ''}>
                        <Card.Header>{sequentialNumber}. {emp.first_name} {emp.last_name}</Card.Header>
                        <Card.Body style={{ fontSize: "13px" }}>
                          <Card.Text>ID: {emp.ID}</Card.Text>
                          <Card.Text>Email: {emp.email_id_office}</Card.Text>
                          <Card.Text>Date of Joining: {emp.joining_date}</Card.Text>
                          <Row>
                            <Col xs lg="2">
                              <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => viewEmp(emp)}>
                                <i className="bi bi-eye"></i>
                              </button>
                            </Col>
                            <Col xs lg="2">
                              <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => updateEmp(emp)}>
                                <i className="bi bi-pencil"></i>
                              </button>
                            </Col>
                            <Col xs lg="2">
                              <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteEmp(emp)}>
                                <i className="bi bi-trash"></i>
                              </button>
                            </Col>
                            <Col xs lg="2">
                              <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => selectEmployee(emp)}>
                                <i className="bi bi-clock-history"></i>
                              </button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            ) : (
                <table className="table">
<thead>
  <tr>
    <th onClick={() => handleSort("first_name")}>Name {renderSortIcon("first_name")}</th>
    <th onClick={() => handleSort("ID")}>ID {renderSortIcon("ID")}</th>
    <th onClick={() => handleSort("email_id_office")}>Email {renderSortIcon("email_id_office")}</th>
    <th onClick={() => handleSort("joining_date")}>Date of Joining {renderSortIcon("joining_date")}</th>
    <th>Actions</th>
  </tr>
</thead>

                <tbody>
                  {sortedEmps.map((emp, index) => {
                    const sequentialNumber = index + 1; // Calculate the sequential number
                    return (
                    <tr key={emp.ID} className={emp.relieved_date ?'non-current-member' :''}>
                      <td>{sequentialNumber}. {emp.first_name} {emp.last_name}</td>
                      <td>{emp.ID}</td>
                      <td>{emp.email_id_office}</td>
                      <td>{emp.joining_date}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => viewEmp(emp)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => updateEmp(emp)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => deleteEmp(emp)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => selectEmployee(emp)}
                        >
                          <i className="bi bi-clock-history"></i>
                        </button>
                      </td>
                    </tr>
                  );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}
      </>
    )}
  </Container>
</>

    )
}