import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarA from './Components/NavbarA';
import NavbarB from './Components/NavbarB';
import IssueFormPage from './Pages/IssueForm';
import StatusPageA from './Pages/StatusPage_A';
import StatusPageB from './Pages/StatusPage_B';
import './HelpDeskApp.css';
import 'normalize.css';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
//import 'bootstrap/dist/css/bootstrap.css';

function useFetchData() {
  const [userData, setUserData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const cachedData = localStorage.getItem('userDataHelpDesk');
    if (cachedData) {
      setUserData(JSON.parse(cachedData));
      setIsDataLoaded(true);
      return;
    }
    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}
   
    const fetchData = async () => {
      setIsSubmitting(true);
      
      const accessToken = await getAccessToken();

      const params = new URLSearchParams({
        email: Auth.user.attributes.email,
      });
      
      try {
        const response = await fetch(
          `https://1gu7g5j872.execute-api.us-east-1.amazonaws.com/dev/access_role_data?${params}`, {
            method: 'GET',
            headers: { 'Authorization':accessToken,
            'Content-Type': 'application/json' },
          })
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log("role",data)
        setUserData(data);
        localStorage.setItem('userDataHelpDesk', JSON.stringify(data));
        setIsDataLoaded(true);
        setIsSubmitting(false);
      } catch (error) {
        console.log(error.message);
        setIsSubmitting(false);
      }
    };

    if (!cachedData) {
      fetchData();
    }
  }, []);

  return { userData, isDataLoaded, isSubmitting };
}

function App() {
  const { userData, isDataLoaded, isSubmitting } = useFetchData();
  const isUserAuthorized = userData && userData.length > 0 && userData[0] && userData[0][0].role.toLowerCase() === 'admin';
  const [isLoading, setIsLoading] = useState(false);

  /*
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
*/
  return (
    <>
 {/*   <Helmet>
    <link rel="stylesheet" href="/bootstrap/dist/css/bootstrap.min.css" />  This file is referring to public/bootstrap 
     </Helmet> */}

  {isLoading ? <>
      <div id="loader-container">
      <div className="loader"></div>
    </div> </> 
    :<>

    <body>
            {isSubmitting && (
        <div id="loader-container">
          <div className="loader"></div>
        </div>
      )}
            {!isDataLoaded && ( // Render loading state until data is loaded
         null
      )}
            {isDataLoaded && ( // Render the content only when data is loaded
        <>
        {isUserAuthorized ? (
          <div>
            <NavbarA />
            <Routes>
              <Route path="/" element={<IssueFormPage />} />
              <Route path="/status" element={<StatusPageA />} />
            </Routes>
          </div>
        ) : (
          <div>
            <NavbarB />
            <Routes>
              <Route path="/" element={<IssueFormPage />} />
              <Route path="/status" element={<StatusPageB />} />
            </Routes>
          </div>
        )}
                </>
      )}
    </body>
    </>}
    </>
  );
}

export default App;