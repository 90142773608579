import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Auth } from 'aws-amplify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import "./ReportPage.css";
import EditRecord from "./EditTasksheetRecord";
import DeleteRecord from './DeleteTasksheetRecord';
import AlertModal from "./AlertModal";
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';

//function to format and get date in yyyy/mm/dd format
function format(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

  return `${year}-${month}-${date}`;
}


const ReportPage = () => {

  const [jsonData, setJsonData] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const [editDataSelProject, setEditDataSelProject] = useState([]);

  const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

  const fetchdata = async() => {
        
  if (formState.startdate==='' || formState.enddate===''|| formState.projectname==='' ||(formState.startdate>formState.enddate)){
    if(formState.startdate>formState.enddate){
      const alertMessage = {
        header: 'Error',
        body: "Start date cannot be greater than End date",
      };
      viewAlert(alertMessage);
    }
    else{
      const alertMessage = {
      header: 'Error',
      body: "Input field cannot be empty",
    };
    viewAlert(alertMessage);
    }
  }
    else{
      setIsSubmitting(true);
      setCurrentPage(Number(1));

    const accessToken = await getAccessToken();

    const params = new URLSearchParams({
      email: Auth.user.attributes.email,
      projectname:formState.projectname,
      startdate: formState.startdate,
      enddate: formState.enddate,
    });
    //console.log(params)
    //the below code is for sending the input values to get the filtered data from dynamodb through api
    fetch(`https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/get_tasksheet_record?${params}`, {
      method: 'GET',
      headers: { 'Authorization':accessToken,
      'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
        setIsSubmitting(false);
        setJsonData(data);
      })
      .catch((error) => console.log(error.message));
          
  };
}

const combinedData = jsonData.map((item) => {
  return {
    email:item.email,
    sort_key:item.sort_key,
    date:item.date,
    hours:item.hours,
    minutes:item.minutes,
    name:item.name,
    projectname:item.projectname,
    taskdescription:item.taskdescription,
    time: `${item.hours}:${item.minutes}`
  };
});

//DeleteModal
const [showDeleteModal, setShowDeleteModal] = useState(false);
const [deleteData, setdeleteData] = useState({});

//handle delete event
const handleDelete = async(item) => {

const data = {
  "email": item.email,
  "sort_key":item.sort_key
}

setdeleteData(data);
setShowDeleteModal(true);

}


//below code is to handle edit event and modal

const [editData, setEditData] = useState({});
const [isModalOpen, setIsModalOpen] = useState(false);

    // Update the editData state when user clicks edit button
    const handleEdit = (data) => {
      const updatedEditData = {
        ...editData,
        name : data.name,
        email :data.email,
        projectname: data.projectname,
        date: data.date,
        hours: data.hours,
        minutes: data.minutes,
        taskdescription: data.taskdescription.slice(0, -40),
        sort_key: data.sort_key,
      };
      setEditData(updatedEditData);
      setEditDataSelProject(data.projectname !== "" ? [{ value:data.projectname, display_key:data.projectname}] : []);
      //console.log(data, 'ddd', updatedEditData);
      setIsModalOpen(true);
    };
  
    //EditModal
const [showEditModal, setShowEditModal] = useState(false);

// Handle form submission in the modal to edit the previous data
const handleModalSubmit = async(event) => {
  event.preventDefault();
  const cur_date = format(new Date());
  let shouldCloseModal = true;

  const regex = /\w+/;
  let minutes
  if (editData.minutes===''){
    minutes = '00'
  }
  else{
    minutes = editData.minutes
  }

  if (editData.taskdescription==='' || editData.date==='' || (editData.hours==='00' && minutes==='00') || editData.hours===''
   ||editData.date > cur_date ||  editDataSelProject.length===0||editData.projectname===''||  (!regex.test(editData.taskdescription)) )
    {

      if (editData.date > cur_date){
        const alertMessage = {
          header: 'Error',
          body: "You can't fill form for future date's",
        };
        viewAlert(alertMessage);
        shouldCloseModal = false;
      }
      else if (editData.hours==='00' && minutes==='00'){
        const alertMessage = {
          header: 'Error',
          body: "hours and minutes cannot be 00 and 00",
        };
        viewAlert(alertMessage);
        shouldCloseModal = false;
      }
      else if (editData.taskdescription===""){
        const alertMessage = {
          header: 'Error',
          body: "Input field cannot be empty",
        };
        viewAlert(alertMessage);
        shouldCloseModal = false;
      }
      else if ((!regex.test(editData.taskdescription))){
        const alertMessage = {
          header: 'Error',
          body: "Task Description field must contain words",
        };
        viewAlert(alertMessage);
        shouldCloseModal = false;
      }
      else{
      const alertMessage = {
        header: 'Error',
        body: "Input field cannot be empty",
      };
      viewAlert(alertMessage);
      shouldCloseModal = false;
      }
    }
    else{
     
      setShowEditModal(true);
      shouldCloseModal = false;
    // call API to update data in DynamoDB
    if (shouldCloseModal) {
      setIsModalOpen(false);
    }
  };
  }


//date formatting from yyyy/mm/dd to dd/mm/yyyy
/* const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  return `${day}/${month}/${year}`;
} */

const formatDate = (date) => {
  const formattedDate = moment(date).format('DD/MM/YYYY');
  return formattedDate;
}


  //the below code is for downloading the fetched data into csv file
  const handleDownloadCSV = (e) => {
    //e.preventDefault();
    if (combinedData.length > 0) {
    const csvData = convertToCSV(combinedData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${Auth.user.attributes.name}-TaskSheet.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    document.getElementById("export_select").value = "";
  }
    else{
      document.getElementById("export_select").value = "";
    }
  };

  //converting the fetched json file to csv
  function convertToCSV(data) {
    const rows = [];
    const columns = [
      { label: 'Date', key: 'date' },
      { label: 'Project Name', key: 'projectname' },
      { label: 'Time Spent', key: 'time' },
      { label: 'Task Description', key: 'taskdescription' },
    ];
    const columnLabels = columns.map((col) => col.label);
    rows.push(columnLabels.join(","));
  
    for (const row of data) {
      const values = [];
      for (const column of columns) {
        const cell = row[column.key];
        values.push(`"${cell.toString().replace(/"/g, '""')}"`);
      }
      rows.push(values.join(","));
    }
  
    return rows.join("\n");
  }
  



  //function that will trigger on onclick download as pdf
  const downloadPDF = () => {
    if (combinedData.length > 0) {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
  
      //const marginLeft = 250;
      const doc = new jsPDF(orientation, unit, size);
    
      doc.setFontSize(15);
  const title = Auth.user.attributes.name + '\'s Report';

  // Get the width of the title
  const titleWidth = doc.getTextWidth(title);

  // Calculate the x-coordinate of the starting point
  const xPos = (doc.internal.pageSize.width - titleWidth) / 2;

  doc.text(title, xPos, 40);

  const tableStyle = {
    theme: 'grid',
    headStyles: {
      fillColor: [235, 191, 97],
      textColor: [51, 51, 51],
      //fontStyle: 'bold',
      //fontSize: 12
    },

    styles: {
      lineWidth: 0.2,
      lineColor: [192, 192, 192],
      fontStyle: 'normal',
      fontSize: 10,
      cellPadding: 5
    }
  };

  doc.autoTable({
    startY: 50,
    head: [['Date', 'Project Name', 'Time Spent', 'Task Description']],
    body: combinedData.map((item) => [formatDate(item.date), item.projectname, item.time, item.taskdescription]),
    tableWidth: 'auto',
    columnStyles: { 2: {cellWidth:40  },3: { cellWidth: 300 } }, // set the width of the 4th column to 300
    ...tableStyle
  });

  doc.save(`${Auth.user.attributes.name}-TaskSheet.pdf`);
  document.getElementById("export_select").value = "";
}
  else{
    document.getElementById("export_select").value = ""; 
  }
};


  // Calculate the index of the last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  // Calculate the index of the first item on the current page
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Get the current page's items from the data array
  const currentItems = combinedData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(combinedData.length / itemsPerPage);

  // Create an array of page numbers to render the pagination buttons
  let pageNumbers = [];
  if (currentPage <= 3) {
    // If we're on the first three pages, show all pages up to page 5
    pageNumbers = [...Array(Math.min(totalPages, 5)).keys()].map((num) => num + 1);
  } else {
    // If we're past page 3, show pages 1, 2, 3, current-1, current, current+1, and the last page
    pageNumbers = [1, 2, 3, currentPage - 1, currentPage, currentPage + 1, totalPages];
    // Remove duplicates and out-of-bounds page numbers
    pageNumbers = pageNumbers.filter(
      (num, index, arr) => arr.indexOf(num) === index && num >= 1 && num <= totalPages
    );
  }
//handling pageclick
  const handlePageClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };
 
  

//creating a state for each input field
  const [formState, setFormState] = useState({
    projectname:'all',
    startdate: '',
    enddate: '',
  });
//This is to update formstate of each input field
  const updateFormState = (key, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [key]: value,
    }));
  };

  //let name = Auth.user.attributes.email.split("@")[0];
  //let upper_name= name.charAt(0).toUpperCase() + name.slice(1);


  //<h1 className="heading" >Welcome to Reports {upper_name} </h1>
  let sortedProjectNameValues = []
  if (localStorage.getItem('userData')){
    const projectname = JSON.parse(localStorage.getItem('userData'))[0]
    //console.log("sortedCloumn1",projectname)
    const projectNameValues = projectname.map(item => item.project_name.S)
    sortedProjectNameValues = projectNameValues.sort((a,b) => a.localeCompare(b));
    //console.log("sortedCloumn",sortedProjectNameValues)
  }
   

  function viewAlert(alertMessage) {
    setAlertMessage(alertMessage);
    setShowAlertModal(true);
  }
  const [selectedProject, setSelectedProject] = useState({ value: 'All', label: 'All' });

  const handleProjectChange = (selectedproject) => {
    setSelectedProject(selectedproject);
    console.log(selectedproject.value)
    updateFormState('projectname', selectedproject.value);
  }

  const handleClose = () =>{
    setIsModalOpen(false);
  }
  
  const handleeditselprojectchange=(selectedOption) =>{
      setEditDataSelProject(selectedOption);
  }

  return (
    <body>
      <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
      <EditRecord show={showEditModal} setShow={setShowEditModal} editData={editData} fetchdata={fetchdata} setIsModalOpen={setIsModalOpen} setIsSubmitting={setIsSubmitting} 
       editDataSelProject={editDataSelProject} viewAlert={viewAlert} />
      <DeleteRecord show={showDeleteModal} setShow={setShowDeleteModal} deleteData={deleteData} fetchdata={fetchdata} setIsSubmitting={setIsSubmitting} viewAlert={viewAlert} />

        {isSubmitting  ? (
            <div id="loader-container">
              <div className="loader"></div>
            </div>
          ) : (null
         )}

          <div class='form2'>
            <table>
              <thead>
               <tr >
                <th class="v-m">Projects: <Select
                    id="dropdown_2"
                    className="input-labeln"
                    value={selectedProject}
                    options={[
                    { value: 'All', label: 'All' },
                       ...(localStorage.getItem('userData')
                      ? sortedProjectNameValues.map((project, index) => ({
                           value: project,
                           label: project,
                           key: index,
                       }))
                         : []),
                     ]}
                     onChange = {handleProjectChange}
                     placeholder="Choose below option."
                     />
                </th>
                <th class="v-m">From: <input
                         id="startdaten"
                         type="date"
                         className ='input-labeln'
                         placeholder="Enter date"
                         required
                         onChange={(e) => updateFormState('startdate', e.target.value)} />
                </th>
                <th class="v-m">To: <input
                         id="enddaten"
                         type="date"
                         className ='input-labeln'
                         placeholder="Enter date"
                         required
                         onChange={(e) => updateFormState('enddate', e.target.value)}/>
                 </th>
                  <th class="v-m"><button 
                        type="button" className ='button' onClick={fetchdata} >Submit</button>
                  </th>
                  <th class="v-m">
                       <select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 handleDownloadCSV();
                            } else if (e.target.value === 'pdf') {
                                 downloadPDF();
                              }
                            }}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        <option value="pdf">PDF</option>
                       </select>

                  </th>
              </tr>
                           </thead>
                          </table>

          </div>
         
          {jsonData.length > 0 ? (
             <div class = "container-table">
                <table>
                 <thead>
                  <tr>
                    <th className='date-column'>Date</th>
                    <th className='projectname-column'>Project Name</th>
                    <th className='timespent-column'>Time Spent</th>
                    <th className="taskdescription-column" >Task Description</th>
                  </tr>
                 </thead>
                 <tbody>
                   {currentItems.map((item, index) => (
                    <tr key={index}>
                     <td className='date-column'>{formatDate(item.date)}</td>
                       <td className='projectname-column'>{item.projectname}</td>
                       <td className='timespent-column'>{item.time}</td>
                       <td className="taskdescription-column" >{item.taskdescription.split('\n').map((line, i) => <div key={i}>{line}<br/></div>)}</td>
                       <td><button ClassName = "button-in-table"  onClick={() => handleEdit(item)}>Edit</button></td>
                       <td><button ClassName = "button-in-table" onClick={() => handleDelete(item)}>Delete</button></td>
                     </tr>
                     ))}
                  </tbody>
                </table>

                <Modal
  show={isModalOpen}
  onHide={handleClose}
  backdrop="static"
  keyboard={false}
  size="lg"
  className="tasksheet-edit-modal"
>
  <Modal.Header closeButton>
    <Modal.Title>
      <h5>Edit Record</h5>
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleModalSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="5" controlId="validationCustom01">
        <Typeahead
            id="project-name-typeahead"
            labelKey="display_key"
            name="projectname"
            selected={editDataSelProject}
            onChange={handleeditselprojectchange}
            options={
              localStorage.getItem("userData")
                ? sortedProjectNameValues.map((project, index) => ({
                    value: project,
                    display_key: project,
                  }))
                : []
            }
            placeholder="Project Name *"
          />
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom01">
          <Form.Control
            type="date"
            className="form-control"
            name="date"
            value={editData.date}
            onChange={(e) =>
              setEditData({ ...editData, date: e.target.value })
            }
            disabled={true}
          />
        </Form.Group>
        <Form.Group as={Col} md="2" controlId="validationCustom01">
          <Form.Select
            className="form-select"
            name="hours"
            value={editData.hours}
            onChange={(e) =>
              setEditData({ ...editData, hours: e.target.value })
            }
          >
            <option value="">Hours *</option>
            <option value="00">00</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="2" controlId="validationCustom01">
          <Form.Select
            className="form-select"
            name="minutes"
            value={editData.minutes}
            onChange={(e) =>
              setEditData({ ...editData, minutes: e.target.value })
            }
          >
            <option value="">Minutes</option>
            <option value="00">00</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="validationCustom01">
        <Form.Control
          as="textarea"
          id="taskdescription_editform"
          maxLength="1000"
          name="taskdescription"
          value={editData.taskdescription}
          onChange={(e) =>
            setEditData({ ...editData, taskdescription: e.target.value })
          }
          className="form-control"
        />
      </Form.Group>
      <div className="text-center">
        <Button variant="primary" type="submit" className="me-2 mb-2">
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose} className="me-2 mb-2">
          Close
        </Button>
      </div>
    </Form>
  </Modal.Body>
</Modal>

          <div className='page'>
              {currentPage > 1 && (
                <button id={currentPage - 1} onClick={handlePageClick}>
                  Prev
                </button>
               )}
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  id={number}
                  onClick={handlePageClick}
                  className={currentPage === number ? 'active' : null}
                 >
               {number}
                </button>
            ))}
              {currentPage < totalPages && (
                <button id={currentPage + 1} onClick={handlePageClick}>
                  Next
                </button>
               )}
           </div>

              </div>
                   ) : (
                         <p style={{"text-align":"center"}}>No data available</p>
                       )}

    </body>
    
  );
};

export default ReportPage; 