import 'bootstrap-icons/font/bootstrap-icons.css';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

export default function Sidebar() {
    const location = useLocation();
    return (
        <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 bg-light sidebar">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <Nav variant="pills" defaultActiveKey="link-1">
                    
                    <Nav.Item>
                        <NavLink
                            to="/projecttracker/emp"
                            className={`nav-link ${location.pathname==='/projecttracker/emp' || location.pathname === '/projecttracker' ? 'active' : ''}`}
                            activeClassName="active"
                        >
                            <i className="bi bi-person-circle"></i> Employee
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item>
                        <NavLink
                            to="/projecttracker/project"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <i className="bi bi-rocket-fill"></i> Project
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item>
                        <NavLink
                            to="/projecttracker/client"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <i className="bi bi-people-fill"></i> Client
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item>
                        <NavLink
                            to="/projecttracker/sow"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <i className="bi bi-file-earmark-text-fill"></i> SOW
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item>
                        <NavLink
                            to="/projecttracker/vendor"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <i className="bi bi-shop"></i> Vendor
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item>
                        <NavLink
                            to="/projecttracker/reports"
                            className="nav-link"
                            activeClassName="active"
                        >
                            <i class="bi bi-file-text"></i> Reports
                        </NavLink>
                    </Nav.Item>

                </Nav>
            </div>
        </div>
    );
}
