import jsPDF from 'jspdf';
import exceljs from 'exceljs'; // Import exceljs library

// Helper function to flatten nested objects
function flattenObject(obj, projLov) {
  const flattened = {};
  const formattedCurrentDate = new Date().toISOString().slice(0, 10); // Get current date in yyyy-mm-dd format

  function flatten(currentObj, propName) {
    if (currentObj === null || typeof currentObj !== 'object' || Array.isArray(currentObj)) {
      flattened[propName] = currentObj;
    } else {
      for (const [key, value] of Object.entries(currentObj)) {
        const newPropName = propName ? `${propName}.${key}` : key;

        if (key === 'project_details') {
          // Handle project_details separately
          if (Array.isArray(value)) {
            flattened[newPropName] = value
              //.filter((details) => details.status === 'active')
              .filter((details) => details.endDate >= formattedCurrentDate || details.endDate === '')
              .map((details) => projLov[details.ID].project_name)
              .join(', ');
          } else {
            flattened[newPropName] = [];
          }
        } else {
          flatten(value, newPropName);
        }
      }
    }
  }

  flatten(obj, '');

  return flattened;
}

// Export employee data as CSV
export const exportCSV = (data, projLov) => {
  const flattenedData = data.map((emp) => flattenObject(emp, projLov));

  // Define the desired column order
  const columnOrder = [
    'ID', 'first_name', 'last_name', 'joining_date',
    'relieved_date', 'it_experience_from', 'skillset', 'project_details',
    'contact_number', 'email_id_office', 'email_id_personal', 'date_of_birth',
    'address_street', 'address_state', 'address_city', 'zipcode',"asset",'emp_remarks'
  ];

  // Filter the columns based on the desired order
  const filteredHeaders = columnOrder.filter((header) => Object.keys(flattenedData[0]).includes(header));

  const rows = flattenedData.map((emp) =>
    filteredHeaders.map((header) => `"${emp[header]?.toString().replace(/"/g, '""') || ''}"`)
  );

  const csvContent = `${filteredHeaders.join(',')}\n${rows.map((row) => row.join(',')).join('\n')}`;
  return csvContent;
};



// Export employee data as PDF
export const exportPDF = (data, projLov, selEmp) => {
  const flattenedData = data.map((project) => {
    return {
      ID: project.ID,
      ProjectName: projLov[project.ID]?.project_name || 'N/A',
      StartDate: project.startDate,
      EndDate: project.endDate,
      Billable: project.billable ? 'Yes' : 'No',
    };
  });

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
      //const marginLeft = 250;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);  const title=selEmp.first_name+' '+selEmp.last_name +' Projects Data'

       // Get the width of the title
       const titleWidth = doc.getTextWidth(title);

       // Calculate the x-coordinate of the starting point
        const xPos = (doc.internal.pageSize.width - titleWidth) / 2;
   
        doc.text(title, xPos, 40);

  const tableHeaders = Object.keys(flattenedData[0]);
  const tableData = flattenedData.map((project) => Object.values(project));

  const tableStyle = {
    theme: 'grid',
    headStyles: {
      fillColor: [235, 191, 97],
      textColor: [51, 51, 51],
      //fontStyle: 'bold',
      //fontSize: 12
    },

    styles: {
      lineWidth: 0.2,
      lineColor: [192, 192, 192],
      fontStyle: 'normal',
      fontSize: 10,
      cellPadding: 5
    }
  };

    doc.autoTable({
      startY: 50,
      head: [tableHeaders],
      body: tableData,
      tableWidth: 'auto',
      columnStyles: 'auto', // set the width of the 4th column to 80
      ...tableStyle
    });

  return doc.output('blob');
};


// Export employee data as CSV

export const exportXLSX = (data, projLov) => {
  const flattenedData = data.map((emp) => flattenObject(emp, projLov));

  // Define the desired column order
  const columnOrder = [
    'ID', 'first_name', 'last_name', 'joining_date',
    'relieved_date', 'it_experience_from', 'skillset', 'project_details',
    'contact_number', 'email_id_office', 'email_id_personal', 'date_of_birth',
    'address_street', 'address_state', 'address_city', 'zipcode',"asset",'emp_remarks'
  ];

  const workbook = new exceljs.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Add column headers
  const columns = columnOrder.filter((header) => Object.keys(flattenedData[0]).includes(header));
  worksheet.addRow(columns);

  // Add data rows
  flattenedData.forEach((row) => {
    const rowData = columns.map((column) => row[column]?.toString().replace(/"/g, '""') || '');
    worksheet.addRow(rowData);
  });

  // Generate and return the Excel file as a Blob
  return workbook.xlsx.writeBuffer();
};
