import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import 'vis-timeline/styles/vis-timeline-graph2d.css';

const EmployeeHistoryModal = ({ employeeID, show, setshow, onHide, setEmployeeHistory, employeeHistory,projLov }) => {
  const [selectedProject, setSelectedProject] = useState('all');

  const filteredData = employeeHistory.filter(item => {
    if (selectedProject !== 'all' && projLov[item.ID].project_name !== selectedProject) {
      return false;
    }
    return true;
  });

  const handleProjectFilterChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const currentDate = new Date();

  const data = [
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Project Name' },
      { type: "string", label: "Resource" },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
    ],
    ...filteredData.map((project, index) => [
      `Task ${index + 1}`,
      projLov[project.ID].project_name,
      project.endDate && new Date(project.endDate) < currentDate ? 'Completed' : 'Ongoing',
      new Date(project.startDate),
      project.endDate
        ? new Date(project.endDate) > currentDate
          ? currentDate
          : new Date(project.endDate)
        : currentDate,
      null, // Duration (can be calculated based on start and end dates)
      project.endDate && new Date(project.endDate) < currentDate ? 100 : null,
      null, // Dependencies (if any)
    ]),
    [
      `Task ${filteredData.length + 1}`, // Task ID for the "Projects" row
      'Projects', // Project Name for the "Projects" row
      null,
      new Date(currentDate), // Start Date for the "Projects" row
      new Date(currentDate), // End Date for the "Projects" row
      null, // Duration (can be calculated based on start and end dates)
      null, // Percent Complete for the "Projects" row
      null, // Dependencies (if any) for the "Projects" row
    ],
  ];

  const handleClose = () => {
    setshow(false);
    setEmployeeHistory([]);
    setSelectedProject('all')
  };
//console.log('history_page',employeeHistory)
{/*
  const Legend = () => {
    const completedProjects = filteredData.filter((project) => new Date(project.project_end_date) < currentDate);
    const ongoingProjects = filteredData.filter((project) => new Date(project.project_end_date) >= currentDate);
  
    return (
      <div className="legend">
        <div className="legend-item">
          <span className="color-box" style={{ background: '#2a56c6' }}></span>
          <span className="legend-text">Completed Projects</span>
        </div>
        <div className="legend-item">
          <span className="color-box" style={{ background: '#db4437' }}></span>
          <span className="legend-text">Ongoing Projects</span>
        </div>
      </div>
    );
  };
*/}

  return (
    <>
      <Modal show={show} onHide={handleClose} className="chart-modal">
        <Modal.Header closeButton>
          <Modal.Title>Employee History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-div">
            <label>Filter by Project: </label>
            <select value={selectedProject} onChange={handleProjectFilterChange}>
              <option value="all">All Projects</option>
              {employeeHistory.map((project) => {
                if (project.ID !== 'Projects') {
                  return (
                    <option key={projLov[project.ID].project_name } value={projLov[project.ID].project_name }>
                      {projLov[project.ID].project_name }
                    </option>
                  );
                }
              })}
            </select>
          </div>
          {employeeHistory.length >= 1 ? (
            <>
          {/* <Legend /> */}
              <Chart
                chartType="Gantt"
                width="100%"
                height="400px"
                data={data}
                options={{
                  gantt: {
                    trackHeight: 30,
                  },
                }}
              />
            </>
          ) : (
            <h4>No Projects Updated</h4>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeHistoryModal;
