import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import logo from './images/logo-grey.png'
import { Link} from 'react-router-dom';


export default function Header({signOut}) {
    return (
        <Container fluid className='Header'>
            <Navbar className="P_navbar" bg="white" variant="light"  style={{height:"100px"}}>
                <Container fluid>
                    <Navbar.Brand href="#home">
                      <Link to="/" className="navbar-brand">
                        <img style={{width:"200px" ,height:"70px", marginLeft:"10px"}} 
                            alt=""
                            src={logo}
                        />{' '}
                      </Link>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <Button variant="warning" onClick={() => Auth.signOut()} className="btn-sm" id='P_signout' style={{paddingTop: '0.3rem', paddingBottom: '0.3rem' ,marginRight:'50px'}}>Sign out</Button>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Container>
    );
}