
import { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Container } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Auth } from '@aws-amplify/auth';
import Badge from 'react-bootstrap/Badge';
import { BsDownload } from 'react-icons/bs';
import { exportCSV, exportProjectMemberPDF, exportSOWPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports
import { saveAs } from 'file-saver';
import SOWForm from "./SOWForm";
import moment from 'moment';

export default function ProjectForm({ selProjectLead, setSelProjectLead, projectMembers, setProjectMembers,selProjectType, setSelProjectType,type, show, setShow, fetchProjects, selProject, viewAlert, empLov,clientLov ,setSelClient,selClient,SOWs,setSOWs,SOWLov,projLov  }) {

    const projectIDField = useRef();
    const projectNameField = useRef();
    const projectTechnologyField = useRef();
    const projectleadField = useRef();
    const startDateField = useRef();
    const endDateField = useRef();
    const projectMemberField = useRef();
    const clientNameField = useRef();
    const projecttypeField = useRef();
    const memberstartDateField = useRef();
    const memberendDateField = useRef();
    const memberBillableField = useRef();
    const SOWsField = useRef();
    const SOWStartDateField = useRef();
    const SOWEndDateField = useRef();
    const projectDescriptionField = useRef();
    const projectRemarksField = useRef();

    const [processing, setProcessing] = useState(false);
    const [showProjectLeadWarning, setShowProjectLeadWarning] = useState(false);
    const [isProjectLeadSelected, setIsProjectLeadSelected] = useState(false);
    const [isClientNameSelected, setIsClientNameSelected] = useState(false);

    const [showProjectNameWarning, setShowProjectNameWarning] = useState(false);
    const [showProjectIDWarning, setShowProjectIDWarning] = useState(false);
    const [showClientNameWarning, setShowClientNameWarning] = useState(false);

 

    const modalTitle = (type === 'update' ? 'Update' : 'Create') + ' project';

    const submitTitle = type === 'update' ? 'Update' : 'Create';
    const cancelTitle = type === 'view' ? 'Close' : 'Cancel';

    const [selProjMember, setSelProjMember] = useState([]);
    const [selProjMemberBillable, setSelProjMemberBillable] = useState("non-billable");

    //const [selProjType, setSelProjType] = useState([]);

    const handleClose = () => {
        setShow(false);
        setShowProjectIDWarning(false);
        setShowProjectNameWarning(false);
        setShowProjectLeadWarning(false);
        setShowClientNameWarning(false);
      };
      
    //populate emp lov    
    const memberOptions = [];
    for (let empID in empLov) {
        let lovMember = { 'ID': empID, 'display_key': empLov[empID].display_key }
        memberOptions.push(lovMember);
    }

        //populate client lov    
        const clientOptions = [];
        for (let clientID in clientLov) {
            let lovClient= { 'ID': clientID, 'display_key': clientLov[clientID].display_key }
            clientOptions.push(lovClient);
        }

    const formattedCurrentDate = new Date().toISOString().slice(0, 10); // Get current date in yyyy-mm-dd format

    const addMember = () => {
        if (selProjMember.length > 0) {

          const selectedMember = selProjMember[0];
          const selectedMemberBillable = selProjMemberBillable;

          if (!projectMembers.some(member => member.ID === selectedMember.ID)) {
            const newMember = {
              ID: selectedMember.ID,
              startDate: memberstartDateField.current.value,
              endDate: memberendDateField.current.value,
              billable: selectedMemberBillable,
              status: (memberendDateField.current.value >= formattedCurrentDate ||
                       memberendDateField.current.value==='') ? 'active' : 'in-active'
            };
            setProjectMembers([...projectMembers, newMember]);
            setSelProjMember([]);
            setSelProjMemberBillable("non-billable");
            projectMemberField.current.clear();
            memberstartDateField.current.value = '';
            memberendDateField.current.value = '';
            memberBillableField.current.value= '';
        }
        }
      };

      const deleteMember = (empID) => {
        const newMembers = projectMembers.filter(member => member.ID !== empID);
        setProjectMembers(newMembers);
      };

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    const onSubmit = (ev) => {
        ev.preventDefault();
        
        console.log('projectpage',projectMembers)
        if (selProjectLead.length === 0 || projectNameField.current.value===''||selClient.length===0) {

            if (projectIDField.current.value===''){
                setShowProjectIDWarning(true)
    
            }
            if( projectNameField.current.value===''){
                setShowProjectNameWarning(true);
    
            }
            if(selProjectLead.length === 0 ){
            setShowProjectLeadWarning(true);

            }
            if(selClient.length === 0 ){
              setShowClientNameWarning(true);
          }
          }else{
            const id = type === 'new' ? projectNameField.current.value +  Auth.user.attributes.email +  moment().format('YYYY/MM/DD, HH:mm:ss A')
            : selProject.ID ;

        setProcessing(true);
        const newProject = {
            "ID": id,
            "project_id": projectIDField.current.value,
            "project_name": projectNameField.current.value,
            "project_technology": projectTechnologyField.current.value,
            "project_lead": selProjectLead[0]?.ID,
            "project_start_date": startDateField.current.value,
            "project_team_member": projectMembers,
            "project_end_date": endDateField.current.value,
            'client_name': selClient[0]?.ID,
            "project_type" :selProjectType[0]?.Type,
            "sow": SOWs,
            "project_description": projectDescriptionField.current.value,
            "user_email":Auth.user.attributes.name,
            "project_remarks": projectRemarksField.current.value
          };


        console.log(newProject)
        newProject["search_name"] = (newProject["project_name"]).toLowerCase();

        (async () => {
            let accessToken = await getAccessToken();
            const rawResponse = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                body: JSON.stringify(newProject)
            });
            const content = await rawResponse.json();
            console.log(content)
            if (type === 'update' || type === 'new') {
                fetchProjects();
            }
            setProcessing(false);
            handleClose();
            const alertMessage = {
                header: "Project " + (type === 'update' ? 'updated' : 'created'),
                body: "Project " + newProject["project_id"] + " is successfully " + (type === 'update' ? 'updated' : 'created')
            }
            viewAlert(alertMessage);
        })();
    }
    }

    const handleProjectIDChange = (event) => {
        const ID = event.target.value;
        if (ID.length > 0) {
         setShowProjectIDWarning(false); // Clear the warning
      } else {
         setShowProjectIDWarning(true); // Show warning when project name is not filled
         }
       };

     const handleProjectNameChange = (event) => {
       const name = event.target.value;
       if (name.length > 0) {
        setShowProjectNameWarning(false); // Clear the warning
     } else {
        setShowProjectNameWarning(true); // Show warning when project name is not filled
        }
      };

      const handleClientNameChange = (selectedClient) => {

        if (selectedClient.length > 0) {
          setSelClient(selectedClient);
          setIsClientNameSelected(true); // Mark project lead as selected
          setShowClientNameWarning(false); // Clear the warning
        } else {
          setSelClient([]); // Reset project lead if no selection
          setIsClientNameSelected(false); // Mark project lead as not selected
          setShowClientNameWarning(true); // Show warning when project lead is not selected
        }
      };

    const handleProjectLeadChange = (selectedLead) => {

        if (selectedLead.length > 0) {
          setSelProjectLead(selectedLead);
          setIsProjectLeadSelected(true); // Mark project lead as selected
          setShowProjectLeadWarning(false); // Clear the warning
        } else {
          setSelProjectLead([]); // Reset project lead if no selection
          setIsProjectLeadSelected(false); // Mark project lead as not selected
          setShowProjectLeadWarning(true); // Show warning when project lead is not selected
        }
      };
      const handleProjectTypeChange= (selectedType) => {
          setSelProjectType(selectedType);
      };

      
      const updateMemberDates = (empID, startDate, endDate, billable,status) => {
        const updatedMembers = projectMembers.map(member => {
          if (member.ID === empID) {
            return {
              ...member,
              startDate,
              endDate,
              billable,
              status: endDate >= formattedCurrentDate || endDate === '' ? 'active' : 'in-active'

            };
          }
          return member;
        });
        setProjectMembers(updatedMembers);
      };

      const [showCurrentMembers, setShowCurrentMembers] = useState(false);

      const toggleShowCurrentMembers = () => {
        setShowCurrentMembers(!showCurrentMembers);
      };

      const filteredMembers = showCurrentMembers
        ? projectMembers.filter(member => (member.endDate >= formattedCurrentDate || member.endDate===''))
        : projectMembers;

        const exportProjectMemberData = (e) => {
          // Export as CSV
          if(e==='csv'){
            if (filteredMembers.length>0){
          const csvData = exportCSV(filteredMembers,empLov,selProject);
          const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          saveAs(csvBlob, 'projectmembers.csv');
          //document.getElementById("export_select").value = "";
            }
      }
         else{
          // Export as PDF
          if (filteredMembers.length>0){
            const pdfData = exportProjectMemberPDF(filteredMembers, empLov,selProject);
            const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
            saveAs(pdfBlob, selProject.project_name +'-project_members.pdf');
          //document.getElementById("export_select").value = ""; 
          }
      }
        };

        const [selSOW, setSelSOW] = useState([]);
    
        //populate emp lov    
     const options = [];
     for (let SOWID in SOWLov) {
         let lovSOW = { 'ID': SOWID, 'display_key': SOWLov[SOWID].display_key }
         options.push(lovSOW);
     }
     const addSOW = () => {
      if (selSOW.length > 0) {
          const SOW = selSOW[0];
          if (!(SOWs.includes(SOW.ID))) {
              SOWs.push(SOW.ID);
              setSOWs([...SOWs]);
              SOWsField.current.clear();
              console.log(SOWs,selSOW)
          }
      }
  }
 
        const deleteSOW = (SOWID) => {
         const newSOWs = SOWs.filter(SOW => SOW !== SOWID);
         setSOWs(newSOWs);
       };
 
 
       const [showCurrentSOWs, setShowCurrentSOWs] = useState(false);
 
       const toggleShowCurrentSOWs = () => {
         setShowCurrentSOWs(!showCurrentSOWs);
       };
 
       const filteredSOWs = showCurrentSOWs
         ? SOWs.filter(SOW => (SOWLov[SOW].sow_end_date >= formattedCurrentDate || SOWLov[SOW].sow_end_date ===''))
         : SOWs;
 
         const exportSOWData = (e) => {
          // Export as CSV
          if(e==='csv'){
            if (filteredSOWs.length>0){
          const csvData = exportCSV(filteredSOWs,SOWLov,selProject);
          const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          saveAs(csvBlob, 'SOWs.csv');
          //document.getElementById("export_select").value = "";
            }
      }
         else{
          // Export as PDF
          if (filteredSOWs.length>0){
            const pdfData = exportSOWPDF(filteredSOWs, SOWLov,selProject);
            const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
            saveAs(pdfBlob, selProject.project_name +'-SOWs.pdf');
          //document.getElementById("export_select").value = ""; 
          }
      }
        };

         const navigate = useNavigate();

         function RedirectToSearchEmp(empId) {
          navigate(`/projecttracker/emp?empId=${empId}`);
        }

         function RedirectToSearchSOW(sowId) {
          navigate(`/projecttracker/sow?sowId=${sowId}`);
        }

    return (

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h5>{modalTitle}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>ID *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Project ID"
                            ref={projectIDField}
                            defaultValue={selProject.project_id}
                            disabled={type == 'view' ? true : false}
                            onChange={handleProjectIDChange}
                        />
                        { showProjectIDWarning && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please enter a project ID.
                          </div>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Name *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Project Name"
                            ref={projectNameField}
                            defaultValue={selProject.project_name}
                            disabled={type === 'view' ? true : false}
                            onChange={handleProjectNameChange}
                        />
                          {showProjectNameWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a project name.
                            </div>
                           )}

                    </Form.Group>

                    <Form.Group className="mb-3"  controlId="validationCustom01">
                        <Form.Label>Client Name *</Form.Label>

<Typeahead
      id="Client-name-typeahead"
      labelKey="display_key"
      options={clientOptions}
      onChange={handleClientNameChange}
      selected={selClient}
      placeholder="Search client"
      ref={clientNameField}
      disabled={type === "view" ? true : false}
    />
                          {showClientNameWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a client name.
                            </div>
                           )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom02">
  <Form.Label>Project Description</Form.Label>
  <Form.Control
    as="textarea"
    rows={3}
    placeholder="Project Description"
    ref={projectDescriptionField}
    defaultValue={selProject.project_description}
    disabled={type === 'view' ? true : false}
  />
</Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                            <Form.Label>Technology</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Project Technology"
                                ref={projectTechnologyField}
                                defaultValue={selProject.project_technology}
                                disabled={type === 'view' ? true : false}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Lead *</Form.Label>
                            <Typeahead
                                id="project-lead-typeahead"
                                labelKey="display_key"
                                options={memberOptions}
                                onChange={handleProjectLeadChange}
                                placeholder="Search employee"
                                selected={selProjectLead}
                                ref={projectleadField}
                                disabled={type === 'view' ? true : false}
                            />
                              {!isProjectLeadSelected && showProjectLeadWarning && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                  Please select a project lead.
                                </div>
                               )}

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                        <Form.Label>Project Type </Form.Label>
                            <Typeahead
                                id="project-lead-typeahead"
                                labelKey="display_key"
                                options={[{Type:'Report Conversion',display_key: 'Report Conversion'},
                                          {Type:'Development',display_key: 'Development'}
                                       ]}
                                onChange={setSelProjectType}
                                placeholder="Search project type"
                                selected={selProjectType}
                                ref={projecttypeField}
                                disabled={type === 'view' ? true : false}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" ref={startDateField} defaultValue={selProject.project_start_date} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type="date" ref={endDateField} defaultValue={selProject.project_end_date} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Row>
                        <Container>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <p>Project Members <Badge bg="warning" text="dark">
                                {projectMembers.length}
                            </Badge></p>
                            <div className="d-flex">
                            <Button
        variant="primary"
        className="me-2"
        onClick={toggleShowCurrentMembers}
      >
        {showCurrentMembers ? 'Show All Members' : 'Show Current Members'}
      </Button>
      <button type="button" className="btn btn-link" onClick={exportProjectMemberData}  disabled={!(type === 'view')}>
      <BsDownload /> Download PDF
    </button>
  </div>
    </div>
                            <Table hover className='table-sm m-2'>
                            <tbody>
                            <tr>
  <th>ID</th>
  <th>Name</th>
  <th>Start Date</th>
  <th>End Date</th>
  <th>Billable</th> {/* New column */}
  <th></th>
</tr>
{filteredMembers.map(member => (
  //<tr key={member.ID} className={(member.status==='active') ? '' : 'non-current-member'}

  <tr key={member.ID} className={(member.endDate >= formattedCurrentDate || member.endDate === '') ? '' : 'non-current-member'}
  >
  <td onClick={() => RedirectToSearchEmp(empLov[member.ID].first_name+' '+empLov[member.ID].last_name)}>{member.ID}</td>
    <td onClick={() => RedirectToSearchEmp(empLov[member.ID].first_name+' '+empLov[member.ID].last_name)}>{empLov[member.ID].first_name} {empLov[member.ID].last_name}</td>
    <td>
      <Form.Control
        type="date"
        value={member.startDate}
        disabled={type === 'view'}
        onChange={(e) => updateMemberDates(member.ID, e.target.value, member.endDate, member.billable)}
      />
    </td>
    <td>
      <Form.Control
        type="date"
        value={member.endDate}
        disabled={type === 'view'}
        onChange={(e) => updateMemberDates(member.ID, member.startDate, e.target.value, member.billable)}
      />
    </td>
    <td>
  <Form.Control
    as="select"
    value={member.billable}
    disabled={type === 'view'}
    onChange={(e) => updateMemberDates(member.ID, member.startDate, member.endDate, e.target.value)}
    style={{ textAlign: 'center' }}
  >
    <option value="billable">Billable</option>
    <option value="non-billable">Non-billable</option>
    <option value="buffer">Buffer</option>
    <option value="billable-named">Billable-named</option>
    <option value="billable-unnamed">Billable-unnamed</option>
  </Form.Control>
</td>
      <td>
        <button
          disabled={type === 'view'}
          type="button"
          className="btn btn-outline-danger btn-sm"
          onClick={() => deleteMember(member.ID)}
        >
          <i className="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  ))}
</tbody>

                            </Table>
                        </Container>
                    </Row>
                    <Row className="mb-3">
  <Form.Group as={Col} md="4" controlId="validationCustom03">
    <Typeahead
      id="project-members-typeahead"
      labelKey="display_key"
      options={memberOptions}
      onChange={setSelProjMember}
      placeholder="Search employee"
      ref={projectMemberField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
  <Form.Group as={Col} md="3" controlId="validationCustom04">
    <Form.Control
      type="date"
      placeholder="Start Date"
      ref={memberstartDateField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
  <Form.Group as={Col} md="3" controlId="validationCustom05">
    <Form.Control
      type="date"
      placeholder="End Date"
      ref={memberendDateField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
  <Form.Group as={Col} md="2" controlId="validationCustom05">
    <Form.Control
      as="select"
      value={selProjMemberBillable}
      ref={memberBillableField}
      onChange={(e) => setSelProjMemberBillable(e.target.value)}
      disabled={type === "view" ? true : false}
    >
      <option value="billable">Billable</option>
      <option value="non-billable">Non-billable</option>
      <option value="buffer">Buffer</option>
    </Form.Control>
  </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04" style={{marginTop:'7px'}}>
                            <Button variant="primary" onClick={addMember} className="me-2 mb-2" id='button-addon1' disabled={processing || (type === 'view' ? true : false)}>Add Member</Button>

                        </Form.Group>
                    </Row>
                    <Row>
                        <Container>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <p>SOW's <Badge bg="warning" text="dark">
                                {SOWs.length}
                            </Badge>
                            </p>
                            <div className="d-flex">
                            <Button
        variant="primary"
        className="me-2"
        onClick={toggleShowCurrentSOWs}
      >
        {showCurrentSOWs ? 'Show All SOWs' : 'Show Current SOWs'}
      </Button>
      <button type="button" className="btn btn-link" onClick={exportSOWData} disabled={!(type === 'view')}>
          <BsDownload /> Download PDF
        </button>
    </div>
    </div>
                            <Table hover className='table-sm m-2'>
                            <tbody>
                            <tr>
  <th>SOW Number</th>
  <th>SOW Name</th>
  <th>Start Date</th>
  <th>End Date</th>
  <th></th>
</tr>
{filteredSOWs.map(SOW => (
  <tr key={SOW} className={(SOWLov[SOW].sow_end_date ==='' || SOWLov[SOW].sow_end_date  >= formattedCurrentDate) ? '' : 'non-current-member'}
  >
  <td onClick={() => RedirectToSearchSOW(SOWLov[SOW].sow_name)}>{SOWLov[SOW].sow_number }</td>
    <td onClick={() => RedirectToSearchSOW(SOWLov[SOW].sow_name)}>{SOWLov[SOW].sow_name }</td>
    <td onClick={() => RedirectToSearchSOW(SOWLov[SOW].sow_name)}>
      <Form.Control
        type="date"
        value={SOWLov[SOW].sow_start_date}
        disabled={true}
      />
    </td>
    <td onClick={() => RedirectToSearchSOW(SOWLov[SOW].sow_name)}>
      <Form.Control
        type="date"
        value={SOWLov[SOW].sow_end_date}
        disabled={true}
      />
    </td>
      <td>
        <button
          disabled={type === 'view'}
          type="button"
          className="btn btn-outline-danger btn-sm"
          onClick={() => deleteSOW(SOW)}
        >
          <i className="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  ))}
</tbody>

                            </Table>
                        </Container>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
    <Typeahead
      id="SOWs-typeahead"
      labelKey="display_key"
      options={options}
      onChange={setSelSOW}
      placeholder="Search SOW Name"
      ref={SOWsField}
      disabled={type === "view" ? true : false}
    />
  </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04" style={{marginTop:'7px'}}>
                            <Button variant="primary" onClick={addSOW} className="me-2 mb-2" id='button-addon1' disabled={processing || (type === 'view' ? true : false)}>Add SOW</Button>

                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="validationCustom02">
                      <Form.Label>Project Remarks</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Project Remarks"
                          ref={projectRemarksField}
                          defaultValue={selProject.project_remarks}
                          disabled={type === 'view' ? true : false}
                        />
                    </Form.Group>
                    {type === 'view' ? <></> : <Button variant="primary" type="submit" className='me-2 mb-2' id='button-addon1' disabled={processing}>{submitTitle}</Button>}
                    <Button variant="secondary" onClick={handleClose} className="me-2 mb-2" disabled={processing}>{cancelTitle}</Button>
                </Form>

            </Modal.Body>
        </Modal >
    );
}