
import { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {Auth} from '@aws-amplify/auth';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Container } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import { exportCSV, exportSOWPDF,exportProjectsPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports
import { saveAs } from 'file-saver';

export default function ClientForm({ type, show, setShow, fetchClients, selClient,SOWs,setSOWs, viewAlert,SOWLov,setProjectDetails,projectDetails,projLov }) {

    const nameField = useRef();
    const addressStreetField = useRef();
    const cityField = useRef();
    const stateField = useRef();
    const zipField = useRef();
    const SOWsField = useRef();
    const SOWStartDateField = useRef();
    const SOWEndDateField = useRef();

    const locationField = useRef();
    const totalValueField = useRef();
    const billedValueField = useRef();
    const currentBalanceField = useRef();
    const clientManagerField = useRef();
    const clientManagerPhoneField = useRef();
    const clientAccountsContactField = useRef();
    const clientAccountsPhoneField = useRef();
    const signingAuthorityField = useRef();
    const signingAuthorityPhoneField = useRef();
    const poField = useRef();
    const applicationNameInvoicingField = useRef();
    const clientRemarksField = useRef();

    const [processing, setProcessing] = useState(false);
    const [showClientNameWarning, setShowClientNameWarning] = useState(false);


    const modalTitle = (type === 'update' ? 'Update' : 'Create') + ' client';

    const submitTitle = type === 'update' ? 'Update' : 'Create';
    const cancelTitle = type === 'view' ? 'Close' : 'Cancel';


    const handleClose = () =>{

      setShow(false);
      setShowClientNameWarning(false);

    }  

    const getAccessToken = async() => {return (await Auth.currentSession()).getAccessToken().getJwtToken()}

    const onSubmit = (ev) => {

        ev.preventDefault();
        if(nameField.current.value===''){
            setShowClientNameWarning(true);
        }
        else{
        const id = type === 'new' ? nameField.current.value +  Auth.user.attributes.email +  moment().format('YYYY/MM/DD, HH:mm:ss A')
        : selClient.ID ;
        
        setProcessing(true);

        const newClient = {
            "ID": id,
            "client_name":nameField.current.value,
            "address_street": addressStreetField.current.value,
            "address_city": cityField.current.value,
            "address_state": stateField.current.value,
            "zipcode": zipField.current.value,
            "sow": SOWs,
            "project_details":projectDetails,
            "location": locationField.current.value,
            "total_value": totalValueField.current.value,
            "billed_value": billedValueField.current.value,
            "current_balance": currentBalanceField.current.value,
            "client_manager": clientManagerField.current.value,
            "client_manager_phone": clientManagerPhoneField.current.value,
            "client_accounts_contact": clientAccountsContactField.current.value,
            "client_accounts_phone": clientAccountsPhoneField.current.value,
            "signing_authority_name": signingAuthorityField.current.value,
            "signing_authority_phone": signingAuthorityPhoneField.current.value,
            "po": poField.current.value,
            "application_name_invoicing": applicationNameInvoicingField.current.value,
            "user_email":Auth.user.attributes.name,
            "client_remarks": clientRemarksField.current.value
        };

        newClient["search_name"] = (newClient["client_name"]).toLowerCase();

        (async () => {
            let accessToken = await getAccessToken();
            const rawResponse = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':accessToken
                },
                body: JSON.stringify(newClient)
            });
            const content = await rawResponse.json();
            if (type === 'update' ||type === 'new') {
                fetchClients();
            }
            setProcessing(false);
            handleClose();
            const alertMessage = {
                header: "Client " + (type === 'update' ? 'updated' : 'created'),
                body: "Client " + newClient["client_name"] + " is successfully " + (type === 'update' ? 'updated' : 'created')
            }
            viewAlert(alertMessage);
        })();
      }
    }

    const handleClientNameChange = (event) => {
        const name = event.target.value;
   
        if (name.length > 0) {
         setShowClientNameWarning(false); // Clear the warning
      } else {
         setShowClientNameWarning(true); // Show warning when project name is not filled
         }
       };

       const formattedCurrentDate = new Date().toISOString().slice(0, 10); // Get current date in yyyy-mm-dd format
   
       //populate emp lov    
    const options = [];
    for (let SOWID in SOWLov) {
        let lovSOW = { 'ID': SOWID, 'display_key': SOWLov[SOWID].display_key }
        options.push(lovSOW);
    }

      const [showCurrentSOWs, setShowCurrentSOWs] = useState(false);

      const toggleShowCurrentSOWs = () => {
        setShowCurrentSOWs(!showCurrentSOWs);
      };

      const filteredSOWs = showCurrentSOWs
        ? SOWs.filter(SOW => (SOWLov[SOW].sow_end_date >= formattedCurrentDate || SOWLov[SOW].sow_end_date===''))
        : SOWs;

        const exportSOWData = (e) => {
          // Export as CSV
          if(e==='csv'){
            if (filteredSOWs.length>0){
          const csvData = exportCSV(filteredSOWs,SOWLov,selClient);
          const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          saveAs(csvBlob, 'SOWs.csv');
          //document.getElementById("export_select").value = "";
            }
      }
         else{
          // Export as PDF
          if (filteredSOWs.length>0){
            const pdfData = exportSOWPDF(filteredSOWs, SOWLov,selClient);
            const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
            saveAs(pdfBlob, selClient.client_name +'-SOWs.pdf');
          //document.getElementById("export_select").value = ""; 
          }
      }
        };

        
        const exportProjectsData = (e) => {
          // Export as CSV
          if(e==='csv'){
            if (filteredProjects.length>0){
          const csvData = exportCSV(filteredProjects,projLov,selClient);
          const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          saveAs(csvBlob, 'projects.csv');
          //document.getElementById("export_select").value = "";
            }
      }
         else{
          // Export as PDF
          if (filteredProjects.length>0){
            const pdfData = exportProjectsPDF(filteredProjects, projLov,selClient);
            const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
            saveAs(pdfBlob, selClient.client_name +'-Projects.pdf');
          //document.getElementById("export_select").value = ""; 
          }
      }
        };

        const [clientLov, setClientLov] = useState({});

        function fetchClientLov() {
          (async () => {
              let accessToken = await getAccessToken();
              const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client/clientlov', {
                  headers: new Headers({
                      'Authorization': accessToken
                  })
              });
              const result = await response.json();
              setClientLov(result);
          })();
      }
  
      useEffect(() => {
          fetchClientLov()
          //fetchSOWs()
      }, []);


const navigate = useNavigate();

function RedirectToSearchSOW(sowId) {
  navigate(`/projecttracker/sow?sowId=${sowId}`);
}

function RedirectToSearchProject(projectId) {
    navigate(`/projecttracker/project?projectId=${projectId}`);
  }
const [showCurrentProjects, setShowCurrentProjects] = useState(false);

const toggleShowCurrentProjects = () => {
  setShowCurrentProjects(!showCurrentProjects);
};

const filteredProjects = showCurrentProjects
  ? projectDetails.filter(project => (projLov[project].project_end_date >= formattedCurrentDate || projLov[project].project_end_date===''))
  : projectDetails;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h5>{modalTitle}</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Client Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            ref={nameField}
                            defaultValue={selClient.client_name}
                            disabled={type == 'view' ? true : false}
                            onChange={handleClientNameChange}
                        />
                          {showClientNameWarning && (
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                              Please enter a Client name.
                            </div>
                           )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address Street</Form.Label>
                        <Form.Control type="text" placeholder="Enter Address" ref={addressStreetField} defaultValue={selClient.address_street} disabled={type === 'view' ? true : false} />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" ref={cityField} defaultValue={selClient.address_city} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" placeholder="State" ref={stateField} defaultValue={selClient.address_state} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text" placeholder="Zipcode" ref={zipField} defaultValue={selClient.zipcode} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    {type==='new' || type==='update' ? null : (
                        <>
                    <Row>
                        <Container>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <p>SOW's <Badge bg="warning" text="dark">
                                {SOWs.length}
                            </Badge></p>
                            <div className="d-flex">
                            <Button
        variant="primary"
        className="me-2"
        onClick={toggleShowCurrentSOWs}
      >
        {showCurrentSOWs ? 'Show All SOWs' : 'Show Current SOWs'}
        </Button>
      <button type="button" className="btn btn-link" onClick={exportSOWData}  disabled={!(type === 'view')}>
      <BsDownload /> Download PDF
    </button>
  </div>
    </div>
                            <Table hover className='table-sm m-2'>
                            <tbody>
                            <tr>
  <th>SOW Number</th>
  <th>SOW Name</th>
  <th>Start Date</th>
  <th>End Date</th>
  <th></th>
</tr>
{filteredSOWs.map(SOW => (
  <tr key={SOW} className={(SOWLov[SOW].sow_end_date==='' || SOWLov[SOW].sow_end_date >= formattedCurrentDate) ? '' : 'non-current-member'}
  onClick={() => RedirectToSearchSOW(SOWLov[SOW].sow_name)}>
  <td>{SOWLov[SOW].sow_number}</td>
    <td>{SOWLov[SOW].sow_name}</td>
    <td>
      <Form.Control
        type="date"
        value={SOWLov[SOW].sow_start_date}
        disabled={true}
      />
    </td>
    <td>
      <Form.Control
        type="date"
        value={SOWLov[SOW].sow_end_date}
        disabled={true}
      />
    </td>

    </tr>
  ))}
</tbody>

                            </Table>
                        </Container>
                    </Row>
                    <Row>
                        <Container>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <p>Project's <Badge bg="warning" text="dark">
                                {projectDetails.length}
                            </Badge></p>
                            <div className="d-flex">
                            <Button
        variant="primary"
        className="me-2"
        onClick={toggleShowCurrentProjects}
      >
        {showCurrentProjects ? 'Show All Projects' : 'Show Current Projects'}
        </Button>
      <button type="button" className="btn btn-link" onClick={exportProjectsData}  disabled={!(type === 'view')}>
      <BsDownload /> Download PDF
    </button>
  </div>
    </div>
                            <Table hover className='table-sm m-2'>
                            <tbody>
                            <tr>
  <th>Project ID</th>
  <th>Project Name</th>
  <th>Start Date</th>
  <th>End Date</th>
  <th></th>
</tr>
{filteredProjects.map(project => (
  <tr key={project} className={(projLov[project].project_end_date==='' || projLov[project].project_end_date >= formattedCurrentDate) ? '' : 'non-current-member'}
  onClick={() => RedirectToSearchProject(projLov[project].project_name)}>
  <td>{projLov[project].project_id}</td>
    <td>{projLov[project].project_name}</td>
    <td>
      <Form.Control
        type="date"
        value={projLov[project].project_start_date}
        disabled={true}
      />
    </td>
    <td>
      <Form.Control
        type="date"
        value={projLov[project].project_end_date}
        disabled={true}
      />
    </td>

    </tr>
  ))}
</tbody>

                            </Table>
                        </Container>
                    </Row>
                    </>
                    )}
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>Total Value</Form.Label>
                            <Form.Control type="text" placeholder="Total Value" ref={totalValueField} defaultValue={selClient.total_value} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>Billed Value</Form.Label>
                            <Form.Control type="text" placeholder="Billed Value" ref={billedValueField} defaultValue={selClient.billed_value} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>Current Balance</Form.Label>
                            <Form.Control type="text" placeholder="Current Balance" ref={currentBalanceField} defaultValue={selClient.current_balance} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Client Manager</Form.Label>
                            <Form.Control type="text" placeholder="Client Manager" ref={clientManagerField} defaultValue={selClient.client_manager} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Client Manager Phone</Form.Label>
                            <Form.Control type="text" placeholder="Client Manager Phone" ref={clientManagerPhoneField} defaultValue={selClient.client_manager_phone} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Client Accounts Contact</Form.Label>
                            <Form.Control type="text" placeholder="Client Accounts Contact" ref={clientAccountsContactField} defaultValue={selClient.client_accounts_contact} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Client Accounts Phone</Form.Label>
                            <Form.Control type="text" placeholder="Client Accounts Phone" ref={clientAccountsPhoneField} defaultValue={selClient.client_accounts_phone} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Signing Authority Name</Form.Label>
                            <Form.Control type="text" placeholder="Signing Authority Name" ref={signingAuthorityField} defaultValue={selClient.signing_authority_name} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Signing Authority Phone</Form.Label>
                            <Form.Control type="text" placeholder="Signing Authority Phone" ref={signingAuthorityPhoneField} defaultValue={selClient.signing_authority_phone} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Location" ref={locationField} defaultValue={selClient.location} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                            <Form.Label>PO#</Form.Label>
                            <Form.Control type="text" placeholder="PO#" ref={poField} defaultValue={selClient.po} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Application Name for Invoicing</Form.Label>
                            <Form.Control type="text" placeholder="Application Name for Invoicing" ref={applicationNameInvoicingField} defaultValue={selClient.application_name_invoicing} disabled={type === 'view' ? true : false} />

                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="validationCustom02">
                      <Form.Label>Client Remarks</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Client Remarks"
                          ref={clientRemarksField}
                          defaultValue={selClient.client_remarks}
                          disabled={type === 'view' ? true : false}
                        />
                    </Form.Group>

                    {type === 'view' ? <></> : <Button variant="primary" type="submit" className='me-2 mb-2' id='button-addon1' disabled={processing}>{submitTitle}</Button>}
                    <Button variant="secondary" onClick={handleClose} className="me-2 mb-2" disabled={processing}>{cancelTitle}</Button>
                </Form>
            </Modal.Body>
        </Modal >
    );
}
