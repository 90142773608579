import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Auth } from '@aws-amplify/auth';
import { AiOutlineClose } from 'react-icons/ai';

export default function DeleteRecord({ show, setShow, deleteData, fetchdata,setIsSubmitting, viewAlert }) {

    const handleClose = () => setShow(false);

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    const deleteRecord = () => {
        setIsSubmitting(true);
        (async () => {
            let myHeaders = new Headers();
            let accessToken = await getAccessToken();

            const data = {
                "email": deleteData.email,
                "sort_key": deleteData.sort_key
              }

            myHeaders.append("Content-Type", "application/json");
            const response = await fetch('https://qy2plq6yej.execute-api.us-east-1.amazonaws.com/dev/delete_tasksheet_record',
                {
                    method: 'DELETE',
                    headers: new Headers({
                        'Authorization': accessToken
                    }),
                    body: JSON.stringify(data),
                });
            const result = await response.json();
            setIsSubmitting(false);
            handleClose();
            fetchdata();
            const alertMessage = {
                header: "Record deleted",
                body: "Selected record is successfully deleted"
            }
            viewAlert(alertMessage);
        })();

    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title><h3>Delete Record</h3></Modal.Title>
                    <button className="close" onClick={handleClose}>
                     <AiOutlineClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    Are you sure want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="btn-sm">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={deleteRecord} className="btn-sm" id='button-addon1' >Delete</Button>
                </Modal.Footer>
            </Modal>
            {show && <div className="overlay"></div>}
        </>
    );
}