import './App.css';
import React, { useState, useEffect } from 'react';
//import './ProjectTrackerEmp.css'
import { Container } from 'react-bootstrap';
import Posts from './components/Posts';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Row from 'react-bootstrap/Row';
import Home from './components/Home';

import EmpHome from './components/Emp/EmpHome';
import VendorHome from './components/Vendor/VendorHome';
import ClientHome from './components/Client/ClientHome';
import ProjectHome from './components/Project/ProjectHome';
import SOWHome from './components/SOW/SOWHome';
import ReportHome from './components/Reports/ReportHome';
//import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route, Navigate, } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { checkUserRole } from './RoleCheck';


function App() {
 const [isLoading, setIsLoading] = useState(false);
/*
  useEffect(() => {
    setIsLoading(true);
    checkUserRole(); // Call the checkUserRole function

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
*/
  return (
    <>
    {/*}
    <Helmet>
      <link rel="stylesheet" href="/bootstrap/dist/css/bootstrap.min.css" />  This file is referring to public/bootstrap 
    </Helmet> 
    */}
    {isLoading ? <>
      <div id="loader-container">
      <div className="loader"></div>
    </div> </> : (
        <>
             {/* {isLoading ? null : (
                 */}
          <Header />
          <Container fluid className='App'>
          

              <Row className="flex-nowrap">
                <Sidebar className="sidebar" />
                <div className="col py-3">
                  <Routes>
                    <Route path="/emp" element={<EmpHome />} />
                    <Route path="/vendor" element={<VendorHome />} />
                    <Route path="/client" element={<ClientHome />} />
                    <Route path="/project" element={<ProjectHome />} />
                    <Route path="/sow" element={<SOWHome />} />
                    <Route path="/*" element={<EmpHome />} />
                    <Route path="/reports" element={<ReportHome />} />
                  </Routes>
                </div>
              </Row>

           
          </Container>
        </> 
   
      )} 
    </>
 );
}

export default App;
