import { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import ClientDelete from "./ClientDelete";
import ClientForm from "./ClientForm";
import Card from 'react-bootstrap/Card';
import 'bootstrap-icons/font/bootstrap-icons.css'
import AlertModal from "../AlertModal";
import { Auth } from '@aws-amplify/auth';
import { saveAs } from 'file-saver';
import { exportCSV,exportXLSX, exportPDF } from './exportUtils'; // Create these utility functions to handle CSV and PDF exports

export default function ClientHome() {
    const [clients, setClients] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searching, setSearching] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();

    const [selClient, setSelClient] = useState({});

    //AlertModal
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});

    //DeleteModal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //Client Form Modal
    const [showClientModal, setShowClientModal] = useState(false);
    const [clientModalType, setClientModalType] = useState("new");

    const [SOWs, setSOWs] = useState([]);
    const [projectDetails, setProjectDetails] = useState([]);

    const [SOWLov, setSOWLov] = useState({});
    const [projLov, setProjLov] = useState({});

    const getAccessToken = async () => { return (await Auth.currentSession()).getAccessToken().getJwtToken() }

    function fetchSOWLov() {
        (async () => {
          setSearching(true);
            let accessToken = await getAccessToken();
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/sow/sowlov', {
                headers: new Headers({
                    'Authorization': accessToken
                })
            });
            const result = await response.json();
            setSOWLov(result);
            setSearching(false);
        })();
    }

    function fetchProjLov() {
      (async () => {
        setSearching(true);
          let accessToken = await getAccessToken();
          const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/project/projlov', {
              headers: new Headers({
                  'Authorization': accessToken
              })
          });
          const result = await response.json();
          setProjLov(result);
          setSearching(false);
      })();
  }

    useEffect(() => {
        fetchSOWLov()
        fetchClients()
        fetchProjLov()
    }, []);

    function fetchClients(searchStr) {
        (async () => {
            setSearching(true);
            setSearchQuery(searchStr ? searchStr : searchQuery);
            let myHeaders = new Headers();
            let accessToken = await getAccessToken();
            myHeaders.append("Content-Type", "application/json");
            const response = await fetch('https://trt9yhb0xj.execute-api.us-east-1.amazonaws.com/dev/client?searchName=' + (searchStr ? searchStr.toLowerCase() : searchQuery.toLowerCase()),
                {
                    headers: new Headers({
                        'Authorization': accessToken
                    })
                }
            );
            const result = await response.json();
            result.sort((a, b) => a.client_name.localeCompare(b.client_name)); // Sort the fetched data by first_name
            setClients(result);
            setSearching(false);
        })();
    }

    function viewAlert(alertMessage) {
        setAlertMessage(alertMessage);
        setShowAlertModal(true);
    }

    function newClient() {
        setClientModalType("new");
        setSelClient({});
        setSOWs([]);
        setProjectDetails([]);
        setShowClientModal(true);
    }

    function updateClient(client) {
        setClientModalType("update");
        setSelClient(client);
        setSOWs(client.sow==='' ? [] : client.sow);
        setProjectDetails(client.project_details==='' ? [] : client.project_details);
        setShowClientModal(true);
        console.log(client.project_details ? client.project_details :[]);
    }

    function viewClient(client) {
        setClientModalType("view");
        setSelClient(client);
        setSOWs(client.sow==='' ? [] : client.sow);
        setProjectDetails(client.project_details==='' ? [] : client.project_details);
        console.log(client)
        setShowClientModal(true);
    }


    function deleteClient(client) {
        setSelClient(client);
        setShowDeleteModal(true);
    }

    const [viewMode, setViewMode] = useState('table');

    const toggleViewMode = () => {
      setViewMode(prevMode => (prevMode === 'card' ? 'table' : 'card'));
    };

    const exportData = (e) => {
      // Export as CSV
      if(e==='csv'){
      const csvData = exportCSV(clients,SOWLov,projLov);
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(csvBlob, 'clients.csv');
      document.getElementById("export_select").value = "";
  }
  else if(e=='xlsx'){
    exportXLSX(clients,SOWLov,projLov)
    .then((xlsxBuffer) => {
      const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'clients.xlsx');
      document.getElementById("export_select").value = "";
    })
    .catch((error) => {
      console.error('Error exporting XLSX:', error);
    });
   }
     else{
      /* Export as PDF
      const pdfData = exportPDF(clients);
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      saveAs(pdfBlob, 'clients.pdf');
      document.getElementById("export_select").value = ""; */
  }
    };

    const [sortOrder, setSortOrder] = useState("asc");
    const [sortColumn, setSortColumn] = useState("");

    const handleSort = (column) => {
      if (sortColumn === column) {
        // If the same column is clicked, toggle the sorting order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // If a different column is clicked, set it as the new sort column
        setSortColumn(column);
        setSortOrder("asc");
      }
    };

    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        if (sortOrder === "asc") {
          return <i className="bi bi-caret-up-fill"></i>; // Display the caret-up icon
        } else {
          return <i className="bi bi-caret-down-fill"></i>; // Display the caret-down icon
        }
      } else {
        return null; // Don't display any icon if the column is not the current sort column
      }
    };

    // Add this code inside the component before rendering the table
let sortedClients = [...clients];
if (sortColumn && sortOrder) {
sortedClients.sort((a, b) => {
  const columnA = a[sortColumn];
  const columnB = b[sortColumn];
  if (columnA < columnB) {
    return sortOrder === "asc" ? -1 : 1;
  }
  if (columnA > columnB) {
    return sortOrder === "asc" ? 1 : -1;
  }
  return 0;
});
}

    return (
        <>
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} message={alertMessage} />
            <ClientDelete show={showDeleteModal} setShow={setShowDeleteModal} selClient={selClient} fetchClients={fetchClients} viewAlert={viewAlert} />
            <ClientForm type={clientModalType} show={showClientModal} setShow={setShowClientModal} selClient={selClient} fetchClients={fetchClients} SOWs={SOWs} setSOWs={setSOWs} viewAlert={viewAlert} SOWLov={SOWLov}
            setProjectDetails={setProjectDetails} projectDetails={projectDetails} projLov={projLov}
           />
            <Container fluid>
                <Row >
                    <Col xs lg="3">
                        <InputGroup className="mb-3">

                            <Form.Control
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                placeholder="Search for a client"
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            /><Button variant="outline-secondary" id="button-addon2" onClick={() => fetchClients()}>
                                <i className="bi bi-search"></i>
                            </Button>


                        </InputGroup>
                    </Col>
                    <Col xs lg="3">
                        <InputGroup className="mb-3">


                            <Button variant="primary" id="button-addon1" onClick={newClient}>
                                <i className="bi bi-file-earmark-plus"></i> Create new
                            </Button>


                        </InputGroup>
                        </Col>
                    <Col style={{ textAlign: 'right' }}>
        <Button variant="outline-secondary" onClick={toggleViewMode}>
          {viewMode === 'card' ? 'Table View' : 'Card View'}
        </Button>

<select id="export_select" onChange={(e) => {
                            if (e.target.value === 'csv') {
                                 exportData('csv');
                            } else if (e.target.value === 'pdf') {
                                exportData('pdf');
                              }
                              else if (e.target.value === 'xlsx') {
                                exportData('xlsx');
                              }
                            }}
                            style={{padding:'5px 6px 7px 6px',
                            backgroundColor:'white',color:'#3e3e3e',border:'1px solid',
                            marginLeft:'5px'}}>
                        <option value="" disabled selected>Export As</option>
                        <option value="csv">CSV</option>
                        {/*<option value="pdf">PDF</option>*/}
                        <option value="xlsx">Excel</option>
                       </select>
      </Col>
                </Row>
                {searching ? (
                    <Row ><Spinner animation="border" /></Row>
                ):(
                    <>
                        {clients.length === 0 ? (
                            <Row ><p>No clients found. Please redefine the search criteria</p></Row>
                         ) : (
                            <>
                            {viewMode === 'card' ? (
                            <Row xs={1} md={5} className="g-4">
                                {clients.map((client, index) => {
                                 const sequentialNumber = index + 1; // Calculate the sequential number
                                    return (
                                        <Col key={client.ID}>
                                            <Card>
                                                <Card.Header>{sequentialNumber}. {client.client_name}</Card.Header>
                                                <Card.Body style={{ fontSize: "13px" }}>
                                                    <Card.Text>Client Manager: {client.client_manager}</Card.Text>
                                                    <Card.Text>PO#: {client.po}</Card.Text>
                                                    <Row >
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => viewClient(client)}><i className="bi bi-eye"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => updateClient(client)}><i className="bi bi-pencil"></i>
                                                            </button>
                                                        </Col>
                                                        <Col xs lg="2">
                                                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => deleteClient(client)}><i className="bi bi-trash"></i>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                  )
                })}
              </Row>
            ) : (
                                <table className="table">
<thead>
  <tr>
    <th onClick={() => handleSort("client_name")}>Client Name {renderSortIcon("client_name")}</th>
    <th onClick={() => handleSort("client_manager")}>Client Manager {renderSortIcon("client_manager")}</th>
    <th onClick={() => handleSort("po")}>PO# {renderSortIcon("po")}</th>
    <th>Actions</th>
  </tr>
</thead>

                <tbody>
                  {sortedClients.map((client, index) => {
                     const sequentialNumber = index + 1; // Calculate the sequential number
                    return(
                    <tr key={client.ID} >
                      <td>{sequentialNumber}. {client.client_name}</td>
                      <td>{client.client_manager}</td>
                      <td>{client.po}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => viewClient(client)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => updateClient(client)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => deleteClient(client)}
                          style={{marginRight:'2px'}}
                        >
                          <i className="bi bi-trash"></i>
                          </button>
                      </td>
                    </tr>
                  );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}
      </>
    )}
  </Container>
</>

    )
}